import { SDKProfileService } from './lib/Services/SDKProfileService';
import ObfInjector from './lib/Helpers/Injector';

export class ObfSDK {
    constructor() { }

    public ProfileService = {
        apiPoints: ObfInjector.getRegistered('SDKProfileService').apiPoints,
        getConfiguration: (...args: any) => {
            return ObfInjector.getRegistered('SDKProfileService').getConfiguration(...args);
        },
        getProfiles: (...args: any) => {
            return ObfInjector.getRegistered('SDKProfileService').getProfiles(...args);
        },
        getValidations: (...args: any) => {
            return ObfInjector.getRegistered('SDKProfileService').getValidations(...args);
        },
        getModulesConfigurations: (...args: any) => {
            return ObfInjector.getRegistered('SDKProfileService').getModulesConfigurations(...args)
        }
    };

    public AccountService = {
        resetPassword: (...args: any) => {
            return ObfInjector.getRegistered('SDKAccountService').resetPassword(...args);
        },
    };

    public ClientService = {
        fetchUserData: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').fetchUserData(...args);
        },
        fetchUserAddresses: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').fetchUserAddresses(...args);
        },
        createAddress: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').createAddress(...args);
        },
        updateAddress: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').updateAddress(...args);
        },
        claimAccount: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').claimAccount(...args);
        },
        fetchUserPhones: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').fetchUserPhones(...args);
        },
        updatePhone: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').updatePhone(...args);
        },
        updatePreferences: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').updatePreferences(...args);
        },
        createPhone: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').createPhone(...args);
        },
        searchAddress: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').searchAddress(...args);
        },
        checkPaymethodSecurityRequirements: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').checkPaymethodSecurityRequirements(...args);
        },
        fetchUserPaymethods: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').fetchUserPaymethods(...args);
        },
        fetchMembershipDetails: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').fetchMembershipDetails(...args);
        },
        getServiceSearchResults: (...args: any) => {
            return ObfInjector.getRegistered('SDKClientService').getServiceSearchResults(...args);
        }
    };

    public ServicesDataService = {
        getCategories: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getCategories(...args);
        },
        getService: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getService(...args);
        },
        getServices: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getServices(...args);
        },
        getServicePaymentMethods: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getServicePaymentMethods(...args);
        },
        fetchTreats: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').fetchTreats(...args);
        },
        getUnits: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getUnits(...args);
        },
        addUnitAsFavorite: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').addUnitAsFavorite(...args);
        },
        removeUnitFromFavorites: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').removeUnitFromFavorites(...args);
        },
        getTeams: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').getTeams(...args);
        },
        addTeamAsFavorite: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').addTeamAsFavorite(...args);
        },
        removeTeamFromFavorites: (...args: any) => {
            return ObfInjector.getRegistered('SDKServicesDataService').removeTeamFromFavorites(...args);
        }
    };

    public AuthenticationService = {
        getSocialProviders: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').getSocialProviders(...args);
        },
        login: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').login(...args);
        },
        loginXRM: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').loginXRM(...args);
        },
        logout: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').logout(...args);
        },
        getAccountsMenu: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').getAccountsMenu(...args);
        },
        register: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').register(...args);
        },
        anonymousRegister: (...args: any) => {
            return ObfInjector.getRegistered('SDKAuthenticationService').anonymousRegister(...args);
        }
    };

    public BookingProcessService = {
        fetchTransaction: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').fetchTransaction(...args);
        },
        submitLeaveReason: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').submitLeaveReason(...args);
        },
        updateTransaction: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').updateTransaction(...args);
        },
        createTransaction: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').createTransaction(...args);
        },
        getAvailability: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').getAvailability(...args);
        },
        getLeaveReasons: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').getLeaveReasons(...args);
        },
        requestChallengeToken: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').requestChallengeToken(...args);
        },
        appendTags: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').appendTags(...args);
        },
        getPaymentMethods: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').getPaymentMethods(...args);
        },
        getPaymethodSetup: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').getPaymethodSetup(...args);
        },
        initiateCheckout: (...args: any) => {
            return ObfInjector.getRegistered('SDKBookingProcessService').initiateCheckout(...args);
        }
    };

    public SharedService = {
        uploadFile: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').uploadFile(...args);
        },
        setUserApplications: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').setUserApplications(...args);
        },
        getServerTime: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').getServerTime(...args);
        },
        sendException: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').sendException(...args);
        },
        sendUserFeedback: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').sendUserFeedback(...args);
        },
        setInStore: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').setInStore(...args);
        },
        fetchFromStore: (...args: any) => {
            return ObfInjector.getRegistered('SDKSharedService').setInStore(...args);
        }
    };

    public BatchRequestService = {
        createBatchObject: (...args: any) => {
            return ObfInjector.getRegistered('SDKBatchRequestService').createBatchObject(...args);
        },
        makeBatchRequest: (...args: any) => {
            return ObfInjector.getRegistered('SDKBatchRequestService').makeBatchRequest(...args);
        }
    };

    public SystemService = {
        sendCoverageReportDetails: (...args: any) => {
            return ObfInjector.getRegistered('SDKSystemService').sendCoverageReportDetails(...args);
        },
    };
}
