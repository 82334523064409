import { AzureAuthService } from './azure-auth.service';
import { Injectable } from '@angular/core';
import { LocalizationProvider } from '@providers/localization.provider';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { Authorize } from 'lib/xrm-sdk/src/lib/Api/ApiAuthorize';
import ObfInjector from 'lib/xrm-sdk/src/lib/Helpers/Injector';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, from } from 'rxjs';
import { interval } from 'rxjs/internal/observable/interval';
import { finalize } from 'rxjs/operators';
import { popupCenter } from 'src/app/helpers/helper-functions';
import { AppleAuthService } from 'src/app/services/authentication/apple-auth.service';
import { getDomainName, getUrlVars } from '../../helpers/global-functions';
import { PostMessageService } from '../post-message.service';
import { UserDataService } from '../xrm/user-data.service';
import { SDKQueryParams } from './../../../../lib/xrm-sdk/src/lib/Api/Interfaces/SDKQueryParams';
import { SDKAuthenticationService } from './../../../../lib/xrm-sdk/src/lib/Services/authentication/SDKAuthenticationService';
import { MainDataProvider } from './../../providers/xrm/main-data.provider';
import { CryptoService } from './../crypto.service';
import { LoaderService } from './../loader.service';
import { XRMApiCommunicatorService } from './../xrm/xrmapi-communicator.service';
import { FacebookAuthService } from './facebook-auth.service';
import { GoogleAuthService } from './google-auth.service';
import { QueryParamsService } from '@services/query-params.service';
import { Oauth2Service } from './oauth2.service';

@Injectable({
    providedIn: 'root',
})

export class AuthenticationService {
    public emailAuth = {
        authSync: 'ext/auth-sync',
    };

    public socialProviders: BehaviorSubject<any>;

    private sdkAuthService: SDKAuthenticationService;

    constructor(
        private loaderService: LoaderService,
        private mainDataProvider: MainDataProvider,
        private cryptoService: CryptoService,
        private XRMApiCommunicatorService: XRMApiCommunicatorService,
        private facebookAuthService: FacebookAuthService,
        private googleAuthService: GoogleAuthService,
        private appleAuthService: AppleAuthService,
        private _eventTrackingService: EventTrackingService,
        private userDataService: UserDataService,
        private cookieService: CookieService,
        private postMessageService: PostMessageService,
        private _localizationProvider: LocalizationProvider,
        private azureAuthService:AzureAuthService,
        private _queryParams: QueryParamsService,
        private _oauth2Service: Oauth2Service
    ) {
        this.socialProviders = new BehaviorSubject([]);

        this.sdkAuthService = this.XRMApiCommunicatorService.getAuthenticationService();
    }

    /**
     * Registrate user
     * @param {object} formData
     * @param {function} callback
     */
    public register(formData, queryParams?: SDKQueryParams, callback?: Function): void {
        const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg11'));

        formData.external = true;

        const emailAuthUrl = this.mainDataProvider.obfEnv.api.accounts + this.emailAuth.authSync;
        const centerPopup = popupCenter();
        const syncPopup = window.open(
            emailAuthUrl,
            'Booking Form Authorization Modal',
            'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
        );

        const subscription = from(this.sdkAuthService.register(formData, queryParams))
            .subscribe(
                async (response: any) => {
                    if (!response.error) {
                        this.userDataService.setAnonymSession(false);

                        const sid = response.data.session.sid;

                        const session = this.setAuthorizationSession(response.data.session);

                        // * Sync csid with accounts.
                        await this.syncCsidWithAccounts(session.csid, syncPopup);
                    } else {
                        if (syncPopup && !syncPopup.closed) {
                            syncPopup.close();
                        }
                    }

                    this.loaderService.hideLoader(loader);
                    subscription.unsubscribe();
                    callback(response);
                }, (error: any) => {
                    this.loaderService.hideLoader(loader);
                    if (syncPopup && !syncPopup.closed) {
                        syncPopup.close();
                    }
                    callback(error);
                },
            );
    }

    /**
     * Anonymous registration of user
     * @param {object} formData
     * @param {function} callback
     */
    public anonymousRegister(formData, queryParams?: SDKQueryParams, callback?: Function): void {
        formData.external = true;

        const loader = this.loaderService.showLoader();

        const subscription = from(this.sdkAuthService.anonymousRegister(formData, queryParams))
            .pipe(
                finalize(() => {
                    this.loaderService.hideLoader(loader);
                })
            )
            .subscribe(
                (response: any) => {
                    if (!response.error) {
                        this.userDataService.setAnonymSession(true);

                        this.userDataService.fetchUserData(
                            { expand: ['all.all.all.all'], 'paging[limit]': '9999' },
                            (data) => {
                                if (!data.error) {

                                } else {

                                }

                                subscription.unsubscribe();
                                callback(response);
                            });
                    }


                },
                (error: any) => {
                    subscription.unsubscribe();
                    callback(error);
                },
            );
    }

    /**
    * Login user
    * @param {object} formData
    * @param {function} callback
    */
    public loginEmail(formData, queryParams?: SDKQueryParams) {
        return new Promise(async (res, rej) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg24'));

            const emailAuthUrl = this.mainDataProvider.obfEnv.api.accounts + this.emailAuth.authSync;
            const centerPopup = popupCenter();
            const syncPopup = window.open(
                emailAuthUrl,
                'Booking Form Authorization Modal',
                'height=710,width=630,left=' + centerPopup.left + ',top=' + centerPopup.top + ',resizable=no,scrollbars=no,toolbar=no,menubar=no,location=no,directories=no, status=no',
            );

            if (formData.rememberMe === false) {
                delete formData.rememberMe;
            }

            this.login(formData, queryParams, async (loginResponse) => {
                if (loginResponse.data) {
                    const session = this.setAuthorizationSession(loginResponse.data.session);

                    // * Sync csid with accounts.
                    await this.syncCsidWithAccounts(session.csid, syncPopup);

                    res(loginResponse);

                    this.loaderService.hideLoader(loader);

                } else if (loginResponse.error) {
                    setTimeout(() => {
                        // Stop listener and close the popup because we have error when try to login.
                        if (syncPopup && !syncPopup.closed) {
                            syncPopup.close();
                        }
                        this.loaderService.hideLoader(loader);
                    }, 100);

                    // return the error.
                    rej(loginResponse);
                }
            });
        });
    }

    public loginOauth2() {
        return new Promise((resolve, reject) => {

            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg24'));

            this._oauth2Service.beginAuth('login').then((response: any) => {
                if (response && !response.error) {

                    const loginPayload = {
                        social: response
                    };

                    // const session = this.setAuthorizationSession(response.data.session);

                    this.loginXRM(loginPayload, {}, async (loginResponse) => {
                        if (loginResponse.data) {
                            const session = this.setAuthorizationSession(loginResponse.data.session);
    
                            // *** SYNC CSID WITH ACCOUNTS.
                            await this.syncCsidWithAccounts(session.csid, this._oauth2Service.popupInstance);
    
                            resolve(loginResponse);
    
                            this.loaderService.hideLoader(loader);
    
                        } else {
                            reject(loginResponse);
                            this.loaderService.hideLoader(loader);
    
                        }
                    });

                } else {
                    this.loaderService.hideLoader(loader);
                    reject(response);
                }
            }).catch((err) => {
                this.loaderService.hideLoader(loader);
                reject(err);
            });
        });
    }

    public loginGoogle() {
        return new Promise((resolve, reject) => {
            this.googleAuthService.beginAuth('login').then((response: any) => {
                if (response.data) {
                    const session = this.setAuthorizationSession(response.data.session);

                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public loginApple() {
        return new Promise((resolve, reject) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg24'));

            this.appleAuthService.beginAuth('login').then((response) => {
                const loginPayload = response;

                loginPayload.api_version = 'v2';
                loginPayload.rememberMe = true;

                this.login(loginPayload, {}, async (loginResponse) => {
                    if (loginResponse.data) {
                        const session = this.setAuthorizationSession(loginResponse.data.session);

                        // *** SYNC CSID WITH ACCOUNTS.
                        await this.syncCsidWithAccounts(session.csid, this.appleAuthService.popupInstance);

                        resolve(loginResponse);

                        this.loaderService.hideLoader(loader);

                    } else {
                        reject(loginResponse);
                        // Close popup and resetInstance.
                        this.appleAuthService.popupInstance.close();
                        this.appleAuthService.popupInstance = null;
                        this.loaderService.hideLoader(loader);

                    }
                });
            }).catch((error) => {
                reject(error);
                this.loaderService.hideLoader(loader);
            });
        });
    }

    public loginFacebook() {
        return new Promise((resolve, reject) => {
            this.facebookAuthService.beginAuth('login').then((response) => {
                if (response.data) {
                    const session = this.setAuthorizationSession(response.data.session);

                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    public loginAzure() {
        return new Promise((resolve, reject) => {
            this.azureAuthService.beginAuth().then((response) => {
                if (response.data) {
                    const session = this.setAuthorizationSession(response.data.session);

                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((err) => {
                reject(err);
            });
        });
    }

    /**
    * Simply deletes cookie only on localhost environment
    */
    public logout(): Promise<boolean | any> {
        return new Promise((resolve: Function, reject: Function) => {
            const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg10'));

            const logoutHandler = () => {
                this.userDataService.resetUserData();
                // * Reset account menu config.
                this.userDataService.setAccountsMenuConfig(null);

                this.setAuthorizationSession(null);

                this.loaderService.hideLoader(loader);

                // when open obf from accounts, on logout navigate to accounts login screen except on pro cross booking
                let obfOptions = this.mainDataProvider.getResourceObfOptions();

                // Do not redirect to login page on pro cross booking
                const queryParams = this._queryParams.getQueryParams();
                
                if (
                    obfOptions && (document.referrer).indexOf(obfOptions.accounts_url) > -1 &&
                    !(queryParams && queryParams.cross_token && queryParams.cross_id)
                ) {
                    window.location.replace(obfOptions.accounts_url + (obfOptions.accounts_url.lastIndexOf('/') === obfOptions.accounts_url.length - 1 ? 'login' : '/login'));
                
                }
            };

            // * Execute logout
            this.sdkAuthService.logout()
                .then(
                    (response: any) => { // Success
                        logoutHandler();
                        resolve(response);
                    },
                    (response: any) => { // Error
                        logoutHandler();
                        resolve(response);
                    },
                );
        });
    }

    private setAuthorizationSession(session: { csid?: string, sid?: string, expire_time?: number, create_time?: number } | null): { csid?: string, sid?: string, expire?: number } {
        console.log('session: ', session);
        if (session) {

            // Try to set return-client flag.
            try {
                localStorage.setItem('return-client', 'true');
            } catch (e) {
                // don't care
            }

            // * Set current auth sid.
            const auth: Authorize = ObfInjector.getRegistered('Authorize') as Authorize;
            auth.authorization = session.sid;

            // * Crypt sid.
            session.csid = this.cryptoService.encryptData(session.sid, this.mainDataProvider.obfEnv.cookieKey);

            this.mainDataProvider.setSessionId(session.sid);
            const sendSession = JSON.parse(JSON.stringify(session));
            delete sendSession.sid;

            // set Cookie only when we have expire time.
            if (session.expire_time) {

                // * Send to client to set cookie.
                this.postMessageService.sendState('csid', sendSession);

                // ! DEVELOPMENT ON LOCALHOST COOKIE.
                if (this.mainDataProvider.obfEnv.env === 'localhost') {
                    const date = new Date();
                    date.setTime(session.expire_time * 1000);
                    this.cookieService.set(
                        'user-login',
                        session.csid,
                        date,
                        '/',
                        this.mainDataProvider.obfEnv.env,
                        false,
                        'Lax',
                    );
                }
            }

            return session;
        } else {
            this._eventTrackingService.push({
                event: 'user-logout',
                userId: null,
                membership: null,
            });

            const dataLayerObj = {
                event: 'logout',
                version: 'GA4',
                user_id: null,
                membership: null
            };
            this._eventTrackingService.push(dataLayerObj);

            const dataLayerObjectUserInfo = {
                event: 'user_info',
                version: 'GA4',
                user_id: undefined,
                membership: ''
            };
            this._eventTrackingService.push(dataLayerObjectUserInfo);

            this.postMessageService.sendState('csid', { csid: null });
            this.mainDataProvider.setSessionId(null);

            this.clearSessionStorageFromAuthParam();

            // ! DEVELOPMENT ON LOCALHOST COOKIE.
            if (this.mainDataProvider.obfEnv.env === 'localhost') {
                this.cookieService.delete(
                    'user-login',
                    '/',
                    getDomainName(location.hostname),
                    false,
                    'Lax',
                );

                return;
            }

            return null;
        }
    }

    private clearSessionStorageFromAuthParam() {
        if (sessionStorage?.getItem('authorization')) {
            try {
                sessionStorage.removeItem('authorization')
            } catch (e) {}
        }
    }

    public isAuthorize(passedCSID = null): Promise<void> {
        return new Promise(async (res, rej) => {
            const loader = this.loaderService.showLoader();

            let tryFetchUserData = false;

            const urlParams = getUrlVars();

            let parsedParams: any = {};

            // Check for payload properties
            Object.keys(urlParams).map(queryParamsKey => {
                if (queryParamsKey.indexOf('fs_payload') !== -1 && queryParamsKey.indexOf(']') !== -1 && queryParamsKey.indexOf('[') !== -1) {

                    let splittedParam = queryParamsKey.split('[');
                    let payloadProperty = splittedParam[1].split(']')[0];

                    if (payloadProperty) {
                        parsedParams[payloadProperty] = urlParams[queryParamsKey];
                    }

                }
            });

            if (parsedParams?.authorization) {
                tryFetchUserData = true; // mark for check is valid sid

                this.setAuthorizationSession({ sid: parsedParams.authorization });
                // Set Authorization Param in LocaStorage for XRM SDK
                sessionStorage.setItem('authorization', parsedParams.authorization);
                
            } else if (this.cookieService.check('user-login') || this.cookieService.check('_user-login')) {

                // Check if we are on modern browser and is possible to read cookies.
                tryFetchUserData = true; // mark for check is valid sid
                
                // ! XRM SDK is reading the sid from cookie.
            } else if (passedCSID) {

                tryFetchUserData = true; // mark for check is valid sid

                this.setAuthorizationSession({ csid: passedCSID });
            }

            if (!urlParams?.authorization) {
                // Try to clear autorization param from session storage if its not present on init, so xrm sdk will not use it
                this.clearSessionStorageFromAuthParam();
            }

            // Check if accounts Are disabled.
            const authRestrictions = await this.userDataService.authRestrictions();
            if (authRestrictions === "anonym_auth" && tryFetchUserData) {
                this.loaderService.hideLoader(loader);
                await this.logout();
                res();
                return;
            }

            // *** Stop the function don't have passed csid from client and cookie. User is not login for us.
            if (!tryFetchUserData) {
                // Check if is return user.
                try {
                    if (localStorage.getItem('return-client')) {
                        this.userDataService.setLoginModalDefaultTab('login');
                    } else {
                        this.userDataService.setLoginModalDefaultTab('register');
                    }
                } catch (e) {
                    this.userDataService.setLoginModalDefaultTab('register');
                }

                this.userDataService.setAccountsMenuConfig(null);
                this.loaderService.hideLoader(loader);
                res();
                return;
            }

            // Will set new return-client or rewrite current.
            try {
                localStorage.setItem('return-client', 'true');
            } catch (e) {
                // don't care
            }
            this.userDataService.setLoginModalDefaultTab('login');

            this.userDataService.fetchUserData(
                { expand: ['all.all.all.all'], 'paging[limit]': '9999' },
                async (response) => {
                    if (!response.error) {
                        res();
                    } else {
                        await this.logout();
                        res();
                        // response.error = { message: 'Fail get login user', fatal: false };
                        // rej(response);
                    }
                    this.loaderService.hideLoader(loader);
                }, true);
        });
    }

    /**
     * Login user
     * @param {object} formData
     * @param {function} callback
     */
    private loginXRM(formData, queryParams?: SDKQueryParams, callback?: Function) {
        const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg9'));


        const subscription = from(this.sdkAuthService.loginXRM(formData, queryParams))
            .pipe(
                finalize(() => {
                    this.loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    if (!response.error) {

                        this.userDataService.setAnonymSession(false);
                    }

                    subscription.unsubscribe();
                    callback(response);
                },
                (error) => {
                    subscription.unsubscribe();
                    callback(error);
                },
            );
    }

    /**
     * Login user
     * @param {object} formData
     * @param {function} callback
     */
    private login(formData, queryParams?: SDKQueryParams, callback?: Function) {
        const loader = this.loaderService.showLoader(this._localizationProvider.getLocalizedText('loaderMsg9'));

        const subscription = from(this.sdkAuthService.login(formData, queryParams))
            .pipe(
                finalize(() => {
                    this.loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    if (!response.error) {

                        this.userDataService.setAnonymSession(false);
                    }

                    subscription.unsubscribe();
                    callback(response);
                },
                (error) => {
                    subscription.unsubscribe();
                    callback(error);
                },
            );
    }

    private syncCsidWithAccounts(csid, popup) {
        return new Promise((resolve) => {
            const syncPopup = popup;

            // this check is if user on browser block the syncpopup.
            if (syncPopup && !syncPopup.closed) {
                let syncAccount = false;
                const syncPostMessageHandler = (event) => {
                    if (event.data?.csid) {
                        syncAccount = true;
                    }
                };

                window.addEventListener('message', syncPostMessageHandler);

                syncPopup.location.href =
                    this.mainDataProvider.obfEnv.api.accounts +
                    this.emailAuth.authSync + '?csid=' + encodeURIComponent(csid);

                const syncTimeout = setTimeout(() => {
                    syncPopup.close();
                }, 10000);

                const subscription = interval(1000).subscribe(() => {
                    if (syncPopup && !syncPopup.closed) { return; }

                    window.clearTimeout(syncTimeout);

                    // Only we care if we sync user account successfully with WA
                    if (!syncAccount) {
                        resolve(false);
                        // ! TODO ADD ERROR REPORTING FOR FAIL SYNC ACCOUNT.
                    } else {
                        resolve(true);
                    }

                    window.removeEventListener('message', syncPostMessageHandler);
                    subscription.unsubscribe();
                });
            } else {
                resolve(false);
            }
        });
    }

    /**
     * Get social providers
     * for basic url use the default middle point url
     * @param {function} callback
     */
    public getSocialProviders(queryParams?: SDKQueryParams, callback?: Function): void {
        const loader = this.loaderService.showLoader();
        const subscription = from(this.sdkAuthService.getSocialProviders(queryParams))
            .pipe(
                finalize(() => {
                    this.loaderService.hideLoader(loader);
                }),
            )
            .subscribe(
                (response: any) => {
                    const data = response.data;
                    this.socialProviders.next(data);

                    const socialAuthData: any = {};

                    data.map((socialAuth) => {
                        if (!socialAuth.data) {
                            return;
                        }

                        switch (socialAuth.type) {
                            case 'Facebook':
                                socialAuthData.facebookAuthKey = socialAuth.data.app_id;
                                socialAuthData.facebookProviderId = socialAuth.id;

                                break;
                            case 'Google':
                                socialAuthData.googleAuthKey = socialAuth.data.client_id;
                                socialAuthData.googleProviderId = socialAuth.id;

                                break;
                            case 'Apple':
                                socialAuthData.appleAuthKey = socialAuth.data.client_id;
                                socialAuthData.appleProviderId = socialAuth.id;

                                break;

                            case 'Microsoft': 
                                // Change schematic. Use coming structure from xrm
                                socialAuthData.azure = socialAuth.data
                                break;

                            case 'OAuth2':
                                const oauth2 = {
                                    client_id: socialAuth.data?.client_id,
                                    authorization_url: socialAuth.data?.authorization_url,
                                    provider_id: socialAuth.id
                                }
                                socialAuthData.oauth2 = oauth2;

                                break;

                            default:
                                break;
                        }
                    });

                    this.mainDataProvider.setSocialAuthData(socialAuthData);

                    subscription.unsubscribe();
                    callback(response);
                },
                (error) => {
                    subscription.unsubscribe();
                },
            );
    }

    /**
     * Check SocialProviders data status
     * @returns {Object}
     */
    public getSocialProvidersStatus(): { status: String, processingMethod: Function } {
        const validationStatusData: { status: String, processingMethod: Function } = {
            status: 'missing', // Note: SocialProviders will always be missing (never cached), because it has no provider or cache functionality for now
            processingMethod: (response: any): Promise<any> => {
                return new Promise((resolve: Function, reject: Function) => {
                    if (response.decoder && response.decoder.error) {
                        reject({ message: 'Failed to get social providers: ' + response.decoder.error, fatal: true });
                    } else {
                        const data = response.data;

                        this.socialProviders.next(data);

                        const socialAuthData: any = {};

                        data.map((socialAuth) => {
                            if (!socialAuth.data) {
                                return;
                            }

                            switch (socialAuth.type) {
                                case 'Facebook':
                                    socialAuthData.facebookAuthKey = socialAuth.data.app_id;
                                    socialAuthData.facebookProviderId = socialAuth.id;

                                    break;
                                case 'Google':
                                    socialAuthData.googleAuthKey = socialAuth.data.client_id;
                                    socialAuthData.googleProviderId = socialAuth.id;

                                    break;
                                case 'Apple':
                                    socialAuthData.appleAuthKey = socialAuth.data.client_id;
                                    socialAuthData.appleProviderId = socialAuth.id;

                                    break;

                                case 'Microsoft': 
                                    // Change schematic. Use coming structure from xrm
                                    socialAuthData.azure = socialAuth.data;
                                    break;

                                case 'OAuth2':
                                    const oauth2 = {
                                        client_id: socialAuth.data?.client_id,
                                        authorization_url: socialAuth.data?.authorization_url,
                                        provider_id: socialAuth.id
                                    }
                                    socialAuthData.oauth2 = oauth2;

                                    break;

                                default:
                                    break;
                            }
                        });

                        this.mainDataProvider.setSocialAuthData(socialAuthData);

                        resolve();
                    }
                });
            },
        };

        // Bind current class scope to the method
        validationStatusData.processingMethod.bind(this);

        return validationStatusData;
    }
}
