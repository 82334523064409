import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalizationProvider } from '@providers/localization.provider';
import * as Sentry from '@sentry/angular';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { getHotjarId, scrollToTop } from '../../../../../../helpers/global-functions';
import { MainDataProvider } from '../../../../../../providers/xrm/main-data.provider';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { UserDataService } from '../../../../../../services/xrm/user-data.service';
import { ValidationService } from '../../../../../../services/xrm/validation.service';
import { ConfigurationService } from '@services/configuration.service';
// #Templates
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
// #endTemplates

export class LoginComponent implements OnInit {
    /**
     * Contain the popup reference
     */
    // @Input()
    // closeModal: Function;
    @Output() public modalClose: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public changeView = new EventEmitter();

    @Input() set prefillData(data: any) {
        if (data) {

        }
    }

    @ViewChild('f', { static: false }) public formTemplateRef: any;

    @Input() set currentView(data) {
        this.componentSettings.errorMessages = [];
        if (this.formTemplateRef) {
            // reset templateFormObject
            this.formTemplateRef.reset();
            this.formTemplateRef.submitted = false;

            // reset reactiveFormObject
            this.loginForm.reset();
            this.formSubmitted = false;
        }
    }

    /**
     * contain loginForm instance
     * @type {formGroup}
     */
    public loginForm: FormGroup;

    private loginRequestInProcess = false; // ! This is fix for the login with fingerprint on samsung device

    public isBoxExpanded = false;
    public isBoxExpandeByDefault = false;
    public formSubmitted = false;
    public isLoginOptional: boolean = false;

    public disableEmailAuthorization: boolean = false;
    public disableChangePassword = false;

    public isGuestAuthEnabled = null;

    /**
     * Contain the component basic settings
     */
    public componentSettings = {
        msg: this._localizationProvider.getLocalizedText('loginFormMessage'),
        errorMessages: [],
        form: {
            fields: {
                email: {
                    textFieldSettings: {
                        type: 'text',
                        label: this._localizationProvider.getLocalizedText('loginFormEmailLabel'),
                        placeholder: this._localizationProvider.getLocalizedText('loginFormEmailPlaceholder'),
                        validate: true,
                        value: '',
                        errMsg: this._localizationProvider.getLocalizedText('loginFormEmailErrMsg'),
                    },
                },
                password: {
                    textFieldSettings: {
                        type: 'password',
                        label: this._localizationProvider.getLocalizedText('loginFormPasswordLabel'),
                        placeholder: this._localizationProvider.getLocalizedText('loginFormPasswordPlaceholder'),
                        validate: true,
                        value: '',
                        errMsg: this._localizationProvider.getLocalizedText('loginFormPasswordErrMsg'),
                        showPassword: false,
                    },
                },
                loggedIn: {
                    textFieldSettings: {
                        label: this._localizationProvider.getLocalizedText('loginFormLoggedInLabel'),
                    },
                },
                login: {
                    label: this._localizationProvider.getLocalizedText('loginFormButtonLabel'),
                },
            },
            labels: {
                signUp: {
                    label: this._localizationProvider.getLocalizedText('wordOr'),
                },
            },
            navLinks: {
                forgotPassword: {
                    label: this._localizationProvider.getLocalizedText('loginFormForgotPassLabel'),
                    // onClick:  () => {
                    //     console.log('Clicked');
                    // }
                },
                createAccount: {
                    label: this._localizationProvider.getLocalizedText('loginFormCreateAccLabel'),
                    // onClick:  () => {
                    //     console.log('Clicked');
                    // }
                },
            },
        },
    };

    /**
     * Social login providers array
     */
    @Input()
    public socialLogins = [];

    @ViewChild('dynamicLoaderContainer', { read: ViewContainerRef })
    public dynamicLoaderContainer: ViewContainerRef;

    @ViewChild('username')
    public username: ElementRef;

    public windowPopup;

    /**
     * Imports
     * @param {FormBuilder} fb
     * @param {AuthenticationService} _authService
     * @param {GoogleAuthService} _googleAuth
     * @param {UserDataService} _userDataService
     */
    constructor(
        private fb: FormBuilder,
        private _authService: AuthenticationService,
        private _userDataService: UserDataService,
        private _eventTrackingService: EventTrackingService,
        private _validationService: ValidationService,
        public _mainDataProvider: MainDataProvider,
        public _localizationProvider: LocalizationProvider,
        private _configurationService: ConfigurationService
    ) {
        // # console.log('Constructor instance: ', this.loginAndRegisterModalRef);
    }

    public ngOnInit() {
        this.initialize();
    }

    /**
     * Initialize Login component
     */
    private async initialize() {

        const bookConfiguration = this._configurationService.getConfigurationSettings()?.book;

        if (bookConfiguration?.settings.hasOwnProperty('enable_guest_auth')) {
            this.isGuestAuthEnabled = bookConfiguration.settings.enable_guest_auth;
        }

        scrollToTop('.obf-lr-modal');

        const configurationSettings: any = this._configurationService.getConfigurationSettings();

        if (configurationSettings?.auth?.settings?.disable_change_password) {
            this.disableChangePassword = configurationSettings.auth.settings.disable_change_password;
        }

        if (configurationSettings?.auth?.settings?.disable_email_authorization) {
            this.disableEmailAuthorization = configurationSettings.auth.settings.disable_email_authorization;
        }

        // Expand form by default whem socials missing
        if (this.socialLogins && !this.socialLogins.length) {
            this.isBoxExpanded = true;
            this.isBoxExpandeByDefault = true;
            this.componentSettings.form.fields.login.label = this._localizationProvider.getLocalizedText('signInLabel');
        }

        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.pattern(this._validationService.getPattern('register', 'email'))]],
            password: ['', Validators.required],
            loggedIn: [''],
        });

        const authRestrictions: any = await this._userDataService.authRestrictions();

        this.isLoginOptional = authRestrictions && authRestrictions === "not_required_auth" ? true : false;
    }

    get email() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }

    get logginIn() {
        return this.loginForm.get('loggedIn');
    }

    /**
     * Use in html to call login with specific social provider
     * @param {SocialProviders} scLogin
     */
    public loginWithSocial(scLogin) {
        switch (scLogin.type) {
            case 'Facebook':
                this.loginWithFacebook();
                break;
            case 'Google':
                this.loginWithGoogle();
                break;
            case 'Apple':
                this.loginWithApple();
                break;
            case 'Microsoft': 
                this.loginWithAzure();
                break;
            case 'OAuth2':
                this.loginWithOauth2();
                break;

            default:
                break;
        }
    }

    /**
     * Login with Azure B2C
     */
     private loginWithAzure(): void {
        this._authService.loginAzure()
            .then(
                (response) => { // Success
                    this.componentSettings.errorMessages = new Array();

                    this.getAuthorizeUserData('microsoft');
                },
                (error: any) => {

                    if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                        this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                    } else {
                        this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('azureSigninErrMsg') }];
                    }

                    this.DataLayerPush('error', 'azure', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');

                    scrollToTop('.obf-lr-modal');
                },
            );
    }

    /**
     * Login with Facebook
     */
    private loginWithFacebook(): void {
        this._authService.loginFacebook()
            .then(
                (response) => { // Success
                    this.componentSettings.errorMessages = new Array();

                    this.getAuthorizeUserData('facebook');
                },
                (error: any) => {

                    if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                        this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                    } else {
                        this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('facebookSigninErrMsg') }];
                    }

                    this.DataLayerPush('error', 'facebook', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');

                    scrollToTop('.obf-lr-modal');
                },
            );
    }
    /**
     * Login with OAuth2
     */
    private loginWithOauth2() {
        this._authService.loginOauth2().then(
            (res: any) => {

                this.componentSettings.errorMessages = new Array();

                this.getAuthorizeUserData('OAuth2');
            },
            (error: any) => {

                if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                    this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                } else {
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('defaultSigninErrMsg') }];
                }

                this.DataLayerPush('error', 'OAuth2', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');

                scrollToTop('.obf-lr-modal');

            },
        );
    }

    /**
     * Login with Google
     */
    private loginWithGoogle() {
        this._authService.loginGoogle().then(
            (res: any) => {

                this.componentSettings.errorMessages = new Array();

                this.getAuthorizeUserData('Google');
            },
            (error: any) => {

                if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                    this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                } else {
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('googleSigninErrMsg') }];
                }

                this.DataLayerPush('error', 'google', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');

                scrollToTop('.obf-lr-modal');

            },
        );
    }

    /**
     * Login with Apple
     * @param scLogin
     */
    private loginWithApple(): void {
        this._authService.loginApple().then(async (res) => {
            this.componentSettings.errorMessages = new Array();

            this.getAuthorizeUserData('apple');
        }).catch((error) => {

            if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                this.componentSettings.errorMessages = [{ message: error.error[0].message }];
            } else {
                this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('appleSigninErrMsg') }];
            }

            this.DataLayerPush('error', 'apple', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');

            scrollToTop('.obf-lr-modal');
        });
    }

    /**
     * Process login form. Get user data.
     */
    private loginWithEmail(formData): void {
        if (this.loginRequestInProcess) { // ! Fix for samsung fingerprint login
            return;
        }
        this.loginRequestInProcess = true;

        this._authService.loginEmail(formData, {})
            .then((res) => {
                this.loginRequestInProcess = false;

                this.componentSettings.errorMessages = new Array();

                this.getAuthorizeUserData('standard');
            }, (errResponse) => {
                this.componentSettings.errorMessages = errResponse.error;

                if (errResponse.statuscode === -1) { // Timeout
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('modalErrMsg4') }];
                }

                this.loginRequestInProcess = false;

                scrollToTop('.obf-lr-modal');

                this.DataLayerPush('error', 'standard', this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');
            });
    }

    private getAuthorizeUserData(loginType) {
        this._userDataService.fetchUserData({ expand: ['all.all.all.all'], 'paging[limit]': '9999' }, (data) => {
            if (!data.error) {
                this.DataLayerPush('success', loginType);

                

                this.modalClose.emit('success');
            } else {
                this.componentSettings.errorMessages = data.error;

                scrollToTop('.obf-lr-modal');

                this.DataLayerPush('error', loginType, this.componentSettings.errorMessages?.length ? this.componentSettings.errorMessages[0] : '');
            }
        });
    }

    /**
     * Process login form. Get user data.
     */
    public validateEmailForm(): void {

        if (!this.isBoxExpanded) {
            this.isBoxExpanded = true;
            this.componentSettings.form.fields.login.label = this._localizationProvider.getLocalizedText('signInLabel');
            return;
        }

        this.formSubmitted = true;

        if (this.loginForm.valid) {
            const formValue = this.loginForm.value;

            const loginType = 'standard';

            const formData = {
                email: formValue.email,
                password: formValue.password,
                rememberMe: formValue.loggedIn ? formValue.loggedIn : false,
                external: true,
                api_version: 'v2',
            };
            this.loginWithEmail(formData);
        } else {
            const loginType = 'standard';
            // Error Data Push
            this.DataLayerPush('error', loginType, 'Form is not valid');
            scrollToTop('.obf-lr-modal');
        }
    }

    /**
     * Data layer push method
     * @param callbackStatus
     * @param loginType
     */
    private DataLayerPush(callbackStatus: any, loginType: string, errorMessage?: string): void {
        const dataLayerObject: any = {};
        const event = callbackStatus === 'error' ? 'user-login-error' : 'user-login-success';

        // Error Data Push
        Object.assign(dataLayerObject, {
            event,
            loginType,
        });

        if (callbackStatus === 'success') {
            const userData = this._userDataService.getUserData();

            const userId: any = userData.id;
            const userMembership: any = userData.membership;
            const username: any = userData.username;
            const userHotjarId: any = getHotjarId();

            dataLayerObject.userId = userId;
            dataLayerObject.membership = userMembership;
            dataLayerObject.username = username;

            const dataLayerObj = {
                event: 'login',
                version: 'GA4',
                type: loginType,
                membership: userMembership,
                user_id: userId
            };
            this._eventTrackingService.push(dataLayerObj);

            const dataLayerObjectUserInfo = {
                event: 'user_info',
                version: 'GA4',
                user_id: userData?.id,
                membership: userData ? userData?.membership ? 'member' : 'non-member' : ''
            };
            this._eventTrackingService.push(dataLayerObjectUserInfo);

            // Add User Data and additional data to the Sentry
            Sentry.configureScope((scope) => {
                scope.setTag('login-type', loginType);
                scope.setTag('hotjar-id', userHotjarId);

                scope.setExtra('membership', userMembership);

                scope.setUser({
                    id: userId,
                    email: username,
                });
            });
            // FOR TEST
            // Sentry.captureMessage('User Log in');
        } else if (callbackStatus === 'error') {
            // Login error
            const dataLayerObj = {
                event: 'login_error',
                version: 'GA4',
                type: loginType,
                error_message: errorMessage ? errorMessage : ''
            };
            this._eventTrackingService.push(dataLayerObj);
        }

        this._eventTrackingService.push(dataLayerObject);
    }

    /**
     * Reset password handler
     */
    public resetPasswordHandler(): void {

        const dataLayerObject = {};
        // Reset Data Push
        Object.assign(dataLayerObject, {
            event: 'reset-password',
        });
        this._eventTrackingService.push(dataLayerObject);

        this.changeView.emit({ tab: 'resetPassword', data: {} });

    }

    public noUser(data: any, loginType): void {
        this.componentSettings.errorMessages = [];
        data.loginType = loginType;
        // data.specialMessage = 'It seems like this ' + loginType + ' account is not yet associated with our system. Please, fill out the register form below to create one.';
        this.changeView.emit({ tab: 'register', data });
    }

    /**
     * Optional login handler
     */
    public continueAsGuest(): void {
        if (this._userDataService.getUserData() && this._userDataService.getUserData().type_id === 1) {
            this.modalClose.emit('success');
        } else {
            this._authService.anonymousRegister({ type_id: 1 }, null, async (response) => {
                this.modalClose.emit('success');
            });
        }
    }
}
