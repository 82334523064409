import { ReactNativeControllerService } from './modules/react-native/react-native-controller.service';
import { AgmCoreModule, MapsAPILoader } from '@agm/core';
import { HttpClientModule } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ErrorHandler,
    Injector,
    NgModule,
} from '@angular/core';
/**
 * Modules
 */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, Router } from '@angular/router';
import { NgAddToCalendarModule } from '@trademe/ng-add-to-calendar';
import { Angulartics2Module } from 'angulartics2';
import {} from 'googlemaps';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { LoggerModule } from 'ngx-logger'; // https://github.com/dbfannin/ngx-logger
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { AppRoutingModule } from './app-routing.module';
import './global-prototypes';
import { SharedModule } from './modules/shared/shared.module';
import { BreadcrumbsService } from './services/breadcrumbs.service';
import { CustomLazyAPIKeyLoader } from './services/custom-google-apikey-loader.service';
import { ModalsService } from './services/modals.service';
import { PopupModalsService } from './services/popup-modals.service';
import { InitConfigDataService } from './services/xrm/init-config-data.service';


//  **here for Logger
import * as Sentry from '@sentry/angular';
import * as Pixel from '@serviceos/interaction-pixel/dist';
import { NGXLogger } from 'ngx-logger';
import { Integrations } from '@sentry/tracing';

/**
 * Components
 */
import { AppComponent } from './app.component';
import { ParentComponent } from './components/parent/parent.component';
import { LoaderComponent } from './components/shared/loader/loader.component';

/**
 * Services
 */
import { CookieService } from 'ngx-cookie-service';
import { AffiliatesScriptsLoaderService } from './services/affiliates-scripts-loader.service';
import { AuthenticationService } from './services/authentication/authentication.service';
import { BackEndService } from './services/backend.service';
import { BookingDetailsService } from './services/booking-details.service';
import { CacheService } from './services/cache.service';
import { CryptoService } from './services/crypto.service';
import { ErrorHandlingService } from './services/errors/error-handling.service';
import { EventTrackingService } from './services/tracking/event-tracking.service';
import { TrackingCustomDimensionsService } from './services/tracking/data-tracking.service';
import { TrackingInteractionsService } from './services/tracking/data-interaction.service';
import { LoaderService } from './services/loader.service';
import { LocalStorageService } from './services/local-storage.service';
import { PostMessageService } from './services/post-message.service';
import { QueryParamsService } from './services/query-params.service';
import { BookingTransactionsService } from './services/xrm/booking-transactions.service';
import { ConfigurationService } from './services/xrm/configuration.service';
import { ServicesService } from './services/xrm/services.service';
import { UserDataService } from './services/xrm/user-data.service';
import { ValidationService } from './services/xrm/validation.service';

/**
 * Pipes
 */
import { UniqueRecordsPipe } from './pipes/unique-records.pipe';
import { UserAddressesPipe } from './pipes/user-addresses.pipe';

/**
 * Directives
 */
import { EqualValidator } from './directives/equal-validator.directive';

/**
 * Providers
 */
import { ConfigurationData } from './providers/xrm/configuration-data.provider';
import { MainDataProvider } from './providers/xrm/main-data.provider';
import { OrderData } from './providers/xrm/order-data.provider';
import { ServiceData } from './providers/xrm/service-data.provider';
import { UserData } from './providers/xrm/user-data.provider';

/**
 * Dynamic Env
 */
import { environment } from './../environments/environment';

/**
 * ErrorHandler
 */
import { CustomErrorHandler } from './handlers/error/custom-error-handler';
import { MyLoggerMonitor } from './handlers/error/custom-log-monitor';

/**
 * Need to be order by type
 */
import { Angulartics2GoogleTagManager } from 'angulartics2/gtm';
import { ObfOptions } from '../../lib/obf-client/src/interfaces/obf-options.interface';
import { getDomainName, getUrlVars, transformLanguageKey, getGoogleCookieId, setCookie} from './helpers/global-functions';
import { SpecialData } from './providers/special-data.provider';
import { AppManagerService } from './services/app-manager.service';
import { AppleAuthService } from './services/authentication/apple-auth.service';
import { FacebookAuthService } from './services/authentication/facebook-auth.service';
import { GoogleAuthService } from './services/authentication/google-auth.service';
import { ErrorReportingService } from './services/errors/error-reporting.service';
import { InitGlobalDataService } from './services/xrm/init-global-data.service';
import { XRMApiCommunicatorService } from './services/xrm/xrmapi-communicator.service';
import { LocalizationProvider } from '@providers/localization.provider';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
};

let parentCookies;

const setRelease = environment.production
    ? 'online-booking-form@' + environment.VERSION
    : undefined;

const sentryFingerprintId = Math.random().toString(36).substr(2, 9);

let sentryExceptionData: any = new Object();

Sentry.init({
    dsn: 'https://0537563e4f70471d80dd61a2650d7aaf@sentry.serviceos.app/2',
    environment: environment.envName,
    release: setRelease,
    maxBreadcrumbs: 50,
    debug: false,//environment.production ? false : true,
    attachStacktrace: true,
    // whitelistUrls: [/localhost/,/1dxr\.com/,/fantasticservices\.com/, /fantasticservicesgroup\.com\.au/, /obfa\.fantasticservices\.com/, /obfa\.fantasticservicesgroup\.com\.au/],
    ignoreErrors: [
        'https://c.paypal.com/',
        'https://in.hotjar.com/',
        'fb_xd_fragment',
        'Google auth register',
    ],

    integrations: [
        // Registers and configures the Tracing integration,
        // which automatically instruments your application to monitor its
        // performance, including custom Angular routing instrumentation
        new Integrations.BrowserTracing({
            routingInstrumentation: Sentry.routingInstrumentation,
        }),
    ],

    // Modify Errors Exceptions with tags
    beforeSend: (event, hint) => {

        let sendEvent = false;
        if (event.hasOwnProperty('exception')) {
            sentryExceptionData = event;
        }

        if (
            event.hasOwnProperty('extra') &&
            event.extra &&
            event.extra.hasOwnProperty('parse-error') &&
            event.extra['parse-error']
        ) {
            if (Object.keys(sentryExceptionData).length === 0) {
                sentryExceptionData = event;
            }

            sentryExceptionData.fingerprint = [sentryFingerprintId];
            sentryExceptionData.tags = event.tags;
            sentryExceptionData.extra = event.extra;
            sendEvent = true;
        }

        return sendEvent ? sentryExceptionData : null;
    },
    tracesSampleRate: 1.0,
});

@NgModule({
    declarations: [
        AppComponent,
        ParentComponent,
        UserAddressesPipe,
        UniqueRecordsPipe,
        EqualValidator,
        LoaderComponent,
    ],
    imports: [
        BrowserModule,
        SharedModule,
        DeviceDetectorModule.forRoot(),
        AppRoutingModule,
        BrowserAnimationsModule,
        NgAddToCalendarModule,
        // HttpModule,
        HttpClientModule,
        // import Angulartics2GoogleTagManager in root ngModule
        Angulartics2Module.forRoot({
            pageTracking: {
                clearHash: true,
                clearQueryParams: true,
            },
        }),
        AgmCoreModule.forRoot({
            libraries: ['places'],
        }),
        PerfectScrollbarModule,
        LoggerModule.forRoot({
            // enableSourceMaps: true,
            serverLoggingUrl: environment.apiUrl + 'api/logs',
            level: environment.logLevel,
            serverLogLevel: environment.serverLogLevel, // TRACE|DEBUG|INFO|LOG|WARN|ERROR|FATAL|OFF //level: The app will only log message for that level or higher (OFF disables the logger for the client)
            disableConsoleLogging: false,
        }),
        ScriptLoaderModule,
    ],
    providers: [
        InitConfigDataService,
        XRMApiCommunicatorService,
        MainDataProvider,
        LocalizationProvider,
        QueryParamsService,
        ErrorHandlingService,
        ErrorReportingService,
        { provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        OrderData,
        PostMessageService, // post message
        UserData,
        ServiceData,
        ConfigurationData,
        BackEndService,
        ServicesService,
        ConfigurationService,
        AuthenticationService,
        GoogleAuthService,
        FacebookAuthService,
        AppleAuthService,
        LoaderService,
        TrackingCustomDimensionsService,
        TrackingInteractionsService,
        CacheService,
        EventTrackingService,
        LocalStorageService,
        UserDataService,
        BookingDetailsService,
        BookingTransactionsService,
        AffiliatesScriptsLoaderService,
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
        },
        { provide: ErrorHandler, useClass: CustomErrorHandler },
        CryptoService,
        MyLoggerMonitor,
        CookieService,
        AppManagerService,
        ValidationService,
        BreadcrumbsService,
        PopupModalsService,
        ModalsService,
        UserAddressesPipe,
        UniqueRecordsPipe,
        SpecialData,
        InitGlobalDataService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(
        private _logger: NGXLogger,
        private _router: Router,
        private _route: ActivatedRoute,
        private _initConfigDataService: InitConfigDataService,
        private _postMessageService: PostMessageService,
        private _cacheService: CacheService,
        private _eventTrackingService: EventTrackingService,
        private _mainDataProvider: MainDataProvider,
        private _localizationProvider: LocalizationProvider,
        private _queryParams: QueryParamsService,
        private _trackingCustomData: TrackingCustomDimensionsService,
        private _trackingInteractionsService: TrackingInteractionsService,
        private injector: Injector,
        private angulartics2GoogleTagManager: Angulartics2GoogleTagManager,
        private _XRMApiCommunicatorService: XRMApiCommunicatorService,
        private _localStorageService: LocalStorageService
    ) {
        // Register logger.
        this._logger.registerMonitor(new MyLoggerMonitor());
        // Init hijack console.log function.
        this._logger.log(
            '[OBF] ' + environment.project + ' - v' + environment.VERSION
        );
                

        this.initApplication()
            .then((successResponse) => {
                this._postMessageService.sendState('init', true);

                const userDataService: UserDataService =
                    this.injector.get(UserDataService);
                const dataLayerObject = {};
                const obfOptions =
                    this._mainDataProvider.getInitializeObfOptions();
                const isUserLogged = userDataService.isUserLogged();
                const userData = userDataService.getUserData();
                const currentEnvConfig = this._mainDataProvider.obfEnv;

                this._logger.info(
                    'Current Enviroment Config:',
                    currentEnvConfig
                );
                this._logger.debug('Init obfOptions: ', obfOptions);

                //  Init OBF Data Push
                Object.assign(dataLayerObject, {
                    event: 'obf_init',
                    version: 'GA4',
                    // marChannel: obfOptions.hasOwnProperty('source_abbr')
                    //     ? obfOptions.source_abbr
                    //     : 'GORG',
                    //  country: obfOptions.hasOwnProperty('country')
                    //     ? obfOptions.country
                    //     : 'UK',
                    // servPhone: obfOptions.hasOwnProperty('phone')
                    //     ? obfOptions.phone
                    //     : null,
                    // servBrand: obfOptions.hasOwnProperty('main_url')
                    //     ? obfOptions.main_url
                    //     : null,
                    // membership: isUserLogged ? userData.membership : null,
                    // userId: isUserLogged ? userData.id : null,
                    // gtmCustomData: obfOptions.hasOwnProperty('gtm_custom_data')
                    //     ? obfOptions.gtm_custom_data
                    //     : null,
                    //  TODO add isProUser
                });
                
                // GTM Data Layer push
                this._eventTrackingService.push(dataLayerObject);
                
                const dataObject = {};
                Object.assign(dataObject, {
                    source: obfOptions.hasOwnProperty('phone')
                        ? obfOptions.phone
                        : null,
                    main_url: obfOptions.hasOwnProperty('main_url')
                        ? obfOptions.main_url
                        : null,
                    membership: isUserLogged ? userData.membership : null,
                    user_id: isUserLogged ? userData.id : null,
                });
                
                // Set init configuration
                Pixel.init({
                    enablePostPixel: false,
                    pixelEndpoint:  obfOptions?.api_url ? obfOptions?.api_url + 'api/v2/system/interactions': "https://login.fantasticxrm.com/api/v2/system/interactions",
                    application: obfOptions?.key,
                    debug: true
                });

                let initEventPush = new Pixel.InteractionController(document,window);
                
                // ServiceOS Traking Set init properties
                initEventPush.tag("init", "id-obf-" + window.location.hostname + "");
                initEventPush.tag('init_booking_form', dataObject);

                // If GA cookie exist split the value and push the data
                // if (parentCookies && parentCookies['_ga']) {
                //     var gaValue = parentCookies['_ga'];
                //     var gaCookieValue = getGoogleCookieId(gaValue);

                //     // GTM Data Layer ga cookie push
                //     this._eventTrackingService.push({
                //         'event': 'gaCookieReceived',
                //         'gaCookieValue': gaCookieValue
                //     });
                    
                // }

                // Create the same cookie in the iframe
                // if (parentCookies && parentCookies['_gcl_aw']) {
                //     var gclValue = parentCookies['_gcl_aw'];
                //     setCookie('_gcl_aw', gclValue, 1); // add cookie for 1 month
                // }

                // Successfully init the application navigate default page.
                const appManagerService: AppManagerService =
                    this.injector.get(AppManagerService);
                appManagerService.subscribeOpeningClosingEvent();
            })
            .catch((errorResponse) => {
                // console.error('Fail Application Init - ' + errorResponse);
                throw new Error('Fail Application Init - ' + errorResponse);
            });
    }

    private getConfigurationDefaultLanguage() {
        const configurationService: ConfigurationService =
                    this.injector.get(ConfigurationService);
        const configurationSettings = configurationService.getConfigurationSettings();

        let defaultLanguageItem = configurationSettings?.application?.settings?.languages.filter( (item) => 
            item.selected
        );
        
        return defaultLanguageItem && defaultLanguageItem.length && defaultLanguageItem[0].code ? defaultLanguageItem[0].code : null;
    }

    private initApplication() {
        return new Promise(async (resolve, reject) => {
            // * Get the init query params
            this._queryParams.checkParams({
                ...this._route.snapshot.params,
                ...getUrlVars(),
            });
            const queryParams = this._queryParams.getQueryParams();

            let defaultAppLanguage: string = '';

            // * Get initial obf options.
            let initialObfOptionsError = null;
            const initialObfOptions: ObfOptions =
                await this._initConfigDataService
                    .getDefaultObfOptions()
                    .catch((err) => {
                        initialObfOptionsError = err;
                    });

            if (initialObfOptionsError !== null) {
                reject(initialObfOptionsError);
                return;
            }

            // * Get parent cookies.
            try {
                parentCookies =
                    await this._initConfigDataService
                        .getParentCookies()
                        .catch((err) => {
                            //
                        });
            } catch (error) {
                //
            }

            // * Check for passed profile_id from url -> use from pro cross booking
            if (queryParams && queryParams.profile_id) {
                initialObfOptions.profile_id = queryParams.profile_id;
            }

            // * Check for passed profile from url -> use from pro cross booking
            if (queryParams && queryParams.profile) {
                initialObfOptions.profile = queryParams.profile;
            }
            

            // * Setup environment
            this._initConfigDataService.setupEnvironment(initialObfOptions);

            // * Check application is in iframe if is not stop initialization execution and navigate to xrm
            if (window === window.parent && !environment.integrate) {
                window.location.href =
                    'https://' +
                    getDomainName(this._mainDataProvider.obfEnv.api.baseUrl);
                return;
            }

            // * Init Xrm Sdk.
            let initXrmApiCommunicatorError = null;

            await this._initConfigDataService
                .initXrmApiCommunicator(initialObfOptions)
                .catch((err) => {
                    initXrmApiCommunicatorError = err;
                });

            if (initXrmApiCommunicatorError !== null) {
                reject(initXrmApiCommunicatorError);
                return;
            }

            // * Check LocalStorage
            if (this._cacheService.isLocalStorageEnabled()) {
                await this._cacheService
                .getActualDataVersion()
                .catch((err) => {
                    initGlobalDataError = err;
                });

            } else {
                console.log('[InitApplication] localStorage disabled.');
            }

            // * Request all configs needed for app init
            const initGlobalDataService: InitGlobalDataService =
                this.injector.get(InitGlobalDataService);
            let initGlobalDataError = null;

            const completeGetGlobalData = await initGlobalDataService
                .initGlobalData()
                .catch((err) => {
                    initGlobalDataError = err;
                });

            if (initGlobalDataError !== null) {
                reject(initGlobalDataError);
                return;
            }

            // Init tracking scripts GTM, FB etc. for all clients, except FS
            // if (
            //     environment.project !== 'fantastic-services' &&
            //     environment.project !== 'fantastic-services-nonbrand'
            // ) {
                this._initConfigDataService.initTrackingScripts();
            // }

            // Init affiliates scripts FS
            if (environment.project === 'fantastic-services') {
                this._initConfigDataService.initAffiliatesScripts();
            }

            // SET DEFAULT APP LANGUAGE
            const userDataService: UserDataService = this.injector.get(UserDataService),
                  userData = userDataService.getUserData();

            if (this._localStorageService.getItem('app_default_language') && this._localStorageService.getItem('app_default_language') !== 'undefined') {
                // Get default language from local storage
                defaultAppLanguage = this._localStorageService.getItem('app_default_language');
            } else if (userData && userData.language) {
                // Get default language from user data
                defaultAppLanguage = userData.language;
            } else if (this.getConfigurationDefaultLanguage()) {
                // Get default language from profile configuration
                defaultAppLanguage = this.getConfigurationDefaultLanguage();
            } else {
                defaultAppLanguage = 'en';
            }

            if (defaultAppLanguage) {
                // Set default language in localization provider
                this._localizationProvider.setAppLanguage(defaultAppLanguage, true);
            }

            const configurationService: ConfigurationService =
                    this.injector.get(ConfigurationService),
                configurationSettings: any =
                    configurationService.getConfigurationSettings(),
                errorReportingService: ErrorReportingService =
                    this.injector.get(ErrorReportingService);
            
            // defaultAppLanguage = configurationSettings.locale;

            // Set profile languages
            if (configurationSettings.languages) {
                this._localizationProvider.setProfileLanguages(
                    configurationSettings.languages
                );
            }

            // Set active languages
            if (configurationSettings.application?.settings?.languages) {
                this._localizationProvider.setActiveLanguages(configurationSettings.application.settings.languages)
            }

            // Update additional config from profileConfiguration.
            if (configurationSettings) {
                this._mainDataProvider.updateAdditionalConfig(
                    configurationSettings
                );
            }
            // Check for missconfiguration in languages
            this._localizationProvider.validateLanguages();

            // * Force Initialize the ReactNativeControllerService need for sites search.
            const reactNativeControllerService = this.injector.get(
                ReactNativeControllerService
            );

            resolve('Successfully load application');
        });
    }
}
