/* eslint-disable */
export const localLanguages: any = {
  "en": {
    "offerPopupTitle": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offerPopupDescription": "You can modify your choice via Account Settings at any time.",
    "offerPopupConfirmButton": "RECEIVE OFFERS",
    "offerPopupCancelButton": "NO, THANKS",
    "automatedByLabel": "Automated by",
    "unitModalCtaLabel": "Pick unit",
    "popularServices": "Popular services",
    "advancedPopularServices": "Select a service",
    "backNaviButton": "Back",
    "nextButtonLabel": "Next",
    "nextButtonDoubleAvailabilityConfirm": "Confirm",
    "continueButtonLabel": "Continue",
    "completeBookingLabel": "Complete booking",
    "proceedButtonLabel": "Proceed",
    "requestAQuoteLabel": "Request a quote",
    "submitButtonLabel": "Submit",
    "mobileSidebarToggleButton": "See details",
    "sameUnitSectionTitle": "Would you like to keep the same professional for this session?",
    "showProSlotsButton": "Yes, show my professional's availability",
    "showAllSlotsButton": "No, show all available slots",
    "wordAnd": "and",
    "serviceDetails": "Service details",
    "hoursMetric": "hours",
    "appointmentLabel": "Appointment",
    "wordRemove": "Remove",
    "wordComplete": "Complete",
    "wordAdd": "Add",
    "wordEdit": "Edit",
    "serviceLabel": "Service",
    "priceDetailsLabel": "Show price details",
    "chatButtonLabel": "Chat with a specialist",
    "cancellationPolicyButtonLabel": "Cancellation policy",
    "unitTotalBookingsLabel": "Total bookings",
    "unitExperienceLabel": "Experience",
    "unitLanguagesLabel": "Languages",
    "unitRatingLabel": "Rating",
    "wordFrom": "from",
    "wordReviews": "reviews",
    "unitLoadingText": "Loading the information please wait",
    "unitDocumentsLabel": "Documents",
    "unitBadgesLabel": "Badges",
    "unitDocumentViewButtonLabel": "View",
    "unitBioLabel": "Bio",
    "unitFilterNoCriteriaText": "No results for search criteria",
    "unitResetFilterButtonLabel": "View All",
    "teamUnitsLabel": "PROs",
    "wordTeam": "Team",
    "backToTeamBtnLabel": "Back to team",
    "loginFormMessage": "Before we can continue, please check the information you have provided in these fields:",
    "loginFormEmailLabel": "E-mail",
    "loginFormEmailPlaceholder": "E-mail",
    "loginFormEmailErrMsg": "Please enter valid e-mail address.",
    "loginFormPasswordLabel": "Password",
    "loginFormPasswordPlaceholder": "Password",
    "loginFormPasswordErrMsg": "Password is required.",
    "loginFormLoggedInLabel": "Keep me logged in",
    "loginFormButtonLabel": "Log in",
    "loginFormForgotPassLabel": "Forgot password?",
    "loginFormCreateAccLabel": "Create account",
    "defaultSigninErrMsg": "Unable to sign in. Please try again later or use a different method to sign in.",
    "defaultSignupErrMsg": "Unable to sign up. Please try again later or use a different method to sign in.",
    "googleSigninErrMsg": "Unable to sign in with Google. Please try again later or use a different method to sign in.",
    "googleSignupErrMsg": "Unable to sign up with Google. Please try again later or use a different method to sign in.",
    "appleSigninErrMsg": "Unable to sign in with Apple. Please try again later or use a different method to sign in.",
    "appleSignupErrMsg": "Unable to sign up with Apple. Please try again later or use a different method to sign in.",
    "facebookSigninErrMsg": "Unable to sign in with Facebook. Please try again later or use a different method to sign in.",
    "facebookSignupErrMsg": "Unable to sign up with Facebook. Please try again later or use a different method to sign in.",
    "socialAuthLabel1": "or continue with",
    "socialAuthLabel2": "Continue with",
    "socialAuthLabel3": "Sign in with",
    "socialAuthLabel4": "Sign up with",
    "loginButtonLabel": "Log in",
    "noAccountLabel1": "No Account?",
    "noAccountLabel2": "Start here",
    "logoutButtonLabel": "Log Out",
    "openLoginFormLabel": "Already have an account?",
    "openRegisterFormButtonLabel2": "Don't have an account?",
    "openLoginFormButtonLabel": "Click here to log in",
    "registerFormMessage": "You're nearly there! Before we can continue, please check the information you have provided in these fields:",
    "registerFormTitle": "Your details",
    "registerFormFirstNameLabel": "First Name",
    "registerFormFirstNamePlaceholder": "",
    "registerFormFirstNameErrMsg": "Please enter your first name.",
    "registerFormLastNameLabel": "Last Name",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Please enter your last name.",
    "registerFormBirthDateLabel": "Birthdate",
    "registerFormBirthDatePlaceholder": "YYYY-MM-DD",
    "registerFormBirthDateErrMsg": "Please enter a valid birthdate format (e.g. 1980-12-30)",
    "registerFormPhoneLabel": "Phone",
    "registerFormPhonePlaceholder": "",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Please enter your phone.",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Please enter your email address.",
    "registerFormEmailInvalidErrMsg": "Email is invalid",
    "registerFormPasswordLabel": "Password",
    "registerFormPasswordPlaceholder": "",
    "registerFormPasswordRequiredErrMsg": "Please enter a valid password",
    "registerFormPasswordInvalidErrMsg": "Your password must be at least 5 characters long.",
    "registerFormConfirmPwLabel": "Repeat password",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "Password mismatch.",
    "registerFormAddressLabel": "Address line 1",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Postcode",
    "registerFormPostcodePlaceholder": "SE1 2TH",
    "registerFormPostcodeNote": "The postcode cannot be changed because we use it to find the nearest professional and calculate the service price.",
    "registerFormTermsErrMsg": "This field is required.",
    "registerFormReferralCodeLabel": "Referral code",
    "registerFormReferralCodePlaceholder": "e.g., john8D37",
    "registerFormReferralCodeErrMsg": "Invalid referral code.",
    "registerFormButtonLabel": "Sign up with email",
    "claimAccountSectionTitle": "Claim My Account",
    "claimAccountTitle": "Have you booked via phone/chat?",
    "claimAccountDescriptionTooltip": "If you've booked a service via phone or email previously, you already have an account with us. You probably don't have a password yet, though - select Claim your account to get one.",
    "claimAccountButtonLabel": "Claim your account",
    "claimAccountFormTitle": "Claim My Account",
    "claimAccountFormDescription": "Enter the email address you first booked with, and we'll send you an email with instructions.",
    "claimAccountFormNote1": "Please note, if you've never booked with us before, you need to",
    "claimAccountFormNote2": "register",
    "claimAccountFormNote3": "first.",
    "claimAccountConfirmationMsg1": "We've just sent an email with verification link to",
    "claimAccountConfirmationMsg2": "Please check your inbox and follow the instructions!",
    "optionalLoginLabel": "Continue as a guest",
    "backToLoginBtnLabel": "< Back to log in",
    "preferencesLabel": "I want to receive updates about promotions, coupons, surveys and news via: (optional)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "Email",
    "wordRegister": "Register",
    "freeQuoteSlotTitle": "Get a price",
    "serviceSearchInputPlacehodler": "What do you need help with?",
    "unitAutoSelectTitle": "Let us assign a professional for you",
    "unitTooltipDescription": "We will assign your appointment to the professional, closest to you.",
    "contactDetailsLabel": "Contact details",
    "userAddressLabel": "Address",
    "noCoverageEmailFieldLabel": "Leave your email address and we will let you know when we service to your area.",
    "noCoverageEmailFieldErrMsg": "Enter valid email",
    "noCoverageSubmitBtnLabel": "Notify me",
    "noCoverageSuccessMsg": "Thanks, we'll let you know once we cover your area",
    "shortMonday": "Mon",
    "shortTuesday": "Tue",
    "shortWednesday": "Wed",
    "shortThursday": "Thu",
    "shortFriday": "Fri",
    "shortSaturday": "Sat",
    "shortSunday": "Sun",
    "standartPriceLabel": "Standard price",
    "priceLabel": "Price",
    "bestPriceLabel": "best price",
    "sameProfessionalLabel": "Same professional",
    "fullyBookedLabel": "Fully booked",
    "promocodeTitle": "Add promo code",
    "promocodeFieldPlaceholder": "Promocode",
    "promocodeApplyButtonLabel": "Apply",
    "exitPopupTitle": "Do you want to start again?",
    "exitPopupDefaultTitle": "You are about to leave the form.",
    "exitPopupDescription": "You can change the appointment details from the sidebar. If you have questions or need help, you can chat with an operator.",
    "exitPopupStayButtonLabel": "Stay on this page",
    "exitPopupLeaveButtonLabel": "Leave page",
    "exitPopupCommentErr": "Please let us know your reason.",
    "exitPopupReasonErr": "Please select one of these options.",
    "fileUploadLabel": "Choose files",
    "fileUploadSuccessMsg": "All your files have been successfully uploaded",
    "fileUploadLimitErrMsg": "You can upload up to 10 files.",
    "fileUploadErrMsg1": "has already been uploaded.",
    "fileUploadErrMsg2": "You can upload only 1 video.",
    "fileUploadErrMsg3": "is too large to be uploaded.",
    "fileUploadErrMsg4": "is not valid.Please, upload another one.",
    "fileUploadErrMsg5": "we cant validate the video duration.",
    "fileUploadInfoMsg1": "Up to",
    "fileUploadInfoMsg2": "files, maximum file size limit - 10MB. Accepted file types: .jpg, .png, .jpeg, .mov, .mp4, .doc, .pdf, .xls.",
    "wordMax": "Max",
    "wordUpladed": "Uploaded",
    "initChoiceLabel": "Enter your full postcode",
    "initChoiceFootNote": "Find out if our service is available in your area.",
    "unavailableSlotModalButtonTitle": "Pick another slot",
    "modalDefaultTitle": "Uh oh!",
    "modalDefaultButtonTitle": "Close",
    "modalDefaultConfirmButtonTitle": "Okay",
    "modalErrMsg1": "Something went wrong!",
    "modalErrMsg2": "Oops! Seems like something went wrong on our end! Please accept our apologies and try again.",
    "modalErrMsg3": "Whoops! Something went wrong with your Internet connection! Please, try to log in!",
    "modalErrMsg4": "Oopsie! Please try again.",
    "modalErrMsg5": "Something went wrong! Please go to \\\"My Bookings\\\" in your Account to see if your booking is saved.",
    "dropdownNoResultsMsg": "Sorry, but nothing matched your search.",
    "bookingReferenceNumberLabel": "Booking reference number",
    "referenceNumberLabel": "Reference number",
    "viewBookingButtonLabel": "View your booking",
    "rateBookingSectionTitle": "Rate your booking experience",
    "ratingTitle1": "Very Poor",
    "ratingTitle2": "Poor",
    "ratingTitle3": "Good",
    "ratingTitle4": "Very Good",
    "ratingTitle5": "Outstanding",
    "ratingTitle6": "Superb",
    "ratingTitle7": "Okay",
    "ratingSuccessMessage": "Thank you for sharing your feedback.",
    "feedbackSectionTitle": "What should we do to improve your experience?",
    "basSectionTitle": "Is there any other way we can help you?",
    "basSubmitButtonLabel": "Book another service",
    "resetPasswordSectionTitle": "Forgot your password?",
    "resetPasswordSectionDescription": "Enter your email here and we will send you a link to reset your password.",
    "resetPasswordButtonLabel": "Reset password",
    "openCalendarButtonLabel1": "View",
    "openCalendarButtonLabel2": "Full",
    "openCalendarButtonLabel3": "Month",
    "newPaymentButtonTitle": "Pay with another Card",
    "acceptedPaymentsSectionTitle": "We accept:",
    "paymentSertificateBoxTitle": "Secure Card Payment",
    "paymentSertificateBoxDesc": "This is a secure 2048-bit SSL encrypted payment.",
    "cardNumberFieldLabel": "Card number",
    "expirationFieldLabel": "Expiration",
    "cvcFieldLabel": "CVC",
    "saveButtonLabel": "Save",
    "cancelButtonLabel": "Cancel",
    "unitGenderFilterLabel": "Professional gender",
    "unitFemaleGenderValue": "Female",
    "unitMaleGenderValue": "Male",
    "unitNoGenderValue": "Don't care",
    "unitRatingFilterLabel": "Professional Rating",
    "postcodeTooltipMsg": "The postcode cannot be changed on this step because the time slot you selected is based on it.",
    "confirmAddressButtonLabel": "Confirm address",
    "addAddressButtonLabel": "Add a new address",
    "addPhoneButtonLabel": "Add a new phone",
    "rebookButtonLabel": "Rebook your service",
    "wordReschedule": "Reschedule",
    "proStatisticsTitle": "Pro statistics",
    "changeProButtonLabel": "Change Pro",
    "choosePaymentMethodTitle": "Choose payment method",
    "parkingMessage": "*Parking fee is not included in the price.",
    "paymentMethodTitle": "Payment method",
    "braintreeCardNumberLabel": "Debit or credit card number",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Please enter card number",
    "braintreeCardNumberInvalidError": "Invalid card number",
    "braintreeExpiryLabel": "Expiry date",
    "braintreeExpiryPlaceholder": "MM/YY",
    "braintreeExpiryRequiredError": "Please enter expiry date",
    "braintreeExpiryInvalidError": "Please enter valid expiry date",
    "braintreeNameOnCardLabel": "Name on card",
    "braintreeNameOnCardPlaceholder": "First and last name",
    "braintreeNameOnCardErrorMsg": "Please enter name",
    "braintreeCvvLabel": "CVV code",
    "braintreeCvvPlaceholder": "000",
    "braintreeCvvRequiredError": "Please enter cvv code",
    "braintreeCvvInvalidError": "Invalid cvv code",
    "wordBook": "Book",
    "goToHomepageButtonLabel": "Go to Homepage",
    "wordIn": "In",
    "serviceSearchLabel": "What service do you need?",
    "serviceSearchPlaceholder": "What do you need help with?",
    "serviceSearchErrMsg": "Select a service",
    "serviceSearchNoResultsElementMsg": "Don't see something that matches ...",
    "serviceSearchNoResultMsg1": "Oops,",
    "serviceSearchNoResultMsg2": "isn't currently a recognised service, but you can still try hiring someone for it!",
    "serviceSearchNoResultMsg3": "isn't currently a recognised service, but you can still try hiring someone for it! Just give us a call.",
    "hoursDetailsMsg1": "*We will send",
    "hoursDetailsMsg2": "for",
    "hourDetailsWarningMsg": "You're booking less time than we recommend. Please list your service priorities to make the best use of your booked time.",
    "defaultConfirmationMsg": "Thanks for your booking!",
    "paymentErrorMsg1": "Your card was declined, please try again or use a different one",
    "paymentErrorMsg2": "We have a technical issue with our payment system. It should be fixed soon, please try again later.",
    "paymentErrorMsg3": "Your card is not supported. Please, try a different card brand.",
    "paymentErrorMsg4": "3DS verification fail. This might be because of a wrong verification code or some issue with your bank. Please use another card or try again later.",
    "loaderDefaultMsg": "Just a sec, we're getting ready...",
    "loaderMsg1": "Updating payment method",
    "loaderMsg2": "Completing your",
    "loaderMsg3": "Completing your booking",
    "loaderMsg4": "Redirecting to request a quote...",
    "loaderMsg5": "Updating your address",
    "loaderMsg6": "Creating you a new address",
    "loaderMsg7": "Chat loading",
    "loaderMsg8": "Logging with Apple",
    "loaderMsg9": "Checking your credentials...",
    "loaderMsg10": "Logging out...",
    "loaderMsg11": "Creating your account...",
    "loaderMsg12": "Logging with Facebook",
    "loaderMsg13": "Get Facebook Data",
    "loaderMsg14": "Waiting for google authentication",
    "loaderMsg15": "Get your booking",
    "loaderMsg16": "Updating your booking",
    "loaderMsg17": "Get Availability",
    "loaderMsg18": "Configured the app",
    "loaderMsg19": "Get app profile",
    "loaderMsg20": "Get our categories",
    "loaderMsg21": "Get our services",
    "loaderMsg22": "Redirecting to request a quote...",
    "loaderMsg23": "Logging with Apple",
    "loaderMsg24": "Sync your account",
    "stepsProgressPriceTitle1": "Prices & schedule",
    "stepsProgressNoPriceTitle1": "Date & time",
    "proBookingModalDescription": "Your booking has been confirmed and is currently being processed.",
    "proBookingModalButtonLabel": "Okay",
    "resetPasswordRequiredErrMsg": "Please enter your email address!",
    "resetPasswordInvalidErrMsg": "Email is invalid",
    "resetPasswordSubmitBtnLabel": "Send reset link",
    "resetPasswordConfirmationMessage": "A link to reset your password has been sent to",
    "addressFormAddressLineOneLabel1": "Street name",
    "addressFormAddressLineOneLabel2": "Delivery address",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Flat number,House name, Street address",
    "addressFormAddressLineOneErrMsg1": "Please enter your street.",
    "addressFormAddressLineOneErrMsg2": "Please provide address",
    "addressFormAddressLineTwoLabel": "Flat / Unit / Building number",
    "addressFormAddressLineTwoPlaceholder": "",
    "addressFormAddressLineTwoErrMsg": "Please enter your flat / unit / building number.",
    "addressFormPostcodeLabel": "Postcode",
    "addressFormPostcodePlaceholder": "",
    "addressFormPostcodeInfoMsg": "You cant change the postcode.",
    "addressFormPostcodeErrMsg1": "Please enter your postcode.",
    "addressFormPostcodeErrMsg2": "Please provide postcode.",
    "anonymFormFirstNameLabel": "First name:",
    "anonymFormFirstNamePlaceholder": "i.e. John",
    "anonymFormFirstNameErrMsg": "Name is required",
    "anonymFormLastNameLabel": "Last name:",
    "anonymFormLastNamePlaceholder": "i.e. Smith",
    "anonymFormLastNameErrMsg": "Last name is required",
    "anonymFormEmailLabel": "Email:",
    "anonymFormEmailPlaceholder": "i.e. your.name@domain.com",
    "anonymFormEmailErrMsg": "Email is required",
    "anonymFormPhoneLabel": "Phone:",
    "anonymFormPhonePlaceholder": "i.e. 02034043444",
    "anonymFormPhoneErrMsg": "Phone is required",
    "signInLabel": "Log in",
    "backToSignInLabel": "Back to Sign in",
    "signUpLabel": "Sign up",
    "one": "one",
    "two": "two",
    "three": "three",
    "four": "four",
    "wordOr": "or",
    "thankYouMessage": "Thank you!",
    "wordHi": "Hi",
    "wordUnit": "Unit",
    "dealDefaultTitle": "Don't miss this special deal!",
    "editOrRescheduleConfirmationMsg1": ", we've sent you an email with your updated booking details.",
    "normalBookingConfirmationMsg1": ", we've received your booking. We'll send you an email confirmation shortly at",
    "freeQuoteConfirmationMsg": ", your inquiry has been received and is currently being processed.",
    "serviceDurationLabel": "Service Duration",
    "enterPostcodeLabel": "Enter your postcode",
    "membershipButtonText1": "Membership",
    "membershipButtonText2": "club",
    "termsAndConditionsText1": "I have read and agree with",
    "termsAndConditionsText2": "Privacy Policy",
    "termsAndConditionsText3": "and",
    "termsAndConditionsText4": "Terms & Conditions",
    "termsAndConditionsErrMsg": "Please accept our terms and conditions",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Membership club",
    "membershipClubPriceLabel": "Membership club",
    "qrCodeSectionMessage1": "To access your booking details and to have your virtual consultation on the date of your appointment, please",
    "qrCodeSectionMessage2": "download the GoFantastic app",
    "qrCodeSectionMessage3": "and log into your account.",
    "qrCodeSectionTitle": "GoFantastic - anytime, anywhere!",
    "qrCodeSectionDescription": "Manage your bookings via the free GoFantastic app - available for iOS and Android.",
    "qrCodeSectionFootNote": "To get the app, scan this code with your phone",
    "manageBookingButtonLabel": "Manage your booking in the app",
    "addToCalendarButtonLabel": "Add to your calendar",
    "benefitSectionStartOfTitle": "How",
    "benefitSectionEndOfTitle": "works",
    "addReferralCodeLabel": "Add referral code",
    "copyButtonTooltipText": "Copied Successfully!",
    "addServiceButtonTitle": "Add to booking",
    "rejectButtonTitle": "No, thanks",
    "azureSigninErrMsg": "Unable to sign in with Microsoft. Please try again later or use a different method to sign in.",
    "azureSignupErrMsg": "Unable to sign up with Microsoft. Please try again later or use a different method to sign in.",
    "LoaderMsg25": "Authenticate with Microsoft",
    "modalDefaultTitle2": "Oops! There was a small system update.",
    "modalErrMsg6": "Please go back to complete your booking.",
    "multiselectPlaceholder": "Type item or select one...",
    "dropdownDefaultOptionLabel": "Select item",
    "crossSellModalServicePriceTitle": "Service price",
    "tipProButtonLabel": "Tip your professional(s)",
    "onWelcomeTitle": "Excellent Services, Fast and Easy to Book",
    "onWelcomeDescription": "in less than 30 seconds",
    "billingFormDetailsTitle": "Contact details",
    "billingFormContactTitle": "Phone number",
    "billingFormAddressTitle": "Address",
    "multipleAvailabilityButton": "+ Add date and time"
  },
  "bg": {
    "offer_popup_title": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offer_popup_description": "You can modify your choice via Account Settings at any time.",
    "offer_popup_confirm_button": "RECEIVE OFFERS",
    "offer_popup_cancel_button": "NO, THANKS",
    "automatedByLabel": "Автоматизирано от",
    "unitModalCtaLabel": "Избор на професионалист/и",
    "popularServices": "Популярни услуги",
    "advancedPopularServices": "Изберете услуга",
    "backNaviButton": "Назад",
    "nextButtonLabel": "Напред",
    "nextButtonDoubleAvailabilityConfirm": "Потвърди",
    "continueButtonLabel": "Продължете",
    "completeBookingLabel": "Завършете",
    "proceedButtonLabel": "Продължете",
    "requestAQuoteLabel": "Заявка за Оферта",
    "submitButtonLabel": "Потвърди",
    "mobileSidebarToggleButton": "Подробности",
    "sameUnitSectionTitle": "Искате ли да запазите същия професионалист за тази сесия?",
    "showProSlotsButton": "Да, покажи наличността на моя професионалист",
    "showAllSlotsButton": "Не, покажи всички налични възможности",
    "wordAnd": "и",
    "serviceDetails": "Подробности за услугата",
    "hoursMetric": "часове",
    "appointmentLabel": "Дата и час",
    "wordRemove": "Премахни",
    "wordComplete": "Завършване",
    "wordAdd": "Добавяне",
    "wordEdit": "Редактирай",
    "serviceLabel": "Услуга",
    "priceDetailsLabel": "Покажи подробности за цената",
    "chatButtonLabel": "Пишете на специалист",
    "cancellationPolicyButtonLabel": "Политика за отказване",
    "unitTotalBookingsLabel": "Общо поръчки",
    "unitExperienceLabel": "Опит",
    "unitLanguagesLabel": "Езици",
    "unitRatingLabel": "Оценка",
    "wordFrom": "от",
    "wordReviews": "отзиви",
    "unitLoadingText": "Информацията се зарежда, моля, изчакайте",
    "unitDocumentsLabel": "Документи",
    "unitBadgesLabel": "Баджове",
    "unitDocumentViewButtonLabel": "Преглед",
    "unitBioLabel": "Биография",
    "unitFilterNoCriteriaText": "Няма резултати за тези критерии на търсене",
    "unitResetFilterButtonLabel": "Виж Всички",
    "teamUnitsLabel": "Професионалисти",
    "wordTeam": "Екип",
    "backToTeamBtnLabel": "Обратно към екип",
    "loginFormMessage": "Преди да продължим, моля проверете информацията, която сте предоставили в тези полета:",
    "loginFormEmailLabel": "Е-мейл",
    "loginFormEmailPlaceholder": "Е-мейл",
    "loginFormEmailErrMsg": "Моля въведете валиден имейл адрес.",
    "loginFormPasswordLabel": "Парола",
    "loginFormPasswordPlaceholder": "Парола",
    "loginFormPasswordErrMsg": "Изисква се парола",
    "loginFormLoggedInLabel": "Запомни ме",
    "loginFormButtonLabel": "Влезте с имейл",
    "loginFormForgotPassLabel": "Забравена парола?",
    "loginFormCreateAccLabel": "Създаване на профил",
    "defaultSigninErrMsg": "Не можахте да влезете. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "defaultSignupErrMsg": "Не можахте да се регистрирате. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "googleSigninErrMsg": "Не можахте да влезете с Google. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "googleSignupErrMsg": "Не можахте да се регистрирате с Google. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "appleSigninErrMsg": "Не можахте да влезете с Apple. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "appleSignupErrMsg": "Не можахте се регистрирате с Apple. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "facebookSigninErrMsg": "Не можахте да влезете с Facebook. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "facebookSignupErrMsg": "Не можахте да се регистрирате с Facebook. Моля, опитайте отново по-късно или използвайте друг метод за влизане.",
    "socialAuthLabel1": "или продължете с",
    "socialAuthLabel2": "Продължете с",
    "socialAuthLabel3": "Влез с",
    "socialAuthLabel4": "Регистрирай се с",
    "loginButtonLabel": "Влез",
    "noAccountLabel1": "Нямате акаунт?",
    "noAccountLabel2": "Започнете от тук",
    "logoutButtonLabel": "Изход",
    "openLoginFormLabel": "Вече имате профил?",
    "openRegisterFormButtonLabel2": "Нямате профил?",
    "openLoginFormButtonLabel": "Щракнете тук, за да влезете",
    "registerFormMessage": "Почти готово! Преди да продължим, моля проверете информацията, която сте предоставили в тези полета:",
    "registerFormTitle": "Вашите данни",
    "registerFormFirstNameLabel": "Име",
    "registerFormFirstNamePlaceholder": "",
    "registerFormFirstNameErrMsg": "Моля, въведете Вашето име.",
    "registerFormLastNameLabel": "Фамилия",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Моля въведете своята фамилия.",
    "registerFormBirthDateLabel": "Дата на раждане",
    "registerFormBirthDatePlaceholder": "ГГГГ-ММ-ДД",
    "registerFormBirthDateErrMsg": "Моля, въведете валидна рождена дата (напр. 1980-12-30)",
    "registerFormPhoneLabel": "Телефон",
    "registerFormPhonePlaceholder": "",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Въведете телефонния си номер",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Моля въведете Вашия имейл адрес",
    "registerFormEmailInvalidErrMsg": "Имейлът е невалиден",
    "registerFormPasswordLabel": "Парола",
    "registerFormPasswordPlaceholder": "",
    "registerFormPasswordRequiredErrMsg": "Моля въведете валидна парола.",
    "registerFormPasswordInvalidErrMsg": "Вашата парола трябва да съдържа най-малко ​5​ символа.",
    "registerFormConfirmPwLabel": "Повтори паролата",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "Паролата не съвпада",
    "registerFormAddressLabel": "Адрес, ред 1",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Пощенски код",
    "registerFormPostcodePlaceholder": "SE1 2TH",
    "registerFormPostcodeNote": "Пощенският код не може да бъде променен, защото го използваме, за да намерим най-близкия професионалист и да изчислим цената на услугата",
    "registerFormTermsErrMsg": "Това поле е задължително.",
    "registerFormReferralCodeLabel": "Референтен код",
    "registerFormReferralCodePlaceholder": "например john8D37",
    "registerFormReferralCodeErrMsg": "Невалиден код.",
    "registerFormButtonLabel": "Регистрирайте се с имейл",
    "claimAccountSectionTitle": "Потвърди моят профил",
    "claimAccountTitle": "Резервирали ли сте по телефон/чат?",
    "claimAccountDescriptionTooltip": "Ако по-рано сте резервирали услуга по телефон или имейл, вече имате акаунт. Вероятно все още нямате парола",
    "claimAccountButtonLabel": "Потвърдете профила си.",
    "claimAccountFormTitle": "Потвърди моят профил",
    "claimAccountFormDescription": "Въведете имейл адреса, с който сте резервирали и ще Ви изпратим имейл с инструкции.",
    "claimAccountFormNote1": "Имайте предвид, че ако никога не сте поръчвали, първо трябва да се регистрирате.",
    "claimAccountFormNote2": "регистрирайте се",
    "claimAccountFormNote3": "първо.",
    "claimAccountConfirmationMsg1": "Току-що изпратихме имейл с връзка за потвърждение до",
    "claimAccountConfirmationMsg2": "Моля, проверете входящата си кутия и следвайте инструкциите!",
    "optionalLoginLabel": "Продължете като гост",
    "backToLoginBtnLabel": "< Назад, за да влезете",
    "preferencesLabel": "Искам да получавам актуализации за промоции, купони, анкети и новини чрез: (по избор)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "Имейл",
    "wordRegister": "Регистриране",
    "freeQuoteSlotTitle": "Ценова оферта",
    "serviceSearchInputPlacehodler": "С какво можем да помогнем?",
    "unitAutoSelectTitle": "Нека назначим професионалист.",
    "unitTooltipDescription": "Ще назначим Вашия час на най-близкия до Вас професионалист.",
    "contactDetailsLabel": "Kонтакти",
    "userAddressLabel": "Адрес",
    "noCoverageEmailFieldLabel": "Оставете имейл адреса си и ние ще Ви уведомим, кога обслужваме вашия район.",
    "noCoverageEmailFieldErrMsg": "Въведете валиден имейл",
    "noCoverageSubmitBtnLabel": "Уведоми Ме",
    "noCoverageSuccessMsg": "Благодарим, ще Ви уведомим, щом районът Ви е покрит.",
    "shortMonday": "ПОН",
    "shortTuesday": "ВТО",
    "shortWednesday": "СРЯ",
    "shortThursday": "ЧЕТ",
    "shortFriday": "ПЕТ",
    "shortSaturday": "СЪБ",
    "shortSunday": "НЕД",
    "standartPriceLabel": "Стандартна цена",
    "priceLabel": "Цена",
    "bestPriceLabel": "Най-добра цена",
    "sameProfessionalLabel": "Същият професионалист",
    "fullyBookedLabel": "Изцяло резервиран",
    "promocodeTitle": "Добавяне на код за отстъпка",
    "promocodeFieldPlaceholder": "Код за отстъпка",
    "promocodeApplyButtonLabel": "Прилагане",
    "exitPopupTitle": "Искате ли да започнете отново?",
    "exitPopupDefaultTitle": "Ще напуснете формата.",
    "exitPopupDescription": "Можете да промените подробностите за срещата от страничната лента. Ако имате въпроси или нужда от помощ, можете да се свържете с оператор.",
    "exitPopupStayButtonLabel": "Остани на тази страница",
    "exitPopupLeaveButtonLabel": "Напусни страницата",
    "exitPopupCommentErr": "Моля, споделете вашата причина.",
    "exitPopupReasonErr": "Моля, изберете една от опциите.",
    "fileUploadLabel": "Изберете файлове.",
    "fileUploadSuccessMsg": "Вашите файлове са качени успешно",
    "fileUploadLimitErrMsg": "Можете да качите до 10 файла.",
    "fileUploadErrMsg1": "вече е качен.",
    "fileUploadErrMsg2": "Можете да качите само 1 видео.",
    "fileUploadErrMsg3": "е твърде голям, за да бъде качен.",
    "fileUploadErrMsg4": "не е валиден. Моля, качете друг.",
    "fileUploadErrMsg5": "не можем да определим дължината на видеото.",
    "fileUploadInfoMsg1": "До",
    "fileUploadInfoMsg2": "файлове, максимален размер - 10MB. Приети типове: .jpg, .png, .jpeg, .mov, .mp4, .doc, .pdf, .xls.",
    "wordMax": "Максимум",
    "wordUpladed": "Качено",
    "initChoiceLabel": "Въведете пълния си пощенски код",
    "initChoiceFootNote": "Разберете дали услугата е налична във вашия район.",
    "unavailableSlotModalButtonTitle": "Изберете друг",
    "modalDefaultTitle": "О, не!",
    "modalDefaultButtonTitle": "Затвори",
    "modalDefaultConfirmButtonTitle": "Потвърди",
    "modalErrMsg1": "Нещо не е наред.",
    "modalErrMsg2": "О, не! Изглежда, че нещо се обърка от наша страна! Моля, приемете нашите извинения и опитайте отново.",
    "modalErrMsg3": "О, не! Нещо се обърка с интернет връзката ви! Моля, опитайте да влезете!",
    "modalErrMsg4": "О, не! Моля, опитайте отново.",
    "modalErrMsg5": "Нещо не е наред. Моля отидете до \\\"Поръчки\\\" във Вашият акаунт за да проверите дали поръчката ви е потвърдена.",
    "dropdownNoResultsMsg": "Съжаляваме, но нищо не отговаря на търсенето Ви.",
    "bookingReferenceNumberLabel": "Референтен номер на Вашата услуга",
    "referenceNumberLabel": "Референтен номер",
    "viewBookingButtonLabel": "Вижте поръчката си",
    "rateBookingSectionTitle": "Оценете ни",
    "ratingTitle1": "Много лошо",
    "ratingTitle2": "Лошо",
    "ratingTitle3": "Добре",
    "ratingTitle4": "Много добре",
    "ratingTitle5": "Отлично",
    "ratingTitle6": "Страхотно",
    "ratingTitle7": "Не е зле",
    "ratingSuccessMessage": "Благодарим Ви за мнението!",
    "feedbackSectionTitle": "Какво да направим, за да подобрим обслужването си?",
    "basSectionTitle": "Има ли друг начин да Ви помогнем?",
    "basSubmitButtonLabel": "Поръчайте друга услуга",
    "resetPasswordSectionTitle": "Забравена парола?",
    "resetPasswordSectionDescription": "Въведете своя имейл тук и ще ви изпратим линк за възстановяване на вашата парола.",
    "resetPasswordButtonLabel": "Възстановяване на парола",
    "openCalendarButtonLabel1": "Преглед/Виж",
    "openCalendarButtonLabel2": "Пълна/Пълен",
    "openCalendarButtonLabel3": "Месец",
    "newPaymentButtonTitle": "Платете с друга карта",
    "acceptedPaymentsSectionTitle": "Приемаме.",
    "paymentSertificateBoxTitle": "Сигурно плащане с карта",
    "paymentSertificateBoxDesc": "Това плащане е криптирано със SSL.",
    "cardNumberFieldLabel": "Номер на карта",
    "expirationFieldLabel": "Валидна до",
    "cvcFieldLabel": "CVC /Таен код/",
    "saveButtonLabel": "Запазване",
    "cancelButtonLabel": "Отказ",
    "unitGenderFilterLabel": "Пол на професионалиста",
    "unitFemaleGenderValue": "Жена",
    "unitMaleGenderValue": "Мъж",
    "unitNoGenderValue": "Без значение",
    "unitRatingFilterLabel": "Рейтинг на професионалиста",
    "postcodeTooltipMsg": "Пощенският код не може да бъде променен на този етап, понеже избраният от вас интервал е базиран на него.",
    "confirmAddressButtonLabel": "Потвърждаване на адреса",
    "addAddressButtonLabel": "Добавете нов адрес",
    "addPhoneButtonLabel": "Добавете нов телефон",
    "rebookButtonLabel": "Поръчайте отново услугата",
    "wordReschedule": "Пренасрочване",
    "proStatisticsTitle": "Статистика професионалиста",
    "changeProButtonLabel": "Промяна на професионалист",
    "choosePaymentMethodTitle": "Изберете начин на плащане",
    "parkingMessage": "* Таксата за паркиране не е включена в цената.",
    "paymentMethodTitle": "Начин на плащане",
    "braintreeCardNumberLabel": "Номер на дебитна или кредитна карта",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Въведете номер",
    "braintreeCardNumberInvalidError": "Невалиден номер на картата",
    "braintreeExpiryLabel": "Валидна до",
    "braintreeExpiryPlaceholder": "ММ/ГГ",
    "braintreeExpiryRequiredError": "Моля, въведете дата на изтичане",
    "braintreeExpiryInvalidError": "Моля, въведете валидна дата.",
    "braintreeNameOnCardLabel": "Име върху картата",
    "braintreeNameOnCardPlaceholder": "Име и Фамилия",
    "braintreeNameOnCardErrorMsg": "Моля в﻿ъведете име...",
    "braintreeCvvLabel": "CVV/Таен/ код",
    "braintreeCvvPlaceholder": 123,
    "braintreeCvvRequiredError": "Моля, въведете CVV/Таен код/",
    "braintreeCvvInvalidError": "Невалиден CVV/Таен код/",
    "wordBook": "Поръчайте",
    "goToHomepageButtonLabel": "Към началната страница",
    "wordIn": "в",
    "serviceSearchLabel": "От каква услуга имате нужда?",
    "serviceSearchPlaceholder": "С какво можем да помогнем?",
    "serviceSearchErrMsg": "Изберете услуга",
    "serviceSearchNoResultsElementMsg": "Няма резултати",
    "serviceSearchNoResultMsg1": "О, не!",
    "serviceSearchNoResultMsg2": "в момента не е призната услуга, но все пак можете да опитате да наемете някой за нея!",
    "serviceSearchNoResultMsg3": "в момента не е призната услуга, но все пак можете да опитате да наемете някой за нея! Просто се свържете с нас.",
    "hoursDetailsMsg1": "*Ще изпратим",
    "hoursDetailsMsg2": "за",
    "hourDetailsWarningMsg": "Поръчвате по-малко време, отколкото препоръчваме. Моля, посочете приоритетите си, за да използвате по най-добрия начин вашето резервирано време.",
    "defaultConfirmationMsg": "Благодарим Ви за поръчката!",
    "paymentErrorMsg1": "Картата ви е отхвърлена, моля опитайте отново или използвайте друга",
    "paymentErrorMsg2": "Имаме технически проблем с платежната система. Скоро ще бъде поправен, моля, опитайте по-късно.",
    "paymentErrorMsg3": "Вашата карта не се поддържа. Моля, опитайте с друга.​",
    "paymentErrorMsg4": "Неуспешна 3DS верификация. Това може да е заради грешно написана парола или проблем с Вашата банка. Моля използвайте друга карта или опитайте отново.",
    "loaderDefaultMsg": "Само секунда, подготвяме се...",
    "loaderMsg1": "Актуализиране (на) начина на плащане",
    "loaderMsg2": "Завършване на Вашата",
    "loaderMsg3": "Завършване на Вашата поръчка",
    "loaderMsg4": "Пренасочване към заявка за оферта...",
    "loaderMsg5": "Актуализиране на адреса",
    "loaderMsg6": "Създаване на нов адрес",
    "loaderMsg7": "Чатът се зарежда",
    "loaderMsg8": "Влизане с Apple",
    "loaderMsg9": "Проверка на вашите данни...",
    "loaderMsg10": "Излизане...",
    "loaderMsg11": "Създаване на акаунт...",
    "loaderMsg12": "Влизане с Facebook",
    "loaderMsg13": "Вземете данни от Facebook",
    "loaderMsg14": "Изчаква се удостоверяване от Google",
    "loaderMsg15": "Вземете своята поръчка",
    "loaderMsg16": "Актуализиране на поръчката",
    "loaderMsg17": "Проверете за наличност",
    "loaderMsg18": "Конфигуриран в приложението?",
    "loaderMsg19": "Влезте в профила си в приложението",
    "loaderMsg20": "Вижте категориите ни",
    "loaderMsg21": "Вижте услугите ни",
    "loaderMsg22": "Пренасочване към заявка за оферта...",
    "loaderMsg23": "Влизане с Apple",
    "loaderMsg24": "Синхронизирайте профила си",
    "stepsProgressPriceTitle1": "Цени",
    "stepsProgressNoPriceTitle1": "Дата и час",
    "proBookingModalDescription": "Вашата поръчка е потвърдена и в момента се обработва.",
    "proBookingModalButtonLabel": "Потвърди",
    "resetPasswordRequiredErrMsg": "Моля въведете Вашия имейл адрес",
    "resetPasswordInvalidErrMsg": "Имейлът не е валиден",
    "resetPasswordSubmitBtnLabel": "Линк за възстановяване",
    "resetPasswordConfirmationMessage": "Линк за възстановяване на парола е изпратен на",
    "addressFormAddressLineOneLabel1": "Име на улица",
    "addressFormAddressLineOneLabel2": "Адрес за доставка",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Апартамент, блок, улица",
    "addressFormAddressLineOneErrMsg1": "Моля, въведете вашата улица.",
    "addressFormAddressLineOneErrMsg2": "Моля, въведете адрес",
    "addressFormAddressLineTwoLabel": "Апартамент / номер на сграда",
    "addressFormAddressLineTwoPlaceholder": "",
    "addressFormAddressLineTwoErrMsg": "Моля, въведете номера на вашия апартамент / сграда.",
    "addressFormPostcodeLabel": "Пощенски код",
    "addressFormPostcodePlaceholder": "",
    "addressFormPostcodeInfoMsg": "Не можете да промените пощенския код.",
    "addressFormPostcodeErrMsg1": "Моля, въведете вашия пощенски код.",
    "addressFormPostcodeErrMsg2": "Моля, въведете пощенски код.",
    "anonymFormFirstNameLabel": "Име:",
    "anonymFormFirstNamePlaceholder": "пр. Иван",
    "anonymFormFirstNameErrMsg": "Името е задължително",
    "anonymFormLastNameLabel": "Фамилия:",
    "anonymFormLastNamePlaceholder": "пр. Иванов",
    "anonymFormLastNameErrMsg": "Фамилията е задължителна",
    "anonymFormEmailLabel": "Имейл:",
    "anonymFormEmailPlaceholder": "пр. your.name@domain.com",
    "anonymFormEmailErrMsg": "Имейлът е задължителен",
    "anonymFormPhoneLabel": "Телефон:",
    "anonymFormPhonePlaceholder": "пр. 0888888888",
    "anonymFormPhoneErrMsg": "Телефонът е задължителен",
    "signInLabel": "Вход",
    "backToSignInLabel": "Назад към Вход",
    "signUpLabel": "Регистриране",
    "one": "едно",
    "two": "две",
    "three": "три",
    "four": "четири",
    "wordOr": "или",
    "thankYouMessage": "Благодарим!",
    "wordHi": "Здравейте",
    "wordUnit": "Единица",
    "dealDefaultTitle": "Не пропускайте тази специална оферта!",
    "editOrRescheduleConfirmationMsg1": ", изпратихме Ви имейл с актуализирани данни за поръчката.",
    "normalBookingConfirmationMsg1": ", получихме Вашата поръчка. Скоро ще Ви изпратим потвърждение на",
    "freeQuoteConfirmationMsg": ", Вашето запитване е получено и в момента се обработва.",
    "serviceDurationLabel": "Продължителност на услугата",
    "enterPostcodeLabel": "Въведете пощенския си код",
    "membershipButtonText1": "Абонамент/Абонаментен/",
    "membershipButtonText2": "Клуб",
    "termsAndConditionsText1": "Прочетох и съм съгласен с",
    "termsAndConditionsText2": "Политика за поверителност",
    "termsAndConditionsText3": "и",
    "termsAndConditionsText4": "Общи условия",
    "termsAndConditionsErrMsg": "Моля, приемете Oбщите условия",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Абонаментен клуб",
    "membershipClubPriceLabel": "Абонаментен клуб",
    "qrCodeSectionMessage1": "За да получите достъп до данните за вашата поръчка и да получите виртуална консултация за датата на вашата уговорка, моля",
    "qrCodeSectionMessage2": "Изтеглете GoFantastic приложението",
    "qrCodeSectionMessage3": "И влезте в профила си",
    "qrCodeSectionTitle": "Gofantastic - навсякъде, по всяко време",
    "qrCodeSectionDescription": "Управлявайте поръчките си през GoFantastic приложението - iOS и Android",
    "qrCodeSectionFootNote": "За да изтеглите приложението, сканирайте този код с телефона си",
    "manageBookingButtonLabel": "Управлявайте поръчката си в приложението",
    "addToCalendarButtonLabel": "Добавете в календара",
    "benefitSectionStartOfTitle": "Как",
    "benefitSectionEndOfTitle": "Работи",
    "addReferralCodeLabel": "Добави референтен код",
    "copyButtonTooltipText": "Копирано",
    "addServiceButtonTitle": "Добави към поръчката",
    "rejectButtonTitle": "Не, благодаря",
    "azureSigninErrMsg": "Няма възможност за влизане с Microsoft. Моля опитайте отново по-късно или изберете различен метод.",
    "azureSignupErrMsg": "Няма възможност за регистрация с Microsoft. Моля опитайте отново по-късно или изберете различен метод.",
    "LoaderMsg25": "Влез с Microsoft",
    "modalDefaultTitle2": "Имаше системно обновяване.",
    "modalErrMsg6": "Моля започнете отначало.",
    "multiselectPlaceholder": "Напишете от какво имате нужда...",
    "dropdownDefaultOptionLabel": "Изберете от опциите",
    "crossSellModalServicePriceTitle": "Цена на услуга",
    "tipProButtonLabel": "Остави бакшиш на куриера",
    "onWelcomeTitle": "Отлично обслужване, бързо и лесно резервиране",
    "onWelcomeDescription": "за по-малко от 30 секунди",
    "billingFormDetailsTitle": "Контакт детайли",
    "billingFormContactTitle": "Телефонен номер",
    "billingFormAddressTitle": "Адрес",
    "multipleAvailabilityButton": "+ Добави"
  },
  "hu": {
    "offer_popup_title": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offer_popup_description": "You can modify your choice via Account Settings at any time.",
    "offer_popup_confirm_button": "RECEIVE OFFERS",
    "offer_popup_cancel_button": "NO, THANKS",
    "automatedByLabel": "Аutomatizálta",
    "unitModalCtaLabel": "Válassz egységet",
    "popularServices": "Népszerű szolgáltatások",
    "advancedPopularServices": "Válassz egy szolgáltatást",
    "backNaviButton": "Vissza",
    "nextButtonLabel": "Következő",
    "nextButtonDoubleAvailabilityConfirm": "Megerősít",
    "continueButtonLabel": "Folytatás",
    "completeBookingLabel": "Foglalás elküldése",
    "proceedButtonLabel": "Folytatom",
    "requestAQuoteLabel": "Ajánlatot kérek",
    "submitButtonLabel": "Elküldöm",
    "mobileSidebarToggleButton": "Lásd a részleteket",
    "sameUnitSectionTitle": "Szeretnéd megtartani korábbi szakemberedet erre a munkára is?",
    "showProSlotsButton": "Igen, mutasd meg a szakember elérhetőségét",
    "showAllSlotsButton": "Nem, mutasd meg az összes elérhető időpontot",
    "wordAnd": "és",
    "serviceDetails": "Részletek",
    "hoursMetric": "órák",
    "appointmentLabel": "Időpont egyeztetés",
    "wordRemove": "Eltávolítom",
    "wordComplete": "Foglalás elküldése",
    "wordAdd": "Hozzáadás",
    "wordEdit": "Szerkesztés",
    "serviceLabel": "Szolgáltatás",
    "priceDetailsLabel": "Mutasd meg az ár részleteit",
    "chatButtonLabel": "Csevegés szakemberrel",
    "cancellationPolicyButtonLabel": "Lemondási feltételek",
    "unitTotalBookingsLabel": "Foglalásösszesítő",
    "unitExperienceLabel": "Tapasztalat",
    "unitLanguagesLabel": "Nyelvek",
    "unitRatingLabel": "Értékelés",
    "wordFrom": "értékeléstől",
    "wordReviews": "vélemények",
    "unitLoadingText": "Az információ betöltése folyamatban, kérjük, várj",
    "unitDocumentsLabel": "Dokumentumok",
    "unitBadgesLabel": "Elismerések",
    "unitDocumentViewButtonLabel": "Megtekintés",
    "unitBioLabel": "Önéletrajz",
    "unitFilterNoCriteriaText": "Nincs találat a keresési feltételekre",
    "unitResetFilterButtonLabel": "Összes megtekintése",
    "teamUnitsLabel": "Szakemberek",
    "wordTeam": "Csapat",
    "backToTeamBtnLabel": "Vissza a csapathoz",
    "loginFormMessage": "Mielőtt folytatnánk, kérjük, ellenőrizd az alábbi mezőkben megadott információkat:",
    "loginFormEmailLabel": "Email",
    "loginFormEmailPlaceholder": "Email",
    "loginFormEmailErrMsg": "Kérjük, érvényes e-mail címet adj meg.",
    "loginFormPasswordLabel": "Jelszó",
    "loginFormPasswordPlaceholder": "Jelszó",
    "loginFormPasswordErrMsg": "Jelszó szükséges.",
    "loginFormLoggedInLabel": "Maradjak bejelentkezve",
    "loginFormButtonLabel": "Bejelentkezés e-mail címmel",
    "loginFormForgotPassLabel": "Elfelejtetted a jelszavad?",
    "loginFormCreateAccLabel": "Fiók létrehozása",
    "defaultSigninErrMsg": "Nem lehet bejelentkezni. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "defaultSignupErrMsg": "Nem lehet bejelentkezni. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "googleSigninErrMsg": "Nem lehet bejelentkezni a Google-lal. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "googleSignupErrMsg": "Nem lehet bejelentkezni a Google-lal. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "appleSigninErrMsg": "Nem lehet bejelentkezni a Apple-lel. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "appleSignupErrMsg": "Nem lehet bejelentkezni a Apple-lel. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "facebookSigninErrMsg": "Nem lehet bejelentkezni a Facebook-kal. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "facebookSignupErrMsg": "Nem lehet bejelentkezni a Facebook-kal. Kérjük, próbáld újra később, vagy használj másik módszert a bejelentkezéshez.",
    "socialAuthLabel1": "vagy folytasd ezzel",
    "socialAuthLabel2": "Folytatás a következővel:",
    "socialAuthLabel3": "Jelentkezz be vele",
    "socialAuthLabel4": "Regisztráljon a",
    "loginButtonLabel": "Belépés",
    "noAccountLabel1": "Nincs fiók?",
    "noAccountLabel2": "Kezdd itt",
    "logoutButtonLabel": "Kijelentkezés",
    "openLoginFormLabel": "Már van fiókod?",
    "openRegisterFormButtonLabel2": "Nincs még fiókod?",
    "openLoginFormButtonLabel": "Kattints ide a bejelentkezéshez",
    "registerFormMessage": "Már majdnem kész vagy! Mielőtt folytatnánk, kérjük, ellenőrizd az alábbi mezőkben megadott információkat:",
    "registerFormTitle": "Az adataid",
    "registerFormFirstNameLabel": "Keresztnév",
    "registerFormFirstNamePlaceholder": "",
    "registerFormFirstNameErrMsg": "Kérjük, add meg a keresztneved.",
    "registerFormLastNameLabel": "Vezetéknév",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Kérlek írd be a vezetékneved.",
    "registerFormBirthDateLabel": "Születési dátum",
    "registerFormBirthDatePlaceholder": "ÉÉÉÉ-HH-NN",
    "registerFormBirthDateErrMsg": "Kérlek adj meg egy szabályos születési dátuom (pl. 1980-12-30)",
    "registerFormPhoneLabel": "Telefonszám",
    "registerFormPhonePlaceholder": "",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Kérlek add meg a telefonszámod",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Kérlek írd be az e-mail címed",
    "registerFormEmailInvalidErrMsg": "E-mail cím nem létezik, kérjük ellenőrizd",
    "registerFormPasswordLabel": "Jelszó",
    "registerFormPasswordPlaceholder": "",
    "registerFormPasswordRequiredErrMsg": "Kérjük adj meg egy létező jelszót",
    "registerFormPasswordInvalidErrMsg": "A jelszavadnak legalább 5 karakter hosszúságúnak kell lennie",
    "registerFormConfirmPwLabel": "Ismételd meg a megadott jelszavadat",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "A két jelszó nem egyezik meg.",
    "registerFormAddressLabel": "Cím első sora",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Irányítószám",
    "registerFormPostcodePlaceholder": 1015,
    "registerFormPostcodeNote": "Az irányítószámot nem lehet megváltoztatni, mert azt használjuk a legközelebbi szakember megkeresésére és a szolgáltatás árának kiszámítására.",
    "registerFormTermsErrMsg": "Ez a mező kötelező.",
    "registerFormReferralCodeLabel": "Kuponkód",
    "registerFormReferralCodePlaceholder": "például john8D37",
    "registerFormReferralCodeErrMsg": "Érvénytelen kuponkód.",
    "registerFormButtonLabel": "Regisztrálj e-mailben",
    "claimAccountSectionTitle": "Fiók igénylése",
    "claimAccountTitle": "Telefonon/chaten foglaltál?",
    "claimAccountDescriptionTooltip": "Ha korábban már foglaltál egy szolgáltatást telefonon vagy e-mailben, akkor már van fiókod nálunk. Valószínűleg azonban még nincs jelszavad.",
    "claimAccountButtonLabel": "Igényeld a fiókod!",
    "claimAccountFormTitle": "Fiókot nyitok",
    "claimAccountFormDescription": "Add meg az e-mail címet, amellyel először foglaltál és küldünk egy e-mailt a további teendőidről.",
    "claimAccountFormNote1": "Felhívjuk a figyelmed, hogy ha még nem foglaltál nálunk, akkor először",
    "claimAccountFormNote2": "REGISZTRÁLNOD ",
    "claimAccountFormNote3": "kell.",
    "claimAccountConfirmationMsg1": "Elküldtünk egy e-mailt az ellenőrző linkkel az email címedre",
    "claimAccountConfirmationMsg2": "Kérjük, ellenőrizd a postafiókod, és kövesd az utasításokat!",
    "optionalLoginLabel": "Továbbra is vendégként",
    "backToLoginBtnLabel": "< Vissza a bejelentkezéshez",
    "preferencesLabel": "Szeretnék értesítést kapni az akciókról, kuponokról, felmérésekről és hírekről a következő címen: (nem kötelező)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "Email",
    "wordRegister": "Regisztráció",
    "freeQuoteSlotTitle": "Kérj árat",
    "serviceSearchInputPlacehodler": "Mivel kapcsolatban van szükséged segítségre?",
    "unitAutoSelectTitle": "Kijelölünk neked egy szakembert",
    "unitTooltipDescription": "Időpontot a hozzád legközelebb lévő szakemberhez rendeljük.",
    "contactDetailsLabel": "Elérhetőségek",
    "userAddressLabel": "Cím",
    "noCoverageEmailFieldLabel": "Add meg az e-mail címed, és értesítünk, amikor a szolgáltatás elérhetővé válik a címeden.",
    "noCoverageEmailFieldErrMsg": "Adj meg érvényes e-mail-címet",
    "noCoverageSubmitBtnLabel": "Értesítést kérek",
    "noCoverageSuccessMsg": "Köszönjük, értesíteni fogunk, amint lefedjük a területet",
    "shortMonday": "HÉT",
    "shortTuesday": "KEDD",
    "shortWednesday": "SZE",
    "shortThursday": "CSÜT",
    "shortFriday": "PÉ",
    "shortSaturday": "SZO",
    "shortSunday": "VAS",
    "standartPriceLabel": "Normál ár",
    "priceLabel": "Ár",
    "bestPriceLabel": "legjobb ár",
    "sameProfessionalLabel": "Ugyanaz a szakember",
    "fullyBookedLabel": "Teljesen lefoglalt",
    "promocodeTitle": "Promóciós kód megadása",
    "promocodeFieldPlaceholder": "Promóciós kód",
    "promocodeApplyButtonLabel": "Alkalmaz",
    "exitPopupTitle": "Újra akarod kezdeni?",
    "exitPopupDefaultTitle": "A foglalásod még nem végleges, biztosan elhagyod?",
    "exitPopupDescription": "A találkozó részleteit az oldalsávon módosíthatod. Ha kérdésed van, vagy segítségre van szükséged, cseveghetsz egy operátorral.",
    "exitPopupStayButtonLabel": "Maradj ezen az oldalon",
    "exitPopupLeaveButtonLabel": "Oldal elhagyása",
    "exitPopupCommentErr": "Kérjük, osszd meg velünk az okát.",
    "exitPopupReasonErr": "Kérjük, válassz egyet az alábbi lehetőségek közül.",
    "fileUploadLabel": "Válassz fájlokat",
    "fileUploadSuccessMsg": "Az összes fájlt sikeresen feltöltötted",
    "fileUploadLimitErrMsg": "Legfeljebb 10 fájlt tölthetsz fel.",
    "fileUploadErrMsg1": "már fel lett töltve ez a file",
    "fileUploadErrMsg2": "Csak 1 videót tölthetsz fel.",
    "fileUploadErrMsg3": "túl nagy a file a feltöltéshez.",
    "fileUploadErrMsg4": "nem érvényes. Kérjük, tölts fel egy másikat.",
    "fileUploadErrMsg5": "nem tudjuk ellenőrizni a videó időtartamát.",
    "fileUploadInfoMsg1": "Akár",
    "fileUploadInfoMsg2": "fájl, maximális fájlméret - 10 MB. Elfogadott fájltípusok: .jpg, .png, .jpeg, .mov, .mp4, .doc, .pdf, .xls.",
    "wordMax": "Max",
    "wordUpladed": "Feltöltve",
    "initChoiceLabel": "Ad meg a teljes irányítószámodat",
    "initChoiceFootNote": "Tudd meg, elérhető-e szolgáltatásunk a te címeden",
    "unavailableSlotModalButtonTitle": "Válassz másikat ",
    "modalDefaultTitle": "UH Oh!",
    "modalDefaultButtonTitle": "Bezárás",
    "modalDefaultConfirmButtonTitle": "Rendben",
    "modalErrMsg1": "Valami nem sikerült! :(",
    "modalErrMsg2": "Hoppá! Úgy tűnik, valami nem működik az oldalunkon! Elnézésedet kérjük, próbáld újra.",
    "modalErrMsg3": "Hoppá! Hiba történt az internetkapcsolattal! Kérem, próbálj újra bejelentkezni!",
    "modalErrMsg4": "Hoppá! Kérlek próbáld újra.",
    "modalErrMsg5": "Valami elromlott! Kérjük, lépj be a fiók \\\"Saját foglalásaim\\\" részébe, és ellenőrizd, hogy foglalásod el lett-e mentve.",
    "dropdownNoResultsMsg": "Sajnáljuk, de semmi sem felelt meg a keresésednek.",
    "bookingReferenceNumberLabel": "Foglalási hivatkozási szám",
    "referenceNumberLabel": "Megrendelésszám",
    "viewBookingButtonLabel": "Tekintsd meg foglalásod",
    "rateBookingSectionTitle": "Értékeld foglalási tapasztalataidat",
    "ratingTitle1": "Nagyon rossz",
    "ratingTitle2": "Rossz",
    "ratingTitle3": "Jó",
    "ratingTitle4": "Nagyon jó",
    "ratingTitle5": "Kiemelkedő",
    "ratingTitle6": "Zseniális",
    "ratingTitle7": "Rendben volt",
    "ratingSuccessMessage": "Köszönjük, hogy megosztottad a véleményedet",
    "feedbackSectionTitle": "Nekünk tényleg számít a véleményed! Mondd el, hogyan lehetne még jobb szolgáltatásunk?",
    "basSectionTitle": "Van más mód, hogy segítsünk?",
    "basSubmitButtonLabel": "Foglalj időpontot más szakterületen is!",
    "resetPasswordSectionTitle": "Elfelejtetted a jelszavadat?",
    "resetPasswordSectionDescription": "Add meg e-mail címed, és küldünk egy linket a jelszó visszaállításához.",
    "resetPasswordButtonLabel": "Jelszó visszaállítása",
    "openCalendarButtonLabel1": "Nézet",
    "openCalendarButtonLabel2": "Teljes",
    "openCalendarButtonLabel3": "Hónap",
    "newPaymentButtonTitle": "Fizess másik kártyával",
    "acceptedPaymentsSectionTitle": "Elfogadjuk:",
    "paymentSertificateBoxTitle": "Biztonságos kártyás fizetés",
    "paymentSertificateBoxDesc": "Ez egy biztonságos, 2048 bites SSL-titkosított fizetés.",
    "cardNumberFieldLabel": "Kártyaszám",
    "expirationFieldLabel": "Lejárat",
    "cvcFieldLabel": "CVC",
    "saveButtonLabel": "Mentés",
    "cancelButtonLabel": "Mégsem",
    "unitGenderFilterLabel": "Nem",
    "unitFemaleGenderValue": "Női",
    "unitMaleGenderValue": "Férfi",
    "unitNoGenderValue": "Nem érdekel",
    "unitRatingFilterLabel": "Szakmai értékelés",
    "postcodeTooltipMsg": "Az irányítószám ebben a lépésben nem módosítható, mert a kiválasztott idősáv ezen alapul.",
    "confirmAddressButtonLabel": "Erősítsd meg címedet",
    "addAddressButtonLabel": "Új cím hozzáadása",
    "addPhoneButtonLabel": "Új telefon hozzáadása",
    "rebookButtonLabel": "Foglalj le egy újabb szolgáltatást",
    "wordReschedule": "Átütemezés",
    "proStatisticsTitle": "Statisztikák",
    "changeProButtonLabel": "Szakember váltás",
    "choosePaymentMethodTitle": "Válassz fizetési módot",
    "parkingMessage": "*A parkolási díjat az ár nem tartalmazza.",
    "paymentMethodTitle": "Fizetési mód",
    "braintreeCardNumberLabel": "Betéti vagy hitelkártyaszám",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Kérjük, add meg a kártya számát",
    "braintreeCardNumberInvalidError": "érvénytelen kártyaszám",
    "braintreeExpiryLabel": "Lejárati dátum",
    "braintreeExpiryPlaceholder": "HH/ÉÉ",
    "braintreeExpiryRequiredError": "Kérjük, add meg a lejárati dátumot",
    "braintreeExpiryInvalidError": "Kérjük, adj meg érvényes lejárati dátumot",
    "braintreeNameOnCardLabel": "A kártyán szereplő név",
    "braintreeNameOnCardPlaceholder": "Kereszt- és vezetéknév",
    "braintreeNameOnCardErrorMsg": "Kérjük, add meg a nevet",
    "braintreeCvvLabel": "CVV kód",
    "braintreeCvvPlaceholder": "000",
    "braintreeCvvRequiredError": "Kérjük, add meg a cvv kódot",
    "braintreeCvvInvalidError": "Érvénytelen cvv kód",
    "wordBook": "Foglalás",
    "goToHomepageButtonLabel": "Vissza a főoldalra",
    "wordIn": "",
    "serviceSearchLabel": "Milyen szolgáltatásra van szükséged?",
    "serviceSearchPlaceholder": "Mivel kapcsolatban van szükséged segítségre?",
    "serviceSearchErrMsg": "Válassz egy szolgáltatást",
    "serviceSearchNoResultsElementMsg": "Ne látsz valamit, ami megfelel...",
    "serviceSearchNoResultMsg1": "Hoppá,",
    "serviceSearchNoResultMsg2": "jelenleg nem elérhető szolgáltatás, de megpróbálhatsz keresni rá valakit!",
    "serviceSearchNoResultMsg3": "jelenleg nem elérhető szolgáltatás, de megpróbálhatsz keresni rá valakit! Hívj minket nyugodtan.",
    "hoursDetailsMsg1": "*Küldeni fogjuk",
    "hoursDetailsMsg2": "számára",
    "hourDetailsWarningMsg": "Kevesebb időre foglalsz, mint amennyit ajánlunk erre a munkára. Kérjük, sorold fel a prioritásaidat, hogy a lefoglalt időt a lehető legjobban kihasználjuk.",
    "defaultConfirmationMsg": "Köszönjük foglalásod!",
    "paymentErrorMsg1": "Kártyád elutasították. Kérjük, próbáld újra, vagy használj másikat",
    "paymentErrorMsg2": "Technikai problémánk van a fizetési rendszerünkkel. Hamarosan javítani fogjuk, kérjük, próbáld meg újra később.",
    "paymentErrorMsg3": "Az kártyád nem támogatott. Kérjük, próbálj ki egy másik kártyamárkát.",
    "paymentErrorMsg4": "3DS ellenőrzés sikertelen. Ennek oka lehet a rossz ellenőrző kód, vagy a bankoddal kapcsolatos probléma. Kérjük, használj másik kártyát, vagy próbáld meg újra később.",
    "loaderDefaultMsg": "Csak egy pillanat, készülünk...",
    "loaderMsg1": "Fizetési mód frissítése",
    "loaderMsg2": "Éppen befejezzük a",
    "loaderMsg3": "Foglalás befejezése",
    "loaderMsg4": "Átirányítás árajánlat kéréshez...",
    "loaderMsg5": "Cím frissítése",
    "loaderMsg6": "Új cím létrehozása",
    "loaderMsg7": "Chat betöltése",
    "loaderMsg8": "Naplózás az Apple-lel",
    "loaderMsg9": "Hitelesítési adatok ellenőrzése...",
    "loaderMsg10": "Kijelentkezés...",
    "loaderMsg11": "Fiók létrehozása...",
    "loaderMsg12": "Belépés Facebookkal",
    "loaderMsg13": "Facebook-adatok ellenőrzése",
    "loaderMsg14": "Várakozás a google hitelesítésre",
    "loaderMsg15": "Foglald le",
    "loaderMsg16": "Foglalásod frissítése",
    "loaderMsg17": "Elérhetőség ellenőrzése",
    "loaderMsg18": "Az alkalmazás konfigurálása",
    "loaderMsg19": "Alkalmazásprofil beállítása",
    "loaderMsg20": "Kategóriák betöltése",
    "loaderMsg21": "Vedd igénybe szolgáltatásainkat",
    "loaderMsg22": "Átirányítás árajánlat kéréshez...",
    "loaderMsg23": "Belépés az Apple-lel",
    "loaderMsg24": "Fiókod szinkronizálása",
    "stepsProgressPriceTitle1": "Árak és időpontok",
    "stepsProgressNoPriceTitle1": "Dátum idő",
    "proBookingModalDescription": "Foglalásodat visszaigazoltuk, és jelenleg feldolgozás alatt áll.",
    "proBookingModalButtonLabel": "Rendben",
    "resetPasswordRequiredErrMsg": "Kérjük, add meg e-mail címedet!",
    "resetPasswordInvalidErrMsg": "A megadott e-mail cím érvénytelen",
    "resetPasswordSubmitBtnLabel": "Reset link küldése",
    "resetPasswordConfirmationMessage": "A jelszó visszaállításához szükséges linket elküldtük neked",
    "addressFormAddressLineOneLabel1": "utcanév",
    "addressFormAddressLineOneLabel2": "Szállítási cím",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Házszám",
    "addressFormAddressLineOneErrMsg1": "Add meg a közterület nevét",
    "addressFormAddressLineOneErrMsg2": "Add meg a címed",
    "addressFormAddressLineTwoLabel": "Emelet / lakás / épület száma",
    "addressFormAddressLineTwoPlaceholder": "",
    "addressFormAddressLineTwoErrMsg": "Kérjük add meg a Emelet / lakás / épület számat.",
    "addressFormPostcodeLabel": "Irányítószám",
    "addressFormPostcodePlaceholder": "",
    "addressFormPostcodeInfoMsg": "Az irányítószámot nem tudod megváltoztatni.",
    "addressFormPostcodeErrMsg1": "Kérjük, írd be az irányítószámod.",
    "addressFormPostcodeErrMsg2": "Kérjük, add meg az irányítószámod.",
    "anonymFormFirstNameLabel": "Keresztnév:",
    "anonymFormFirstNamePlaceholder": "például \"János\"",
    "anonymFormFirstNameErrMsg": "A név megadása kötelező",
    "anonymFormLastNameLabel": "Vezetéknév:",
    "anonymFormLastNamePlaceholder": "például \"Kovács\"",
    "anonymFormLastNameErrMsg": "A vezetéknév megadása kötelező",
    "anonymFormEmailLabel": "Email:",
    "anonymFormEmailPlaceholder": "azaz te.neved@domain.com",
    "anonymFormEmailErrMsg": "E-mail cím megadása kötelező",
    "anonymFormPhoneLabel": "Telefon:",
    "anonymFormPhonePlaceholder": "például \"062034434444\"",
    "anonymFormPhoneErrMsg": "Telefon szükséges",
    "signInLabel": "Bejelentkezés",
    "backToSignInLabel": "Vissza a Bejelentkezéshez",
    "signUpLabel": "Regisztrálj",
    "one": "egy",
    "two": "kettő",
    "three": "három",
    "four": "négy",
    "wordOr": "vagy",
    "thankYouMessage": "Köszönöm!",
    "wordHi": "Szia",
    "wordUnit": "Egység",
    "dealDefaultTitle": "Ne hagyd ki ezt a különleges ajánlatot!",
    "editOrRescheduleConfirmationMsg1": ", e-mailt küldtünk a frissített foglalási adataiddal.",
    "normalBookingConfirmationMsg1": ", megkaptuk foglalásodat. Hamarosan visszaigazoló e-mailt küldünk a következő címre:",
    "freeQuoteConfirmationMsg": ", megkaptuk megkeresésed, az ügy jelenleg feldolgozás alatt áll.",
    "serviceDurationLabel": "Szolgáltatás időtartama",
    "enterPostcodeLabel": "Írd be irányítószámát",
    "membershipButtonText1": "Tagság",
    "membershipButtonText2": "klub",
    "termsAndConditionsText1": "Elolvastam és egyetértek",
    "termsAndConditionsText2": "Adatvédelmi irányelvek",
    "termsAndConditionsText3": "és",
    "termsAndConditionsText4": "Felhasználási feltételek",
    "termsAndConditionsErrMsg": "A megrendelés véglegesítéséhez fogadd el általános szerződési feltételeinket, tegyél egy pipát a négyzetbe",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Klubtagság",
    "membershipClubPriceLabel": "Klubtagság",
    "qrCodeSectionMessage1": "A virtuális felméréshez és az összes információ eléréséhez kérlek, hogy",
    "qrCodeSectionMessage2": "töltse le a GoFantastic alkalmazást",
    "qrCodeSectionMessage3": "és jelentkezz be fiókodba",
    "qrCodeSectionTitle": "GoFantastic - bármikor, bárhol!",
    "qrCodeSectionDescription": "Kezeld foglalásaidat az ingyenes GoFantastic alkalmazáson keresztül – elérhető iOS és Android rendszerre.",
    "qrCodeSectionFootNote": "Az alkalmazás letöltéséhez olvasd be ezt a kódot telefonoddal",
    "manageBookingButtonLabel": "Foglalásodat az alkalmazásban is kezelheted",
    "addToCalendarButtonLabel": "Add hozzá a naptáradhoz",
    "benefitSectionStartOfTitle": "Hogyan",
    "benefitSectionEndOfTitle": "Munkák",
    "addReferralCodeLabel": "Ajánlókód hozzáadása",
    "copyButtonTooltipText": "Sikeresen másolva",
    "addServiceButtonTitle": "Hozzáadás foglalásomhoz",
    "rejectButtonTitle": "Köszönöm nem",
    "azureSigninErrMsg": "Nem sikerült belépni Microsoft fiókoddal. Próbáld meg később vagy más módon.",
    "azureSignupErrMsg": "Nem sikerült regisztrálnod Microsoft fiókoddal. Próbáld meg később vagy más módon.",
    "LoaderMsg25": "Azonosítás Microsofttal",
    "modalDefaultTitle2": "Oops rendszerfrissítés történt.",
    "modalErrMsg6": "Légyszi fejezd be a foglalásod és ehez lépj vissza.",
    "multiselectPlaceholder": "Válassz a lehetőségek közül vagy írd be",
    "dropdownDefaultOptionLabel": "Válassz a lehetőségek közül vagy írd be",
    "crossSellModalServicePriceTitle": "Szolgáltatás ára",
    "tipProButtonLabel": "Adj borravalót a szakinak!",
    "onWelcomeTitle": "Kiváló szolgáltatások, gyors és egyszerű foglalás",
    "onWelcomeDescription": "kevesebb, mint 30 másodperc alatt",
    "billingFormDetailsTitle": "Elérhetőségeit",
    "billingFormContactTitle": "Telefonszám",
    "billingFormAddressTitle": "Cím",
    "multipleAvailabilityButton": "+ Dátum hozzáadása"
  },
  "ro": {
    "offer_popup_title": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offer_popup_description": "You can modify your choice via Account Settings at any time.",
    "offer_popup_confirm_button": "RECEIVE OFFERS",
    "offer_popup_cancel_button": "NO, THANKS",
    "automatedByLabel": "Аutomatizat de",
    "unitModalCtaLabel": "Alege unitatea",
    "popularServices": "Servicii populare",
    "advancedPopularServices": "Alege un serviciu",
    "backNaviButton": "Înapoi",
    "nextButtonLabel": "Înainte",
    "nextButtonDoubleAvailabilityConfirm": "Confirm",
    "continueButtonLabel": "Continuă",
    "completeBookingLabel": "Rezervare completă",
    "proceedButtonLabel": "De accord",
    "requestAQuoteLabel": "Cere o evaluare",
    "submitButtonLabel": "Trimite",
    "mobileSidebarToggleButton": "Vezi detalii",
    "sameUnitSectionTitle": "Vreți să păstrați aceeași echipă pentru această dată?",
    "showProSlotsButton": "Da, arată echipele disponibile",
    "showAllSlotsButton": "Nu, afișați toate sloturile disponibile",
    "wordAnd": "Și",
    "serviceDetails": "Detaliile serviciului",
    "hoursMetric": "Ore",
    "appointmentLabel": "Programare",
    "wordRemove": "Elimină",
    "wordComplete": "Finalizează",
    "wordAdd": "Adaugă",
    "wordEdit": "Editează",
    "serviceLabel": "Serviciu",
    "priceDetailsLabel": "Arată detalii despre preț",
    "chatButtonLabel": "Vorbește cu un specialist",
    "cancellationPolicyButtonLabel": "Politică de anulare",
    "unitTotalBookingsLabel": "Total rezervari",
    "unitExperienceLabel": "Experiență",
    "unitLanguagesLabel": "Limbă",
    "unitRatingLabel": "Rating",
    "wordFrom": "De la",
    "wordReviews": "Recenzie",
    "unitLoadingText": "Așteptați până se incarcă informațiile",
    "unitDocumentsLabel": "Documente",
    "unitBadgesLabel": "Insigne",
    "unitDocumentViewButtonLabel": "Vizualizare",
    "unitBioLabel": "Biografie",
    "unitFilterNoCriteriaText": "Niciun rezultat pentru criterul căutat",
    "unitResetFilterButtonLabel": "Vizualizare toate",
    "teamUnitsLabel": "PRO",
    "wordTeam": "Echipă",
    "backToTeamBtnLabel": "Înapoi la echipă",
    "loginFormMessage": "Înainte de a continua, vă rugăm să verificați informațiile scrise în aceste câmpuri",
    "loginFormEmailLabel": "E-mail",
    "loginFormEmailPlaceholder": "E-mail",
    "loginFormEmailErrMsg": "Introdu adresa de e-mail validă",
    "loginFormPasswordLabel": "Parola",
    "loginFormPasswordPlaceholder": "Parola",
    "loginFormPasswordErrMsg": "Introdu parola",
    "loginFormLoggedInLabel": "Pastreaza-mă logat",
    "loginFormButtonLabel": "Autentifica-mă cu e-mail",
    "loginFormForgotPassLabel": "Ai uitat parola?",
    "loginFormCreateAccLabel": "Creare cont",
    "defaultSigninErrMsg": "Nu se poate conecta. Încercați mai târziu sau utilizați un alt mod de a vă conecta",
    "defaultSignupErrMsg": "Nu se poate inregistra. Incercati mai tarziu sau utilizati un alt mod de a va conecta",
    "googleSigninErrMsg": "Nu se poate conecta cu Google. Încercați mai târziu sau utilizați un alt mod de a vă conecta",
    "googleSignupErrMsg": "Nu se poate inregistra cu Google. Incercati mai tarziu sau utilizati un alt mod de a va conecta",
    "appleSigninErrMsg": "Nu se poate conecta cu Apple. Încercați mai târziu sau utilizați un alt mod de a vă conecta",
    "appleSignupErrMsg": "Nu se poate inregistra cu Apple. Încercați mai târziu sau utilizați un alt mod de a vă conecta",
    "facebookSigninErrMsg": "Nu se poate conecta cu Facebook. Încearcă mai târziu sau folosește o alta metodă",
    "facebookSignupErrMsg": "Nu se poate inregistra cu Facebook. încearcă mai târziu sau folosește o altă metodă",
    "socialAuthLabel1": "sau continuă cu",
    "socialAuthLabel2": "Continuă cu",
    "socialAuthLabel3": "Autentifica-mă cu",
    "socialAuthLabel4": "Întregistreaza-mă cu",
    "loginButtonLabel": "Logare",
    "noAccountLabel1": "Nu ai cont?",
    "noAccountLabel2": "De aici",
    "logoutButtonLabel": "Delogheaza-mă",
    "openLoginFormLabel": "Ai deja cont?",
    "openRegisterFormButtonLabel2": "Nu ai cont?",
    "openLoginFormButtonLabel": "Pentru logare",
    "registerFormMessage": "Aproape ai ajuns! Înainte de a continua, vă rugăm să verificați informațiile pe care le-ați furnizat în aceste câmpuri:",
    "registerFormTitle": "Datele tale",
    "registerFormFirstNameLabel": "Numele de familie",
    "registerFormFirstNamePlaceholder": "",
    "registerFormFirstNameErrMsg": "Introdu numele de familie",
    "registerFormLastNameLabel": "Prenumele",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Introdu prenumele",
    "registerFormBirthDateLabel": "Zi de naștere",
    "registerFormBirthDatePlaceholder": "AN-LUNA-ZI",
    "registerFormBirthDateErrMsg": "Introdu data de naștere (ex. 1980-12-30)",
    "registerFormPhoneLabel": "Numărul de telefon",
    "registerFormPhonePlaceholder": "",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Introdu numărul de telefon",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Introdu adresa de e-mail",
    "registerFormEmailInvalidErrMsg": "Email-ul nu este corect",
    "registerFormPasswordLabel": "Parola",
    "registerFormPasswordPlaceholder": "",
    "registerFormPasswordRequiredErrMsg": "Introdu o parolă corectă",
    "registerFormPasswordInvalidErrMsg": "Parola trebuie sa aiba cel puțin 5 caractere",
    "registerFormConfirmPwLabel": "Reintrodu parola",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "Parola nu este aceeași",
    "registerFormAddressLabel": "Adresă",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Cod poștal",
    "registerFormPostcodePlaceholder": 715200,
    "registerFormPostcodeNote": "Codul poștal nu poate fi schimbat, deoarece îl folosim pentru a găsi cea mai apropiată și pentru a calcula prețul serviciului.",
    "registerFormTermsErrMsg": "Câmpul este obligatoriu",
    "registerFormReferralCodeLabel": "Cod referință",
    "registerFormReferralCodePlaceholder": "ex. Ion8D37",
    "registerFormReferralCodeErrMsg": "Cod referință invalid",
    "registerFormButtonLabel": "Întregistrează-mă cu email",
    "claimAccountSectionTitle": "Recupereaza-ți contul",
    "claimAccountTitle": "Ai rezervat prin prin telefon/mesaj?",
    "claimAccountDescriptionTooltip": "Dacă ai rezervat un serviciu prin telefon sau email aveți cont la noi. Probabil înca nu ai o parolă",
    "claimAccountButtonLabel": "Recuperează-ți contul",
    "claimAccountFormTitle": "Recuperează-mi contul pentru a avea unul",
    "claimAccountFormDescription": "Introduceți adresa de email cu care ați <b>rezervat</b> prima dată, iar noi vă vom trimite un email cu instrucțiuni",
    "claimAccountFormNote1": "Dacă nu ai rezervat cu noi înainte, trebuie să o faceți",
    "claimAccountFormNote2": "întregistrare",
    "claimAccountFormNote3": "primul.",
    "claimAccountConfirmationMsg1": "V-am trimis un email cu linkul de verificare",
    "claimAccountConfirmationMsg2": "Verifică mesajele primite și urmează instrucțiunile",
    "optionalLoginLabel": "Continuă ca vizitator",
    "backToLoginBtnLabel": "<Înapoi la autentificare",
    "preferencesLabel": "Vreau să primesc informații despre promoții, cupoane, sondaje și stiri prin: (opțional)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "Email",
    "wordRegister": "Întregistrare",
    "freeQuoteSlotTitle": "Obține un pret",
    "serviceSearchInputPlacehodler": "Ai nevoie de ajutor?",
    "unitAutoSelectTitle": "Îți vom asigura o echipă bună",
    "unitTooltipDescription": "Iți vom asigura programarea unei echipe cea mai apropiate de tine",
    "contactDetailsLabel": "Detaliile de contact",
    "userAddressLabel": "Adresă",
    "noCoverageEmailFieldLabel": "Scrie adresa de email pentru a vă informa când vom face servicii în zona ta",
    "noCoverageEmailFieldErrMsg": "Introdu un email valabil",
    "noCoverageSubmitBtnLabel": "Anunță-mă",
    "noCoverageSuccessMsg": "Vă mulțumim, vă vom anunța odată ce acoperim zona d-voastra.",
    "shortMonday": "Luni",
    "shortTuesday": "Marți",
    "shortWednesday": "Miercuri",
    "shortThursday": "Joi",
    "shortFriday": "Vineri",
    "shortSaturday": "Sâmbătă",
    "shortSunday": "Duminică",
    "standartPriceLabel": "Prețul standard",
    "priceLabel": "Prețul",
    "bestPriceLabel": "prețul cel mai bun",
    "sameProfessionalLabel": "Aceeași echipă",
    "fullyBookedLabel": "Complet rezervat",
    "promocodeTitle": "Adaugă cod promoțional",
    "promocodeFieldPlaceholder": "Cod promoțional",
    "promocodeApplyButtonLabel": "Aplică",
    "exitPopupTitle": "Vrei să începi de la inceput?",
    "exitPopupDefaultTitle": "Vreți să părăsiți formularul",
    "exitPopupDescription": "Poți modifica detaliile programării din bara laterală. Daca ai intrebări ne poți scrie.",
    "exitPopupStayButtonLabel": "Rămaneți pe această pagină",
    "exitPopupLeaveButtonLabel": "Părăsește pagina",
    "exitPopupCommentErr": "Scrieți motivul d-voastră",
    "exitPopupReasonErr": "Alegeți una din aceste opțiuni",
    "fileUploadLabel": "Introdu fișierele",
    "fileUploadSuccessMsg": "Toate fișierele tale au fost încarcate cu succes",
    "fileUploadLimitErrMsg": "Poți încarca până la 10 fișiere",
    "fileUploadErrMsg1": "a fost încărcat",
    "fileUploadErrMsg2": "Poți atașa doar un video.",
    "fileUploadErrMsg3": "este prea mare pentru a fi încărcat.",
    "fileUploadErrMsg4": "nu este valid. Introdu un alt fișier",
    "fileUploadErrMsg5": "Nu putem valida durata videoului",
    "fileUploadInfoMsg1": "Până la",
    "fileUploadInfoMsg2": "Limita maximă a dimensiunii fișierului - 10 mb. Fișiere acceptate tip .mov, .mp4, .doc, pdf., .xls.",
    "wordMax": "Maxim",
    "wordUpladed": "Încărcat",
    "initChoiceLabel": "Introdu codul poștal complet",
    "initChoiceFootNote": "Află dacă serviciile noastre sunt disponibile in zona d-voastră.",
    "unavailableSlotModalButtonTitle": "Alege alt slot",
    "modalDefaultTitle": "Uh oh!",
    "modalDefaultButtonTitle": "Închide",
    "modalDefaultConfirmButtonTitle": "Bine",
    "modalErrMsg1": "Ceva nu a fost corect",
    "modalErrMsg2": "Scuze, încercă din nou",
    "modalErrMsg3": "Ups! Ceva a mers rău cu conexiunea ta de internet. Încearcă din nou!",
    "modalErrMsg4": "Opps! Încearcă din nou",
    "modalErrMsg5": "Mergi la \\\"Rezervările mele\\\" in contul tău pentru a vedea rezervarile",
    "dropdownNoResultsMsg": "Scuze, însă cautarea ta nu s-a potrivit",
    "bookingReferenceNumberLabel": "Număr rezervare",
    "referenceNumberLabel": "Număr referință",
    "viewBookingButtonLabel": "Vizualizează rezervarea",
    "rateBookingSectionTitle": "Acordă o recenzie serviciului prestat",
    "ratingTitle1": "Foarte Slab",
    "ratingTitle2": "Slab",
    "ratingTitle3": "Bun",
    "ratingTitle4": "Foarte bun",
    "ratingTitle5": "Excelent",
    "ratingTitle6": "Minunat",
    "ratingTitle7": "Bine",
    "ratingSuccessMessage": "Mulțumim că ați împărtașit experienta d-voastră.",
    "feedbackSectionTitle": "Ce ar trebui să facem ca să îmbunătățim serviciul nostru?",
    "basSectionTitle": "Există un alt mod în care vă putem ajuta?",
    "basSubmitButtonLabel": "Rezervă un alt serviciu",
    "resetPasswordSectionTitle": "Ai uitat parola?",
    "resetPasswordSectionDescription": "Introdu email-ul aici și noi vă vom trimite un link pentru resetarea parolei.",
    "resetPasswordButtonLabel": "Reseteaza parola",
    "openCalendarButtonLabel1": "Vezi",
    "openCalendarButtonLabel2": "Plin",
    "openCalendarButtonLabel3": "Lună",
    "newPaymentButtonTitle": "Plătește cu alt card",
    "acceptedPaymentsSectionTitle": "Noi Acceptăm:",
    "paymentSertificateBoxTitle": "Plată Securizată cu Cardul",
    "paymentSertificateBoxDesc": "Aceasta este o plată securizată criptată SSL pe 2048 de biti.",
    "cardNumberFieldLabel": "Numărul cardului",
    "expirationFieldLabel": "Expirare",
    "cvcFieldLabel": "CVC",
    "saveButtonLabel": "Salvare",
    "cancelButtonLabel": "Anulare",
    "unitGenderFilterLabel": "Genul",
    "unitFemaleGenderValue": "Femeie",
    "unitMaleGenderValue": "Bărbat",
    "unitNoGenderValue": "Nu contează",
    "unitRatingFilterLabel": "Rating Echipă",
    "postcodeTooltipMsg": "Codul poștal nu poate fi modificat deoarece intervalul de timp se bazează pe acesta",
    "confirmAddressButtonLabel": "Confirmă adresa",
    "addAddressButtonLabel": "Adaugă o adresă noua",
    "addPhoneButtonLabel": "Adăugați un telefon nou",
    "rebookButtonLabel": "Rezerveaza din nou serviciul dorit",
    "wordReschedule": "Reprogramare",
    "proStatisticsTitle": "Statistici echipă",
    "changeProButtonLabel": "Schimbă echipa",
    "choosePaymentMethodTitle": "Alege metoda de plată",
    "parkingMessage": "*Plata de parcare nu este inclusă in preț.",
    "paymentMethodTitle": "Metoda de plata",
    "braintreeCardNumberLabel": "Debit sau credit card",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Introdu numărul cardului",
    "braintreeCardNumberInvalidError": "Numărul cardului nu este corect",
    "braintreeExpiryLabel": "Data expirării",
    "braintreeExpiryPlaceholder": "LUNĂ/AN",
    "braintreeExpiryRequiredError": "Introdu data expirării",
    "braintreeExpiryInvalidError": "Introdu data expirării validă",
    "braintreeNameOnCardLabel": "Numele deținătorului cardului",
    "braintreeNameOnCardPlaceholder": "Numele și prenumele",
    "braintreeNameOnCardErrorMsg": "Introdu numele",
    "braintreeCvvLabel": "Cod CVV",
    "braintreeCvvPlaceholder": 0,
    "braintreeCvvRequiredError": "Introdu codul cw",
    "braintreeCvvInvalidError": "Codul cw invalid",
    "wordBook": "Rezervă",
    "goToHomepageButtonLabel": "Mergi la pagina principală",
    "wordIn": "În",
    "serviceSearchLabel": "Ce serviciu aveți nevoie?",
    "serviceSearchPlaceholder": "Cu ce vă putem ajuta?",
    "serviceSearchErrMsg": "Alege un serviciu",
    "serviceSearchNoResultsElementMsg": "Ceva nu se potrivește…",
    "serviceSearchNoResultMsg1": "Oops,",
    "serviceSearchNoResultMsg2": "Nu este în prezent un serviciu recunoscut, dar iți găsim pe cineva pentru el!",
    "serviceSearchNoResultMsg3": "Ne poți suna.",
    "hoursDetailsMsg1": "*Îl vom trimite",
    "hoursDetailsMsg2": "pentru",
    "hourDetailsWarningMsg": "pentru a folosi cât mai bine timpul rezervat",
    "defaultConfirmationMsg": "Mulțumim pentru rezervare!",
    "paymentErrorMsg1": "Cardul a fost respins, incearcă din nou sau folosește alt card.",
    "paymentErrorMsg2": "Avem o problemă tehnică cu sistemul de plată. Îl vom rezolva cât mai repede. Încearcă din nou mai târziu.",
    "paymentErrorMsg3": "Acest tip de card nu a fost acceptat. Încearcă un alt card.",
    "paymentErrorMsg4": "Verificarea 3D a eșuat. Aceasta poate fi din cauza unui cod de verificare greșit sau a unei probleme cu banca ta. Foloseste un alt card sau încearcă mai tarziu.",
    "loaderDefaultMsg": "Doar o secunda, imediat va fi gata…",
    "loaderMsg1": "Se actualizează metoda de plată",
    "loaderMsg2": "Completează",
    "loaderMsg3": "Completează rezervarea",
    "loaderMsg4": "Redirecționare pentru a cere o evaluare",
    "loaderMsg5": "Actualizeaza-ți adresa",
    "loaderMsg6": "Crează o adresa noua",
    "loaderMsg7": "Mesageria se incarcă",
    "loaderMsg8": "Înregistrează-te cu Apple",
    "loaderMsg9": "Acreditarile se verifică…",
    "loaderMsg10": "Delogare…",
    "loaderMsg11": "Creare cont…",
    "loaderMsg12": "Înregistrează-te cu Facebook",
    "loaderMsg13": "Obțineți date Facebook",
    "loaderMsg14": "Asteptați autentificarea google",
    "loaderMsg15": "Obtineți rezervarea",
    "loaderMsg16": "Actualizarea rezervării d-voastre",
    "loaderMsg17": "Obțineți disponibilitatea",
    "loaderMsg18": "Am configurat aplicația",
    "loaderMsg19": "Obtineți profilul aplicatiei",
    "loaderMsg20": "Obtineți categoriile noastre",
    "loaderMsg21": "Obțineți serviciile noastre",
    "loaderMsg22": "Redireționare pentru a obtine o evaluare",
    "loaderMsg23": "Autentificare cu Apple",
    "loaderMsg24": "Sincronizează contul",
    "stepsProgressPriceTitle1": "Preț & program",
    "stepsProgressNoPriceTitle1": "Dată & timp",
    "proBookingModalDescription": "Rezervarea a fost confirmată și este în curs de procesare",
    "proBookingModalButtonLabel": "Bine",
    "resetPasswordRequiredErrMsg": "Introdu adresa de email",
    "resetPasswordInvalidErrMsg": "Email-ul este invalid",
    "resetPasswordSubmitBtnLabel": "Trimite linkul de resetare",
    "resetPasswordConfirmationMessage": "A fost trimis un link de resetare a parolei",
    "addressFormAddressLineOneLabel1": "Numele străzii",
    "addressFormAddressLineOneLabel2": "Adresa de livrare",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Numele străzii, numărul apartamentului, numărul casei",
    "addressFormAddressLineOneErrMsg1": "Introdu numele strazii",
    "addressFormAddressLineOneErrMsg2": "Introduceți adresa",
    "addressFormAddressLineTwoLabel": "Apartament / Scară / Numărul clădirii",
    "addressFormAddressLineTwoPlaceholder": "",
    "addressFormAddressLineTwoErrMsg": "Introdu numărul apartamentului / scarei / numărul cladirii",
    "addressFormPostcodeLabel": "Codul postal",
    "addressFormPostcodePlaceholder": "",
    "addressFormPostcodeInfoMsg": "Nu poți schimba codul postal.",
    "addressFormPostcodeErrMsg1": "Introdu codul postal.",
    "addressFormPostcodeErrMsg2": "",
    "anonymFormFirstNameLabel": "Numele:",
    "anonymFormFirstNamePlaceholder": "ex. Alexandru",
    "anonymFormFirstNameErrMsg": "Prenumele este necesar",
    "anonymFormLastNameLabel": "Numele:",
    "anonymFormLastNamePlaceholder": "ex. Munteanu",
    "anonymFormLastNameErrMsg": "Numele este necesar",
    "anonymFormEmailLabel": "Email:",
    "anonymFormEmailPlaceholder": "ex. nume@domeniu.ro",
    "anonymFormEmailErrMsg": "Email-ul este necesar",
    "anonymFormPhoneLabel": "Numărul de telefon:",
    "anonymFormPhonePlaceholder": "ex. 0734xxxxxx",
    "anonymFormPhoneErrMsg": "Numărul de telefon este necesar",
    "signInLabel": "Autentificare",
    "backToSignInLabel": "Înapoi la autentificare",
    "signUpLabel": "Înregistrare",
    "one": "unu",
    "two": "doi",
    "three": "trei",
    "four": "patru",
    "wordOr": "sau",
    "thankYouMessage": "Mulțumim!",
    "wordHi": "Salut",
    "wordUnit": "Unitate",
    "dealDefaultTitle": "Nu rata oferta specială",
    "editOrRescheduleConfirmationMsg1": ", a fost trimis un email cu detaliile actualizării rezervarii d-voastre",
    "normalBookingConfirmationMsg1": ", am primit rezervarea ta. Iți vom trimite un email de confirmare curând",
    "freeQuoteConfirmationMsg": ", cererea ta a fost primită și este in curs de procesare",
    "serviceDurationLabel": "Durata Serviciului",
    "enterPostcodeLabel": "Introdu codul poștal",
    "membershipButtonText1": "Calitatea de membru",
    "membershipButtonText2": "club",
    "termsAndConditionsText1": "Am citit și sunt de acord cu ",
    "termsAndConditionsText2": "Politica de confidențialitate",
    "termsAndConditionsText3": "și",
    "termsAndConditionsText4": "Termenii & condițiile",
    "termsAndConditionsErrMsg": "Accepți termenii și condițiile noastre",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Clubul membrilor",
    "membershipClubPriceLabel": "Clubul membrilor",
    "qrCodeSectionMessage1": "Pentru a accesa detaliile de rezervare și pentru a beneficia de o consultație virtuală la data programării, vă rugăm să",
    "qrCodeSectionMessage2": "descarca Landlife app",
    "qrCodeSectionMessage3": "și înregistrează-te in contul tău",
    "qrCodeSectionTitle": "Landlife - oricând, oriunde!",
    "qrCodeSectionDescription": "Gestionați-vă rezervările prin intermediul aplicației gratuite Landlife - disponibilă pentru iOS și Android.",
    "qrCodeSectionFootNote": "Pentru a funcționa aplicatia, scanează acest cod cu telefonul tău",
    "manageBookingButtonLabel": "Urmărește rezervarea în aplicație",
    "addToCalendarButtonLabel": "Adaugă în calendar.",
    "benefitSectionStartOfTitle": "Cum",
    "benefitSectionEndOfTitle": "funcționează",
    "addReferralCodeLabel": "Adăugați codul de recomandare",
    "copyButtonTooltipText": "Copiat cu succes!",
    "addServiceButtonTitle": "Adaugă la rezervare",
    "rejectButtonTitle": "Nu, mulțumesc",
    "azureSigninErrMsg": "Nu vă puteți autentifica cu Microsoft. Vă rugăm să încercați din nou mai târziu sau să utilizați o altă metodă de conectare.",
    "azureSignupErrMsg": "Imposibil să vă înregistrați cu Microsoft. Vă rugăm să încercați din nou mai târziu sau să utilizați o altă metodă de conectare.",
    "LoaderMsg25": "Autentificare cu Microsoft",
    "modalDefaultTitle2": "Ops! A existat o mică actualizare a sistemului",
    "modalErrMsg6": "Mergi înapoi la completarea rezervării",
    "multiselectPlaceholder": "Scrie elementul sau selectați unul",
    "dropdownDefaultOptionLabel": "Selectați obiectul",
    "crossSellModalServicePriceTitle": "Prețul serviciului",
    "tipProButtonLabel": "Tips pentru echipă",
    "onWelcomeTitle": "Servicii excelente, rapid și ușor de rezervat",
    "onWelcomeDescription": "în mai puțin de 30 de secunde",
    "billingFormDetailsTitle": "Detalii de contact",
    "billingFormContactTitle": "Număr de telefon",
    "billingFormAddressTitle": "Abordare",
    "multipleAvailabilityButton": "+ Adăugați data și ora"
  },
  "fr": {
    "offer_popup_title": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offer_popup_description": "You can modify your choice via Account Settings at any time.",
    "offer_popup_confirm_button": "RECEIVE OFFERS",
    "offer_popup_cancel_button": "NO, THANKS",
    "automatedByLabel": "automatisé par",
    "unitModalCtaLabel": "Veuillez sélectionner une unité",
    "popularServices": "Services populaires",
    "backNaviButton": "Précédent",
    "nextButtonLabel": "Suivant",
    "nextButtonDoubleAvailabilityConfirm": "Confirmer",
    "continueButtonLabel": "Poursuivre",
    "completeBookingLabel": "Valider la réservation",
    "proceedButtonLabel": "Procéder",
    "requestAQuoteLabel": "Demander un devis",
    "submitButtonLabel": "Envoyer",
    "mobileSidebarToggleButton": "Voir les détails",
    "sameUnitSectionTitle": "Souhaitez-vous garder le même professionnel pour cette session ?",
    "showProSlotsButton": "Oui, afficher les disponibilités de mon professionnel",
    "showAllSlotsButton": "Non, afficher toutes les disponibilités",
    "wordAnd": "et",
    "serviceDetails": "Détails",
    "hoursMetric": "heures",
    "appointmentLabel": "Réservation",
    "wordRemove": "Supprimer",
    "wordComplete": "Terminer",
    "wordAdd": "Ajouter",
    "wordEdit": "Modifier",
    "serviceLabel": "Service",
    "priceDetailsLabel": "Indiquer les détails du prix",
    "chatButtonLabel": "Ouvrir le chat",
    "cancellationPolicyButtonLabel": "Conditions générales de vente",
    "unitTotalBookingsLabel": "Total des réservations",
    "unitExperienceLabel": "Expérience",
    "unitLanguagesLabel": "Langues",
    "unitRatingLabel": "Notes",
    "wordFrom": "de",
    "wordReviews": "avis",
    "unitLoadingText": "Informations en cours de chargement, veuillez patienter",
    "unitDocumentsLabel": "Documents",
    "unitBadgesLabel": "Badges",
    "unitDocumentViewButtonLabel": "Voir",
    "unitBioLabel": "Bio",
    "unitFilterNoCriteriaText": "Aucun résultat pour cette recherche",
    "unitResetFilterButtonLabel": "Voir tout",
    "teamUnitsLabel": "PROs",
    "wordTeam": "Équipe",
    "backToTeamBtnLabel": "Retour à l'équipe",
    "loginFormMessage": "Avant de poursuivre, veuillez vérifier les informations fournies dans ces champs",
    "loginFormEmailLabel": "E-mail",
    "loginFormEmailPlaceholder": "E-mail",
    "loginFormEmailErrMsg": "Veuillez entrer une adresse e-mail valide.",
    "loginFormPasswordLabel": "Mot de passe",
    "loginFormPasswordPlaceholder": "Mot de passe",
    "loginFormPasswordErrMsg": "Le mot de passe est requis.",
    "loginFormLoggedInLabel": "Rester connecté(e)",
    "loginFormButtonLabel": "Connection avec e-mail",
    "loginFormForgotPassLabel": "Mot de passe oublié",
    "loginFormCreateAccLabel": "Créer un compte",
    "defaultSigninErrMsg": "Impossible de se connecter. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "defaultSignupErrMsg": "Impossible de s'inscrire. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "googleSigninErrMsg": "Impossible de se connecter avec Google. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "googleSignupErrMsg": "Impossible de s'inscrire avec Google. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "appleSigninErrMsg": "Impossible de se connecter avec Apple. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "appleSignupErrMsg": "Impossible de s'inscrire avec Apple. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "facebookSigninErrMsg": "Impossible de se connecter avec Facebook. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "facebookSignupErrMsg": "Impossible de s'inscrire avec Facebook. Veuillez essayer plus tard ou utiliser une autre méthode de connexion.",
    "socialAuthLabel1": "ou continuer avec",
    "socialAuthLabel2": "Continuer avec",
    "socialAuthLabel3": "Se connecter avec",
    "socialAuthLabel4": "S'inscrire avec",
    "loginButtonLabel": "Connexion",
    "noAccountLabel1": "Pas de compte?",
    "noAccountLabel2": "Commencer ici",
    "logoutButtonLabel": "Déconnexion",
    "openLoginFormLabel": "Vous avez déjà un compte ?",
    "openRegisterFormButtonLabel2": "Vous n'avez pas encore de compte ?",
    "openLoginFormButtonLabel": "Cliquer ici pour vous connecter",
    "registerFormMessage": "C'est presque fini ! Avant de continuer, merci de vérifier les informations que vous avez fournies dans ces champs",
    "registerFormTitle": "Vos détails",
    "registerFormFirstNameLabel": "Prénom",
    "registerFormFirstNamePlaceholder": "",
    "registerFormFirstNameErrMsg": "Veuillez entrer votre prénom",
    "registerFormLastNameLabel": "Nom",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Veuillez entrer votre nom",
    "registerFormBirthDateLabel": "Date de naissance",
    "registerFormBirthDatePlaceholder": "YYYY-MM-DD",
    "registerFormBirthDateErrMsg": "Veuillez entrer une date de naissance valide (ex: 1980-12-30)",
    "registerFormPhoneLabel": "Téléphone",
    "registerFormPhonePlaceholder": "",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Veuillez entrer votre numéro de téléphone.",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Veuillez entrer votre adresse e-mail.",
    "registerFormEmailInvalidErrMsg": "L'e-mail est invalide.",
    "registerFormPasswordLabel": "Mot de passe",
    "registerFormPasswordPlaceholder": "",
    "registerFormPasswordRequiredErrMsg": "Veuillez entrer un mot de passe valide",
    "registerFormPasswordInvalidErrMsg": "Votre mot de passe doit faire au moins 5 caractères.",
    "registerFormConfirmPwLabel": "Veuillez répéter le mot de passe.",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "Le mot de passe est différent.",
    "registerFormAddressLabel": "Adresse",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Code postal",
    "registerFormPostcodePlaceholder": "1205",
    "registerFormPostcodeNote": "The code postal ne peut être modifié car il nous aide à trouver l'agent le plus proche de chez vous.",
    "registerFormTermsErrMsg": "Ce champ est requis.",
    "registerFormReferralCodeLabel": "Code de parrainage",
    "registerFormReferralCodePlaceholder": "ex: JeanGHC125",
    "registerFormReferralCodeErrMsg": "Code parrainage invalide",
    "registerFormButtonLabel": "Je m'inscris avec mon e-mail",
    "claimAccountSectionTitle": "Demander mon compte",
    "claimAccountTitle": "Avez-vous réservé via téléphone/chat ?",
    "claimAccountDescriptionTooltip": "Si vous avez réservé un service par téléphone ou e-mail dans précédemment, vous avez déjà un compte chez nous. Veuillez donc demander votre compte afin de définir un mot de passe.", 
    "claimAccountButtonLabel": "Demander votre compte",
    "claimAccountFormTitle": "Demander mon compte",
    "claimAccountFormDescription": "Veuillez entrer l'adresse e-mail avec laquelle vous avez réservé et nous vous enverrons un e-mail avec des instructions pour activer votre compte.",
    "claimAccountFormNote1": "Si vous n'avez pas encore réservé avec nous avant, vous dever vous",
    "claimAccountFormNote2": "enregistrer",
    "claimAccountFormNote3": "d'abord.",
    "claimAccountConfirmationMsg1": "Nous venons d'envoyer un e-mail avec un lien de vérification à",
    "claimAccountConfirmationMsg2": "Veuillez vérifier votre boîte mail et suivre les instructions !",
    "optionalLoginLabel": "Continuer en tant qu'invité",
    "backToLoginBtnLabel": "Retour à la page de connexion",
    "preferencesLabel": "Je souhaite recevoir des mises à jour sur vos promotions, codes promo, sondages et actualités via: (optionnel)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "E-mail",
    "wordRegister": "Inscription",
    "freeQuoteSlotTitle": "Obtenir un prix",
    "serviceSearchInputPlacehodler": "Comment pouvons-nous vous aider?",
    "unitAutoSelectTitle": "Laissez-nous vous assigner un agent",
    "unitTooltipDescription": "Nous assignerons votre réservation à l'agent le plus proche.",
    "contactDetailsLabel": "Détails de contact",
    "userAddressLabel": "Adresse",
    "noCoverageEmailFieldLabel": "Veuillez laisser votre adresse e-mail et nous vous informerons lorsque votre zone sera desservie.",
    "noCoverageEmailFieldErrMsg": "Veuillez entrer une adresse e-mail valide",
    "noCoverageSubmitBtnLabel": "Veuillez me notifier",
    "noCoverageSuccessMsg": "Merci, nous vous informerons lorsque votre zone sera desservie",
    "shortMonday": "Lun",
    "shortTuesday": "Mar",
    "shortWednesday": "Mer",
    "shortThursday": "Jeu",
    "shortFriday": "Ven",
    "shortSaturday": "Sam",
    "shortSunday": "Dim",
    "standartPriceLabel": "Prix standard",
    "priceLabel": "Prix",
    "bestPriceLabel": "meilleur prix",
    "sameProfessionalLabel": "même agent(e)",
    "fullyBookedLabel": "Complet",
    "promocodeTitle": "Ajouter un code promo",
    "promocodeFieldPlaceholder": "Code promo",
    "promocodeApplyButtonLabel": "Appliquer",
    "exitPopupTitle": "Souhaitez-vous recommencer ?",
    "exitPopupDefaultTitle": "Vous allez quitter le formulaire.",
    "exitPopupDescription": "Vous pouvez modifier les détails de votre réservation depuis le résumé.",
    "exitPopupStayButtonLabel": "Rester sur cette page",
    "exitPopupLeaveButtonLabel": "Quitter la page",
    "exitPopupCommentErr": "S'il vous plait, précisez la raison.",
    "exitPopupReasonErr": "Veuillez sélectionner une de ces options.",
    "fileUploadLabel": "Choisir un ou plusieurs fichier(s)",
    "fileUploadSuccessMsg": "Vos fichiers ont été téléchargés avec succès",
    "fileUploadLimitErrMsg": "Vous pouvez télécharger jusqu'à 10 fichiers.",
    "fileUploadErrMsg1": "a déjà été téléchargé.",
    "fileUploadErrMsg2": "Vous pouvez télécharger uniquement 1 vidéo.",
    "fileUploadErrMsg3": "est trop volumineux.",
    "fileUploadErrMsg4": "est invalide. Veuillez télécharger un autre fichieré.",
    "fileUploadErrMsg5": "nous ne pouvons pas valider la durée de la vidéo.",
    "fileUploadInfoMsg1": "Jusqu'à",
    "fileUploadInfoMsg2": "fichiers, taille maximale - 10 MB. Fichiers acceptés: .jpg, .png, .jpeg, .mov, .mp4, .doc, .pdf, .xls.",
    "wordMax": "Max",
    "wordUpladed": "Téléchargé",
    "initChoiceLabel": "Veuillez entrer votre code postal complet",
    "initChoiceFootNote": "Découvrez si votre service est disponible dans votre commune.",
    "unavailableSlotModalButtonTitle": "Veuillez sélectionner un autre créneau",
    "modalDefaultTitle": "Oh oh !",
    "modalDefaultButtonTitle": "Presque",
    "modalDefaultConfirmButtonTitle": "Ok",
    "modalErrMsg1": "Une erreur est survenue.",
    "modalErrMsg2": "Oups ! Une erreur est survenue. Veuillez réessayer.",
    "modalErrMsg3": "Oups ! Veuillez vérifier votre connexion internet. Essayez de vous connecter.",
    "modalErrMsg4": "Oups ! Veuillez réessayer.",
    "modalErrMsg5": "Une erreur est survenue. Veuillez vous rendre dans votre compte afin de vous assurer que votre réservation a bien été enregistrée.",
    "dropdownNoResultsMsg": "Désolés, votre recherche n'a rien donné.",
    "bookingReferenceNumberLabel": "Numéro de référence de la réservation",
    "referenceNumberLabel": "Numéro de référence",
    "viewBookingButtonLabel": "Voir votre réservation",
    "rateBookingSectionTitle": "Veuillez noter votre expérience",
    "ratingTitle1": "Mauvaise",
    "ratingTitle2": "Médiocre",
    "ratingTitle3": "Bonne",
    "ratingTitle4": "Très bonne",
    "ratingTitle5": "Excellente",
    "ratingTitle6": "Parfaite",
    "ratingTitle7": "Ok",
    "ratingSuccessMessage": "Nous vous remercions d'avoir partagé votre feedback !",
    "feedbackSectionTitle": "Que devrions-nous faire pour améliorer votre expérience ?",
    "basSectionTitle": "Il y a t'il autre chose avec quoi nous pourrions vous aider ?",
    "basSubmitButtonLabel": "Réserver un autre service",
    "resetPasswordSectionTitle": "Mot de passe oublié",
    "resetPasswordSectionDescription": "Veuillez entrer votre e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe.",
    "resetPasswordButtonLabel": "Réinitialiser le mot de passe",
    "openCalendarButtonLabel1": "Voir votre réservation",
    "openCalendarButtonLabel2": "Entier",
    "openCalendarButtonLabel3": "Mois",
    "newPaymentButtonTitle": "Payer avec une autre carte",
    "acceptedPaymentsSectionTitle": "Nous acceptons",
    "paymentSertificateBoxTitle": "Paiement sécurisé par carte",
    "paymentSertificateBoxDesc": "Ceci est un paiement 2048-bit SSL sécurisé et encrypté.",
    "cardNumberFieldLabel": "Numéro de carte",
    "expirationFieldLabel": "Expiration",
    "cvcFieldLabel": "CVC",
    "saveButtonLabel": "Enregistrer",
    "cancelButtonLabel": "Supprimer",
    "unitGenderFilterLabel": "Genre",
    "unitFemaleGenderValue": "Femme",
    "unitMaleGenderValue": "Homme",
    "unitNoGenderValue": "Autre",
    "unitRatingFilterLabel": "Note de l'agent",
    "postcodeTooltipMsg": "Le code postal ne peut être changé à cette étape car le créneau sélectionné est basé dessus.",
    "confirmAddressButtonLabel": "Confirmer l'adresse",
    "addAddressButtonLabel": "Aouter une nouvelle adresse",
    "addPhoneButtonLabel": "Ajouter un nouveau téléphone",
    "rebookButtonLabel": "Réserver le même service",
    "wordReschedule": "Reprogrammer",
    "proStatisticsTitle": "Statistiques de l'agent",
    "changeProButtonLabel": "Changer d'agent",
    "choosePaymentMethodTitle": "Choisir une méthode de paiement",
    "parkingMessage": "*Le prix du parking n'est pas inclus dans le prix",
    "paymentMethodTitle": "Méthode de paiement",
    "braintreeCardNumberLabel": "Numéro de carte de crédit/débit",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Veuillez entrer votre numéro de carte",
    "braintreeCardNumberInvalidError": "Numéro de carte invalide",
    "braintreeExpiryLabel": "Date d'expiration",
    "braintreeExpiryPlaceholder": "MM/YY",
    "braintreeExpiryRequiredError": "Veuillez entrer une date d'expiration",
    "braintreeExpiryInvalidError": "Veuillez entrer une date d'expiration valide",
    "braintreeNameOnCardLabel": "Nom sur la carte",
    "braintreeNameOnCardPlaceholder": "Prénom et Nom",
    "braintreeNameOnCardErrorMsg": "Veuillez entrer le nom",
    "braintreeCvvLabel": "Code CVV",
    "braintreeCvvPlaceholder": "000",
    "braintreeCvvRequiredError": "Veuillez enter le code CVV",
    "braintreeCvvInvalidError": "Code CVV invalide",
    "wordBook": "Réserver",
    "goToHomepageButtonLabel": "Aller à l'accueil",
    "wordIn": "Dans",
    "serviceSearchLabel": "De quel service avez-vous besoin ?",
    "serviceSearchPlaceholder": "Comment pouvons-nous vous aider ?",
    "serviceSearchErrMsg": "Veuillez sélectionner un service",
    "serviceSearchNoResultsElementMsg": "Rien ne correspond",
    "serviceSearchNoResultMsg1": "Oups",
    "serviceSearchNoResultMsg2": "n'est pas un service proposé pour le moment !",
    "serviceSearchNoResultMsg3": "n'est pas un service proposé pour le moment !",
    "hoursDetailsMsg1": "*Nous enverrons",
    "hoursDetailsMsg2": "pour",
    "hourDetailsWarningMsg": "Vous réservez moins de temps que ce que nous recommandons. La qualité du service ne pourra donc pas être conservée.",
    "defaultConfirmationMsg": "Merci pour votre réservation !",
    "paymentErrorMsg1": "Votre carte a été refusée. Veuillez réessayer ou utiliser une autre carte.",
    "paymentErrorMsg2": "Une erreur technique est survenue. Veuillez réessayer plus tard.",
    "paymentErrorMsg3": "Votre type de carte n'est pas acceptée. Veuillez essayer avec une autre carte.",
    "paymentErrorMsg4": "La vérification 3DS a échoué. Veuillez réessayer.",
    "loaderDefaultMsg": "Un instant, nous ajustons les détails...",
    "loaderMsg1": "Mise à jour de la méthode de paiement",
    "loaderMsg2": "Nous complétons votre",
    "loaderMsg3": "Nous complétons votre réservation",
    "loaderMsg4": "Nous vous redirigeons au formulaire de devis",
    "loaderMsg5": "Mise à jour de votre adresse",
    "loaderMsg6": "Nous créons une nouvelle adresse",
    "loaderMsg7": "Chargement du chat",
    "loaderMsg8": "Connexion avec Apple",
    "loaderMsg9": "Nous vérifions vos identifiants",
    "loaderMsg10": "Déconnexion...",
    "loaderMsg11": "Nous créons votre compte...",
    "loaderMsg12": "Connexion avec Facebook",
    "loaderMsg13": "Obtenir vos informations Facebook",
    "loaderMsg14": "En attente de l'authentifiaction Google",
    "loaderMsg15": "Obtenir votre réservation",
    "loaderMsg16": "Mise à jour de la réservation",
    "loaderMsg17": "Voir les disponibilités",
    "loaderMsg18": "L'app a été configurée",
    "loaderMsg19": "Obtenir un profile dans l'app",
    "loaderMsg20": "Obtenir nos catégories",
    "loaderMsg21": "Obtenir nos services",
    "loaderMsg22": "Redirection au formulaire de devis...",
    "loaderMsg23": "Connexion avec Apple",
    "loaderMsg24": "Synchroniser votre compte",
    "stepsProgressPriceTitle1": "Prix et calendrier",
    "stepsProgressNoPriceTitle1": "Date et heure",
    "proBookingModalDescription": "Votre réservation a été confirmée et est actuellement en cours de traitement",
    "proBookingModalButtonLabel": "Ok",
    "resetPasswordRequiredErrMsg": "Veuiller entrer votre adresse e-mail",
    "resetPasswordInvalidErrMsg": "L'e-mail est invalide",
    "resetPasswordSubmitBtnLabel": "Envoyer un lien de réinitialisation",
    "resetPasswordConfirmationMessage": "Un lien de réinitialisation a été envoyé à",
    "addressFormAddressLineOneLabel1": "Nom de la rue",
    "addressFormAddressLineOneLabel2": "Adresse de livraison",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Nom et numéro de la rue",
    "addressFormAddressLineOneErrMsg1": "Veuillez entrer le nom et numéro de la rue",
    "addressFormAddressLineOneErrMsg2": "Veuillez renseigner une adresse",
    "addressFormAddressLineTwoLabel": "Appartement / Étage / Raison sociale",
    "addressFormAddressLineTwoPlaceholder": "",
    "addressFormAddressLineTwoErrMsg": "Veuillez entrer votre Appartement / Étage / Raison sociale",
    "addressFormPostcodeLabel": "Code postal",
    "addressFormPostcodePlaceholder": "",
    "addressFormPostcodeInfoMsg": "Vous ne pouvez pas changer le code postal.",
    "addressFormPostcodeErrMsg1": "Veuillez entrer votre code postal.",
    "addressFormPostcodeErrMsg2": "Veuillez renseigner votre code postal.",
    "anonymFormFirstNameLabel": "Prénom",
    "anonymFormFirstNamePlaceholder": "ex: Jean",
    "anonymFormFirstNameErrMsg": "Le prénom est requis",
    "anonymFormLastNameLabel": "Nom",
    "anonymFormLastNamePlaceholder": "ex: Müller",
    "anonymFormLastNameErrMsg": "Le nom de famille est requis",
    "anonymFormEmailLabel": "E-mail",
    "anonymFormEmailPlaceholder": "ex: jean@exemple.com",
    "anonymFormEmailErrMsg": "L'e-mail est requis",
    "anonymFormPhoneLabel": "Téléphone",
    "anonymFormPhonePlaceholder": "ex: 041761111111",
    "anonymFormPhoneErrMsg": "Le téléphone est requis",
    "signInLabel": "Connexion",
    "backToSignInLabel": "Retour à la connexion",
    "signUpLabel": "Inscription",
    "one": "un",
    "two": "deux",
    "three": "trois",
    "four": "quatre",
    "wordOr": "ou",
    "thankYouMessage": "Merci !",
    "wordHi": "Bonjour",
    "wordUnit": "Unité",
    "dealDefaultTitle": "Ne rater pas cette offre spéciale !",
    "editOrRescheduleConfirmationMsg1": ", nous vous avons envoyé un mail avec vos détails mis à jour.",
    "normalBookingConfirmationMsg1": ", nous avons reçu votre réservation. Nous vous enverrons un mail de confirmation à",
    "freeQuoteConfirmationMsg": ", votre demande a été reçue et est actuellement en cours de traitement.",
    "serviceDurationLabel": "Durée du service",
    "enterPostcodeLabel": "Veuillez entrer votre code postal.",
    "membershipButtonText1": "Adhésion",
    "membershipButtonText2": "club",
    "termsAndConditionsText1": "J'ai lu et j'accepte", 
    "termsAndConditionsText2": "la Politique de Privacité",
    "termsAndConditionsText3": "et",
    "termsAndConditionsText4": "les Conditions Générales de Vente",
    "termsAndConditionsErrMsg": "Veuillez accepter nos conditions générales de vente",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Club membres",
    "membershipClubPriceLabel": "Club membres",
    "qrCodeSectionMessage1": "Pour accéder aux détails de la réservation et avoir votre consultation virtuelle le jour de votre réservation, veuillez",
    "qrCodeSectionMessage2": "",
    "qrCodeSectionMessage3": "connecter-vous à votre compte.",
    "qrCodeSectionTitle": "",
    "qrCodeSectionDescription": "",
    "qrCodeSectionFootNote": "",
    "manageBookingButtonLabel": "",
    "addToCalendarButtonLabel": "Ajouter à votre calendrier",
    "benefitSectionStartOfTitle": "Comment",
    "benefitSectionEndOfTitle": "marche",
    "addReferralCodeLabel": "Code de parrainage",
    "copyButtonTooltipText": "Copié avec succès !",
    "addServiceButtonTitle": "Ajouter à la réservation",
    "rejectButtonTitle": "Non merci",
    "azureSigninErrMsg": "Impossible de se connecter avec Microsoft. Veuillez essayer plus tard ou utiliser une méthode différente.",
    "azureSignupErrMsg": "Impossible de s'inscrire avec Microsoft. Veuillez essayer plus tard ou utiliser une méthode différente.",
    "LoaderMsg25": "Authentification avec Microsoft",
    "modalDefaultTitle2": "Oups ! Une mise à jour est survenue.",
    "modalErrMsg6": "Veuillez retourner en arrière pour valider votre réservation.",
    "multiselectPlaceholder": "Veuillez sélectionner une option",
    "dropdownDefaultOptionLabel": "Sélectionner une option",
    "crossSellModalServicePriceTitle": "Prix du service",
    "tipProButtonLabel": "Laissez un pourboire à votre agent",
    "onWelcomeTitle": "Excellents services, réservation rapide et facile",
    "onWelcomeDescription": "en moins de 30 secondes",
    "billingFormDetailsTitle": "Détails du contact",
    "billingFormContactTitle": "Numéro de téléphone",
    "billingFormAddressTitle": "Аdresse",
    "multipleAvailabilityButton": "+ Ajouter la date et l'heure"
  },
  "lt": {
    "offer_popup_title": "Would you like to receive promotions, tips and discounts via E-mail?",
    "offer_popup_description": "You can modify your choice via Account Settings at any time.",
    "offer_popup_confirm_button": "RECEIVE OFFERS",
    "offer_popup_cancel_button": "NO, THANKS",
    "automatedByLabel": "Automatizuotas",
    "unitModalCtaLabel": "Pasirinkite vienetą",
    "popularServices": "Populiarios paslaugos",
    "advancedPopularServices": "Pasirinkite paslaugą",
    "backNaviButton": "Atgal",
    "nextButtonLabel": "Kitas",
    "nextButtonDoubleAvailabilityConfirm": "Patvirtinti",
    "continueButtonLabel": "Tęsti",
    "completeBookingLabel": "Užbaikite užsakymą",
    "proceedButtonLabel": "Tęsti toliau",
    "requestAQuoteLabel": "Prašyti kainos pasiūlymo",
    "submitButtonLabel": "Pateikti",
    "mobileSidebarToggleButton": "Žiūrėti išsamią informaciją",
    "sameUnitSectionTitle": "Ar norėtumėte pasilikti tą patį specialistą šiai sesijai?",
    "showProSlotsButton": "Taip, rodykite mano specialisto prieinamumą",
    "showAllSlotsButton": "Ne, rodyti visus galimus laiko tarpus",
    "wordAnd": "ir",
    "serviceDetails": "Paslaugos informacija",
    "hoursMetric": "valandinis",
    "appointmentLabel": "Paskyrimas",
    "wordRemove": "Pašalinti",
    "wordComplete": "Užbaigti",
    "wordAdd": "Pridėti",
    "wordEdit": "Redaguoti",
    "serviceLabel": "Paslauga",
    "priceDetailsLabel": "Rodyti kainos informaciją",
    "chatButtonLabel": "Kalbėkitės su specialistu",
    "cancellationPolicyButtonLabel": "Atšaukimo politika",
    "unitTotalBookingsLabel": "Bendras užsakymų skaičius",
    "unitExperienceLabel": "Patirtis",
    "unitLanguagesLabel": "Kalbos",
    "unitRatingLabel": "Įvertinimas",
    "wordFrom": "nuo",
    "wordReviews": "atsiliepimai",
    "unitLoadingText": "Įkeliama informacija, prašome palaukti",
    "unitDocumentsLabel": "Dokumentai",
    "unitBadgesLabel": "Ženkliukai",
    "unitDocumentViewButtonLabel": "Peržiūrėti",
    "unitBioLabel": "Biografija",
    "unitFilterNoCriteriaText": "Pagal paieškos kriterijus nėra rezultatų",
    "unitResetFilterButtonLabel": "Peržiūrėti visus",
    "teamUnitsLabel": "Privalumai",
    "wordTeam": "Komanda",
    "backToTeamBtnLabel": "Atgal į komandas",
    "loginFormMessage": "Prieš tęsdami, patikrinkite informaciją, kurią pateikėte šiuose laukeliuose:",
    "loginFormEmailLabel": "El. paštas",
    "loginFormEmailPlaceholder": "El. paštas",
    "loginFormEmailErrMsg": "Įveskite galiojantį el. pašto adresą.",
    "loginFormPasswordLabel": "Slaptažodis",
    "loginFormPasswordPlaceholder": "Slaptažodis",
    "loginFormPasswordErrMsg": "Reikalingas slaptažodis.",
    "loginFormLoggedInLabel": "Laikyti mane prisijungusį",
    "loginFormButtonLabel": "Prisijungti su el. paštu",
    "loginFormForgotPassLabel": "Pamiršote slaptažodį?",
    "loginFormCreateAccLabel": "Sukurti paskyrą",
    "defaultSigninErrMsg": "Nepavyko prisijungti. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "defaultSignupErrMsg": "Nepavyko prisiregistruoti. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "googleSigninErrMsg": "Nepavyko prisijungti su Google. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "googleSignupErrMsg": "Nepavyko prisiregistruoti su Google. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "appleSigninErrMsg": "Nepavyko prisijungti su Apple. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "appleSignupErrMsg": "Nepavyko prisiregistruoti su Apple. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "facebookSigninErrMsg": "Nepavyko prisijungti su Facebook. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "facebookSignupErrMsg": "Nepavyko prisiregistruoti su Facebook. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "socialAuthLabel1": "arba tęskite su",
    "socialAuthLabel2": "Tęsti su",
    "socialAuthLabel3": "Prisijungti su",
    "socialAuthLabel4": "Prisiregistruoti su",
    "loginButtonLabel": "Prisijungti",
    "noAccountLabel1": "Neturite paskyros?",
    "noAccountLabel2": "Pradėkite čia",
    "logoutButtonLabel": "Atsijungti",
    "openLoginFormLabel": "Jau turite paskyrą?",
    "openRegisterFormButtonLabel2": "Neturite paskyros?",
    "openLoginFormButtonLabel": "Spauskite čia norėdami prisijungti",
    "registerFormMessage": "Jūs jau beveik baigėte! Prieš tęsdami, patikrinkite informaciją, kurią pateikėte šiuose laukeliuose:",
    "registerFormTitle": "Jūsų informacija",
    "registerFormFirstNameLabel": "Vardas",
    "registerFormFirstNamePlaceholder": "",	
    "registerFormFirstNameErrMsg": "Įveskite savo vardą.",
    "registerFormLastNameLabel": "Pavardė",
    "registerFormLastNamePlaceholder": "",
    "registerFormLastNameErrMsg": "Įveskite savo pavardę.",
    "registerFormBirthDateLabel": "Gimimo data",
    "registerFormBirthDatePlaceholder": "MMMM-MM-DD",
    "registerFormBirthDateErrMsg": "Įveskite tinkamą gimimo datos formatą (pvz. 1980-12-30)",
    "registerFormPhoneLabel": "Telefonas",
    "registerFormPhonePlaceholder":"",
    "customPhoneInputPlaceholder": "",
    "registerFormPhoneErrMsg": "Įveskite savo telefoną.",
    "registerFormEmailPlaceholder": "",
    "registerFormEmailRequiredErrMsg": "Įveskite savo el. pašto adresą.",
    "registerFormEmailInvalidErrMsg": "El. paštas neteisingas",
    "registerFormPasswordLabel": "Slaptažodis",
    "registerFormPasswordPlaceholder": "",	
    "registerFormPasswordRequiredErrMsg": "Įveskite galiojantį slaptažodį",
    "registerFormPasswordInvalidErrMsg": "Jūsų slaptažodis turi būti bent 5 simbolių ilgio.",
    "registerFormConfirmPwLabel": "Pakartokite slaptažodį",
    "registerFormConfirmPwPlaceholder": "",
    "registerFormConfirmPwErrMsg": "Slaptažodis nesutampa",
    "registerFormAddressLabel": "Adreso eilutė 1",
    "registerFormAddressPlaceholder": "",
    "registerFormPostcodeLabel": "Pašto kodas",
    "registerFormPostcodePlaceholder": "SE1 2TH",
    "registerFormPostcodeNote": "Pašto kodo pakeisti negalima, nes pagal jį surandame artimiausią specialistą ir paskaičiuojame paslaugos kainą.",
    "registerFormTermsErrMsg": "Šį laukelį būtina užpildyti.",
    "registerFormReferralCodeLabel": "Rekomendacijos kodas",
    "registerFormReferralCodePlaceholder": "pvz. john8D37",
    "registerFormReferralCodeErrMsg": "Neteisingas rekomendacijos kodas.",
    "registerFormButtonLabel": "Registruotis su el. paštu",
    "claimAccountSectionTitle": "Atsiimti Savo Paskyrą",
    "claimAccountTitle": "Ar užsisakėte telefonu/tiesioginiu pokalbiu?",
    "claimAccountDescriptionTooltip": "Jei anksčiau užsisakėte paslaugą telefonu arba el. paštu, tuomet jau turite paskyrą pas mus. Tačiau tikriausiai dar neturite slaptažodžio, norėdami jį susikurti, pasirinkite Atsiimti Savo Paskyrą.",
    "claimAccountButtonLabel": "Atsiimkite savo paskyrą",
    "claimAccountFormTitle": "Atsiimti Savo Paskyrą",
    "claimAccountFormDescription": "Įveskite el. pašto adresą, su kuriuo pirmą kartą užsisakėte paslaugą, ir mes atsiųsime jums el. laišką su instrukcijomis.",
    "claimAccountFormNote1": "Atkreipkite dėmesį, kad jei niekada anksčiau nesate užsisakę paslaugos pas mus, turėsite",
    "claimAccountFormNote2": "užsiregistruoti",
    "claimAccountFormNote3": "pirmiausiai.",
    "claimAccountConfirmationMsg1": "Ką tik išsiuntėme el. laišką su patvirtinimo nuoroda į",
    "claimAccountConfirmationMsg2": "Patikrinkite pašto dėžutę ir sekite instrukcijas!",
    "optionalLoginLabel": "Tęsti kaip svečiui",
    "backToLoginBtnLabel": "< Atgal į prisijungimą",
    "preferencesLabel": "Noriu gauti naujienas apie akcijas, kuponus, apklausas ir naujienas per: (neprivaloma)",
    "smsPreferenceLabel": "SMS",
    "wordEmail": "El. paštas",
    "wordRegister": "Užsiregistruoti",
    "freeQuoteSlotTitle": "Gaukite kainą",
    "serviceSearchInputPlacehodler": "Su kuo jums reikia pagalbos?",
    "unitAutoSelectTitle": "Leiskite jums priskirti specialistą",
    "unitTooltipDescription": "Paskirsime jums artimiausią specialistą.",
    "contactDetailsLabel": "Kontaktiniai duomenys",
    "userAddressLabel": "Adresas",
    "noCoverageEmailFieldLabel": "Įveskite savo el. pašto adresą ir mes jus informuosime, kuomet suteikinėsime paslaugas jūsų vietovėje.",
    "noCoverageEmailFieldErrMsg": "Įveskite galiojantį el. paštą",
    "noCoverageSubmitBtnLabel": "Informuoti mane",
    "noCoverageSuccessMsg": "Ačiū, informuosime, kai tik apimsime jūsų vietovę",
    "shortMonday": "Pir.",
    "shortTuesday": "Ant.",
    "shortWednesday": "Tre.",
    "shortThursday": "Ket.",
    "shortFriday": "Pen.",
    "shortSaturday": "Šeš.",
    "shortSunday": "Sek.",
    "standartPriceLabel": "Standartinė kaina",
    "priceLabel": "Kaina",
    "bestPriceLabel": "geriausia kaina",
    "sameProfessionalLabel": "Tas pats specialistas",
    "fullyBookedLabel": "Pilnai rezervuota",
    "promocodeTitle": "Pridėkite nuolaidos kodą",
    "promocodeFieldPlaceholder": "Nuolaidos kodas",
    "promocodeApplyButtonLabel": "Taikyti",
    "exitPopupTitle": "Ar norite pradėti iš naujo?",
    "exitPopupDefaultTitle": "Jūs paliksite formą.",
    "exitPopupDescription": "Susitikimo informaciją galite pakeisti šoninėje juostoje. Jei turite kokių nors klausimų ar reikia pagalbos, galite pasikalbėti su operatoriumi.",
    "exitPopupStayButtonLabel": "Lik šiame puslapyje",
    "exitPopupLeaveButtonLabel": "Palikti puslapį",
    "exitPopupCommentErr": "Praneškite mums savo priežastį.",
    "exitPopupReasonErr": "Pasirinkite vieną iš šių pasirinkimų.",
    "fileUploadLabel": "Pasirinkite failus",
    "fileUploadSuccessMsg": "Visi failai buvo sėkmingai įkelti",
    "fileUploadLimitErrMsg": "Galite įkelti iki 10 failų.",
    "fileUploadErrMsg1": "jau buvo įkelta.",
    "fileUploadErrMsg2": "Galite įkelti tik 1 vaizdo įrašą.",
    "fileUploadErrMsg3": "yra per didelis, kad būtų galima įkelti.",
    "fileUploadErrMsg4": "negalimas. Prašome įkelti kitą.",
    "fileUploadErrMsg5": "negalime patvirtinti vaizdo įrašo trukmės.",
    "fileUploadInfoMsg1": "Iki",
    "fileUploadInfoMsg2": "failų, maksimalus failo dydžio limitas yra 10MB. Priimami failų formatai: .jpg, .png, .jpeg, .mov, .mp4, .doc, .pdf, .xls.",
    "wordMax": "Maks.",
    "wordUpladed": "Įkelta",
    "initChoiceLabel": "Įrašykite visą pašto kodą",
    "initChoiceFootNote": "Sužinokite, ar mūsų paslauga teikiama jūsų vietovėje.",
    "unavailableSlotModalButtonTitle": "Pasirinkite kitą laiko tarpą",
    "modalDefaultTitle": "Uch Och!",
    "modalDefaultButtonTitle": "Uždaryti",
    "modalDefaultConfirmButtonTitle": "Gerai",
    "modalErrMsg1": "Kažkas įvyko ne taip!",
    "modalErrMsg2": "Uups! Kažkas atsitiko negerai pas mums! Priimkite mūsų atsiprašymus ir pabandykite dar kartą.",
    "modalErrMsg3": "Ups! Kažkas ne taip su jūsų interneto ryšiu! Pabandykite prisijungti!",
    "modalErrMsg4": "Ojojoj! Pabandykite dar kartą.",
    "modalErrMsg5": "Kažkas įvyko ne taip! Norėdami sužinoti, ar jūsų užsakymas buvo išsaugotas, eikite į savo paskyros skiltį \"Mano Užsakymai\".",
    "dropdownNoResultsMsg": "Atsiprašome, tačiau niekas neatitiko jūsų paieškos.",
    "bookingReferenceNumberLabel": "Užsakymo informacinis numeris",
    "referenceNumberLabel": "Informacinis numeris",
    "viewBookingButtonLabel": "Peržiūrėti savo užsakymą",
    "rateBookingSectionTitle": "Įvertinkite savo užsakymo patirtį",
    "ratingTitle1": "Labai prastai",
    "ratingTitle2": "Prastai",
    "ratingTitle3": "Gerai",
    "ratingTitle4": "Labai Gerai",
    "ratingTitle5": "Nepriekaištingai",
    "ratingTitle6": "Super",
    "ratingTitle7": "Patenkinamai",
    "ratingSuccessMessage": "Ačiū, už jūsų atsiliepimą.",
    "feedbackSectionTitle": "Ką turėtume padaryti, kad pagerintume jūsų patirtį?",
    "basSectionTitle": "Ar yra koks nors kitas būdas, kuriuo galime jums padėti?",
    "basSubmitButtonLabel": "Užsakyti kitą paslaugą",
    "resetPasswordSectionTitle": "Pamiršote slaptažodį?",
    "resetPasswordSectionDescription": "Įveskite čia savo el. pašto adresą ir mes atsiųsime jums nuorodą, kad iš naujo nusistatytumėte slaptažodį.",
    "resetPasswordButtonLabel": "Atstatyti slaptažodį",
    "openCalendarButtonLabel1": "Peržiūrėti",
    "openCalendarButtonLabel2": "Pilnas",
    "openCalendarButtonLabel3": "Mėnesis",
    "newPaymentButtonTitle": "Mokėti kita kortele",
    "acceptedPaymentsSectionTitle": "Mes priimame:",
    "paymentSertificateBoxTitle": "Saugus Apmokėjimas su Kortele",
    "paymentSertificateBoxDesc": "Tai saugus 2048 bitų SSL užšifruotas mokėjimas.",
    "cardNumberFieldLabel": "Kortelės numeris",
    "expirationFieldLabel": "Galiojimo laikas",
    "cvcFieldLabel": "CVC",
    "saveButtonLabel": "Išsaugoti",
    "cancelButtonLabel": "Atšaukti",
    "unitGenderFilterLabel": "Specialisto lytis",
    "unitFemaleGenderValue": "Moteris",
    "unitMaleGenderValue": "Vyras",
    "unitNoGenderValue": "Nesvarbu",
    "unitRatingFilterLabel": "Specialisto Įvertinimas",
    "postcodeTooltipMsg": "Pašto kodas negali būti pakeistas atliekant šį veiksmą, nes pasirinktas laiko tarpas yra pagrįstas būtent juo.",
    "confirmAddressButtonLabel": "Patvirtinkite adresą",
    "addAddressButtonLabel": "Pridėti naują adresą",
    "addPhoneButtonLabel": "Pridėti naują telefoną",
    "rebookButtonLabel": "Užsisakyti tą pačią paslaugą",
    "wordReschedule": "Suplanuoti iš naujo",
    "proStatisticsTitle": "Specialisto statistika",
    "changeProButtonLabel": "Pakeisti Specialistą",
    "choosePaymentMethodTitle": "Pasirinkite apmokėjimo būdą",
    "parkingMessage": "* Parkavimo mokestis į kainą neįskaičiuotas.",
    "paymentMethodTitle": "Mokėjimo būdas",
    "braintreeCardNumberLabel": "Debeto arba kredito kortelės numeris",
    "braintreeCardNumberPlaceholder": "0000 0000 0000 0000",
    "braintreeCardNumberRequiredError": "Įveskite kortelės numerį",
    "braintreeCardNumberInvalidError": "Neteisingas kortelės numeris",
    "braintreeExpiryLabel": "Galiojimo laikas",
    "braintreeExpiryPlaceholder": "MM/YY",
    "braintreeExpiryRequiredError": "Įveskite galiojimo datą",
    "braintreeExpiryInvalidError": "Įveskite tinkamą galiojimo datą",
    "braintreeNameOnCardLabel": "Vardas ant kortelės",
    "braintreeNameOnCardPlaceholder": "Vardas ir pavardė",
    "braintreeNameOnCardErrorMsg": "Įveskite vardą",
    "braintreeCvvLabel": "CVV kodas",
    "braintreeCvvPlaceholder": "0",
    "braintreeCvvRequiredError": "Įveskite CVV kodą",
    "braintreeCvvInvalidError": "Neteisingas CVV kodas",
    "wordBook": "Užsakyti",
    "goToHomepageButtonLabel": "Eiti į Pagrindinį Puslapį",
    "wordIn": "-",
    "serviceSearchLabel": "Kokios paslaugos jums reikia?",
    "serviceSearchPlaceholder": "Su kuo jums reikia pagalbos?",
    "serviceSearchErrMsg": "Pasirinkite paslaugą",
    "serviceSearchNoResultsElementMsg": "Nematau nieko, kas atitinka...",
    "serviceSearchNoResultMsg1": "Uups,",
    "serviceSearchNoResultMsg2": "nėra šiuo metu pripažinta paslauga, bet vis tiek galite pabandyti ką nors pasamdyti!",
    "serviceSearchNoResultMsg3": "nėra šiuo metu pripažinta paslauga, bet vis tiek galite pabandyti ką nors pasamdyti! Tiesiog paskambinkite mums.",
    "hoursDetailsMsg1": "Mes išsiųsime",
    "hoursDetailsMsg2": "kam",
    "hourDetailsWarningMsg": "Rezervuojate mažiau laiko nei mes rekomenduojame. Nurodykite savo paslaugų prioritetus, kad geriausiai išnaudotumėte užsakytą laiką.",
    "defaultConfirmationMsg": "Dėkojame jums už užsakymą!",
    "paymentErrorMsg1": "Jūsų kortelė buvo atmesta, bandykite dar kartą arba naudokite kitą kortelę",
    "paymentErrorMsg2": "Turime techninių problemų dėl apmokėjimo sistemos. Greitai tai turėtų būti pataisyta, pabandykite dar kartą vėliau.",
    "paymentErrorMsg3": "Jūsų kortelė nepalaikoma. Pabandykite naudoti kitą kortelę.",
    "paymentErrorMsg4": "3DS patvirtinimas nepavyko. Taip gali nutikti dėl neteisingo patvirtinimo kodo arba dėl su banku susijusių problemų. Naudokite kitą kortelę arba pabandykite dar kartą vėliau.",
    "loaderDefaultMsg": "Šiek tiek palaukite, ruošiamės...",
    "loaderMsg1": "Atnaujinamas mokėjimo būdas",
    "loaderMsg2": "Užbaigiame jūsų",
    "loaderMsg3": "Užbaigiame jūsų užsakymą",
    "loaderMsg4": "Peradresuojama gauti kainos pasiūlymo...",
    "loaderMsg5": "Atnaujinamas jūsų adresas",
    "loaderMsg6": "Kuriame jums naują adresą",
    "loaderMsg7": "Įkeliamas pokalbis",
    "loaderMsg8": "Prisijungimas su Apple",
    "loaderMsg9": "Tikrinami jūsų kredencialai...",
    "loaderMsg10": "Atsijungiama...",
    "loaderMsg11": "Kuriama paskyra...",
    "loaderMsg12": "Prisijungimas su Facebook",
    "loaderMsg13": "Gauti Facebook Duomenis",
    "loaderMsg14": "Laukiama Google autentifikavimo",
    "loaderMsg15": "Gauti savo užsakymą",
    "loaderMsg16": "Jūsų užsakymas atnaujinamas",
    "loaderMsg17": "Gauti prieinamumą",
    "loaderMsg18": "Sukonfigūravo programėlę",
    "loaderMsg19": "Gauti programėlės profilį",
    "loaderMsg20": "Gauti mūsų kategorijas",
    "loaderMsg21": "Gauti mūsų paslaugas",
    "loaderMsg22": "Peradresuojama gauti kainos pasiūlymo...",
    "loaderMsg23": "Prisijungimas su Apple",
    "loaderMsg24": "Sinchronizuoti paskyrą",
    "stepsProgressPriceTitle1": "Kainos ir tvarkaraštis",
    "stepsProgressNoPriceTitle1": "Data ir laikas",
    "proBookingModalDescription": "Jūsų užsakymas patvirtintas ir šiuo metu yra apdorojamas.",
    "proBookingModalButtonLabel": "Gerai",
    "resetPasswordRequiredErrMsg": "Prašome įvesti savo el. paštą!",
    "resetPasswordInvalidErrMsg": "El. paštas neteisingas",
    "resetPasswordSubmitBtnLabel": "Siųsti atstatymo nuorodą",
    "resetPasswordConfirmationMessage": "Buvo išsiųsta nuoroda slaptažodžio atstatymui į",
    "addressFormAddressLineOneLabel1": "Gatvės pavadinimas",
    "addressFormAddressLineOneLabel2": "Pristatymo adresas",
    "addressFormAddressLineOnePlaceholder1": "",
    "addressFormAddressLineOnePlaceholder2": "Buto numeris, Namo pavadinimas, Adresas",
    "addressFormAddressLineOneErrMsg1": "Įveskite savo gatvę.",
    "addressFormAddressLineOneErrMsg2": "Nurodykite adresą",
    "addressFormAddressLineTwoLabel": "Buto / Namo / Pastato numeris",
    "addressFormAddressLineTwoPlaceholder":	"",
    "addressFormAddressLineTwoErrMsg": "Įveskite savo Buto / Pastato numerį.",
    "addressFormPostcodeLabel": "Pašto kodas",
    "addressFormPostcodePlaceholder":	"",
    "addressFormPostcodeInfoMsg": "Pašto kodo pakeisti negalite.",
    "addressFormPostcodeErrMsg1": "Įveskite savo pašto kodą.",
    "addressFormPostcodeErrMsg2": "Nurodykite pašto kodą.",
    "anonymFormFirstNameLabel": "Vardas:",
    "anonymFormFirstNamePlaceholder": "pvz. Jonas",
    "anonymFormFirstNameErrMsg": "Būtina nurodyti vardą",
    "anonymFormLastNameLabel": "Pavardė:",
    "anonymFormLastNamePlaceholder": "pvz. Jonaitis",
    "anonymFormLastNameErrMsg": "Būtina nurodyti pavardę",
    "anonymFormEmailLabel": "El. paštas:",
    "anonymFormEmailPlaceholder": "pvz. tavo.vardas@domenas.com",
    "anonymFormEmailErrMsg": "Būtina nurodyti el. paštą",
    "anonymFormPhoneLabel": "Telefonas:",
    "anonymFormPhonePlaceholder": "pvz. 02034043444",
    "anonymFormPhoneErrMsg": "Būtina nurodyti telefoną",
    "signInLabel": "Prisijungti",
    "backToSignInLabel": "Grįžti į Prisijungimą",
    "signUpLabel": "Registruotis",
    "one": "vienas",
    "two": "du",
    "three": "trys",
    "four": "keturi",
    "wordOr": "arba",
    "thankYouMessage": "Ačiū!",
    "wordHi": "Sveiki",
    "wordUnit": "Vienetas",
    "dealDefaultTitle": "Nepraleiskite šio ypatingo pasiūlymo!",
    "editOrRescheduleConfirmationMsg1": ", išsiuntėme jums el. laišką su atnaujinta užsakymo informacija.",
    "normalBookingConfirmationMsg1": ", mes gavome jūsų užsakymą. Netrukus atsiųsime patvirtinimo el. laišką į",
    "freeQuoteConfirmationMsg": ", jūsų užklausa gauta ir šiuo metu yra apdorojama.",
    "serviceDurationLabel": "Paslaugos Trukmė",
    "enterPostcodeLabel": "Įveskite savo pašto kodą",
    "membershipButtonText1": "Narystė",
    "membershipButtonText2": "klubas",
    "termsAndConditionsText1": "Aš perskaiciau ir sutinku",
    "termsAndConditionsText2": "Privatumo Politika",
    "termsAndConditionsText3": "ir",
    "termsAndConditionsText4": "Paslaugų Teikimo Sąlygos ir Tvarka",
    "termsAndConditionsErrMsg": "Prašome sutikti su mūsų paslaugų teikimo sąlygomis ir tvarka",
    "additionalTermsMessage": "",
    "membershipClubLabel": "Klubo narystė",
    "membershipClubPriceLabel": "Klubo narystė",
    "qrCodeSectionMessage1": "Norėdami pasiekti savo užsakymo informaciją ir gauti virtualią konsultaciją susitikimo dieną, prašome",
    "qrCodeSectionMessage2": "atsisiųsti GoFantastic programėlę",
    "qrCodeSectionMessage3": "ir prisijungti prie savo paskyros.",
    "qrCodeSectionTitle": "GoFantastic - bet kada ir bet kur!",
    "qrCodeSectionDescription": "Tvarkykite savo užsakymus su nemokamą GoFantastic programėle, kuri prieinama iOS ir Android įrenginiams.",
    "qrCodeSectionFootNote": "Norėdami atsisiųsti programėlę, nuskenuokite šį kodą su telefonu",
    "manageBookingButtonLabel": "Tvarkykite savo užsakymą programėlėje",
    "addToCalendarButtonLabel": "Pridėkite prie savo kalendoriaus",
    "benefitSectionStartOfTitle": "Kaip",
    "benefitSectionEndOfTitle": "darbai",
    "addReferralCodeLabel": "Pridėkite rekomendavimo kodą",
    "copyButtonTooltipText": "Nukopijuota sėkmingai!",
    "addServiceButtonTitle": "Pridėti prie užsakymo",
    "rejectButtonTitle": "Ne, ačiū",
    "azureSigninErrMsg": "Nepavyko prisijungti su Microsoft. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "azureSignupErrMsg": "Nepavyko prisiregistruoti su Microsoft. Bandykite dar kartą vėliau arba naudokite kitą prisijungimo būdą.",
    "LoaderMsg25": "Autentifikuotis su Microsoft.",
    "modalDefaultTitle2": "Uups! Buvo nedidelis sistemos atnaujinimas.",
    "modalErrMsg6": "Grįžkite, kad užbaigtumėte užsakymą.",
    "multiselectPlaceholder": "Įveskite elementą arba pasirinkite vieną...",
    "dropdownDefaultOptionLabel": "Pasirinkite elementą",
    "crossSellModalServicePriceTitle": "Paslaugos kaina",
    "tipProButtonLabel": "Palikite arbatpinigių savo specialistui (-ams)",
    "onWelcomeTitle": "Puikios paslaugos, greitas ir paprastas užsakymas",
    "onWelcomeDescription": "mažiau nei per 30 sekundžių",
    "billingFormDetailsTitle": "Kontaktiniai duomenys",
    "billingFormContactTitle": "Telefono numeris",
    "billingFormAddressTitle": "Adresas",
    "multipleAvailabilityButton": "+ Pridėti datą ir laiką"
  }
}