import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@models/address';

@Pipe({ name: 'userAddresses' })

/**
 * Filter user addresses by uniqueness and NOT empty address_line_one
 */
export class UserAddressesPipe implements PipeTransform {
    /**
     * Filter user addresses
     * @param userAddresses array of all user addresses
     * @param postCode optional param, addresses could be filtered by postcode
     */
    public transform(userAddresses: Array<Address>, postCode?: string): Array<Address> {
        let filteredAddresses: Array<Address> = [];

        if (postCode) {
            filteredAddresses = userAddresses.filter((address: Address, index: number, array: Array<Address>) => {
                const currentPostcode: string = postCode.replace(/\s/g, '').toLowerCase(),
                    addressPostcode: string = address.postcode.replace(/\s/g, '').toLowerCase();

                return addressPostcode === currentPostcode &&
                    array.map((mapObj: Address) => {
                        return mapObj?.address_line_one;
                    }).indexOf(address.address_line_one) === index &&
                    address?.address_line_one !== '';
            });
        } else {

            filteredAddresses = userAddresses.filter((address: Address, index: number, array: Array<Address>) => {

                if (address?.address_line_one === '') {
                    return false;
                }

                return array.slice(index + 1).findIndex((checkedAddress: Address) => {
                    return checkedAddress?.address_line_one.replace(/\s/g, '').toLowerCase() === address?.address_line_one.replace(/\s/g, '').toLowerCase() &&
                        checkedAddress.postcode.replace(/\s/g, '').toLowerCase() === address.postcode.replace(/\s/g, '').toLowerCase();

                }) === -1;
            });
        }

        return filteredAddresses;
    }
}
