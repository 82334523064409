import { SDKQueryParams } from '../Api/Interfaces/SDKQueryParams';
import { ParamsOptionsBuilder } from '../Helpers/ParamsOptionsBuilder';
import { BackEndXrmService } from './backendservices/BackEndXrmService';
import { tap } from 'rxjs/operators';
import ObfInjector from '../Helpers/Injector';
import { pipe } from 'rxjs';
export class SDKClientService {
    private _backEndXrmService: BackEndXrmService;
    private _paramsOptionsBuilder: ParamsOptionsBuilder;
    private apiPoints = {
        addresses: 'client/user/addresses',
        avatar: 'client/user/avatar',
        createUpdateAddress: 'client/addresses',
        paymethods: 'client/user/paymethods',
        phones: 'client/user/phones',
        user: 'client/user',
        preferences: 'client/user/preferences',
        checkSecurityRequirements: 'client/check_security_requirements',
        claimAccount: 'client/claim_account',
        membership: 'client/booking_transactions/{ID}/membership_details_screen',
        search: 'client/search',
        searchAddress: 'client/search_address'
    };

    constructor() {
        this._backEndXrmService = ObfInjector.getRegistered('BackEndXrmService') as BackEndXrmService;
        this._paramsOptionsBuilder = ObfInjector.getRegistered('ParamsOptionsBuilder') as ParamsOptionsBuilder;
    }

    /**
     * Get all user data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchUserData(queryParams?: SDKQueryParams, skipInterceptor = false): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.user + params, { skipInterceptor })
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch user addresses
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchUserAddresses(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.addresses + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Create new user address
     * @param {Object} data new address data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public createAddress(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.createUpdateAddress + params, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch user phones
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchUserPhones(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.phones + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Update existing address Object
     * @param {Number} id address id to update
     * @param {Object} data new address data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public updateAddress(id: string, data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.createUpdateAddress + '/' + id + params, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Claim your account
     * @param data 
     */
    public claimAccount(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.claimAccount, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Update existing phone Object
     * @param {Number} phoneId id of existing phone
     * @param {Object} phoneData new phone data
     * @param {Object} queryPrams query parameters
     * @returns {Promise}
     */
    public updatePhone(phoneId: Number, phoneData: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? `/?${params}` : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(`${this.apiPoints.phones}/${phoneId}${params}`, phoneData)
                .subscribe(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Update user notification preference
     * @param {Object} preferenceObject new phone data
     * @param {Object} queryPrams query parameters
     * @returns {Promise}
     */
    public updatePreferences(preferenceObject: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? `/?${params}` : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(`${this.apiPoints.preferences}${params}`, preferenceObject)
                .subscribe(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Create new phone
     * @param {Object} phoneData new phone data
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public createPhone(phoneData: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? `/?${params}` : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .post(`${this.apiPoints.phones}/${params}`, phoneData)
                .subscribe(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Search address
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public searchAddress(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);

        params = params ? `/?${params}` : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.searchAddress + params)
                .subscribe(
                    response => {
                        resolve(response);
                    },
                    error => {
                        reject(error);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Check security requirements for specific paymethod
     * @param {Object} data paymethod data
     * @param {Object} queryParams query parameters
     * @returns {Promise} 
     */
    public checkPaymethodSecurityRequirements(data: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve: Function, reject: Function) => {
            const subscriber = this._backEndXrmService
                .post(this.apiPoints.checkSecurityRequirements + params, data)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch user paymethods (cards)
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchUserPaymethods(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(this.apiPoints.paymethods + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Fetch membership details
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public fetchMembershipDetails(transactionId: any, queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        const membershipApiPoint = this.apiPoints.membership.replace('{ID}', transactionId);

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(
                    membershipApiPoint + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }

    /**
     * Search for service
     * @param {Object} queryParams query parameters
     * @returns {Promise}
     */
    public getServiceSearchResults(queryParams?: SDKQueryParams): Promise<any> {
        let params = this._paramsOptionsBuilder.paramBuilder(queryParams);
        params = params ? '/?' + params : '';

        return new Promise((resolve, reject) => {
            const subscriber = this._backEndXrmService
                .get(
                    this.apiPoints.search + params)
                .pipe(
                    tap(resData => { })
                )
                .subscribe(
                    res => {
                        resolve(res);
                    },
                    err => {
                        reject(err);
                    },
                    () => {
                        subscriber.unsubscribe();
                    }
                );
        });
    }
}
