import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocalizationProvider } from '@providers/localization.provider';
import { EventTrackingService } from '@services/tracking/event-tracking.service';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { scrollToTop, getPreferredCountriesKeys } from '../../../../../../helpers/global-functions';
import { MainDataProvider } from '../../../../../../providers/xrm/main-data.provider';
import { AuthenticationService } from '../../../../../../services/authentication/authentication.service';
import { ConfigurationService } from '../../../../../../services/xrm/configuration.service';
import { UserDataService } from '../../../../../../services/xrm/user-data.service';
import { ValidationService } from '../../../../../../services/xrm/validation.service';

/**
 * Check the two passwors is the same
 * @param {AbstractControl} c
 */
function passwordMatcher(c: AbstractControl) {
    return c.get('password').value === c.get('confirmPassword').value ? null : { nomatch: true };
}

export function phoneValidator(regex) {
    return (c: AbstractControl) => {
        return c.value && regex.test(c.value.replace(/\s/g, '')) ? null : { pattern: true };
    };
}

export interface FormData {
    type_id: number;
    email: string;
    password: string;
    first_name: string;
    last_name: string;
    birth_date_formatted?: any;
    phone: any;
    referrer_code?: string;
    preferences?: {
        allow_mk_email: boolean,
        allow_mk_sms: boolean,
    };
    external?: boolean;
    api_version?: string;
    language?: string;
  }

// #Templates
@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss', './../../../../../../../../node_modules/intl-tel-input/build/css/intlTelInput.css'],
})
// #endTemplates

export class RegisterComponent implements OnInit {
    /**
     * Contain the popup reference
     */
    // @Input()
    // closeModal: Function;
    @Output() public modalClose: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    public changeView = new EventEmitter();

    @Input() set prefillData(data: any) {

        if (data) {

        }
    }

    @ViewChild('templateRForm', { static: false }) public formTemplateRef: any;

    @Input() set currentView(data) {
        this.componentSettings.errorMessages = [];
        if (this.formTemplateRef) {
            // reset templateFormObject
            this.formTemplateRef.reset();
            this.formTemplateRef.submitted = false;
            // reset reactiveFormObject
            this.registerForm.reset();
            this.formSubmitted = false;
        }
    }

    @ViewChild('birthDateField') public birthDateField: ElementRef;

    /**
     * Contain reference to registration form
     */
    public registerForm: FormGroup;
    private signupType = 'standard';
    public termsAndConditionsUrl = '';
    public privacyPolicyUrl = '';
    public brandName = '';
    public birthdateFormFieldEnabled = false;
    public referralFormFieldEnabled = true;
    public birthDateHasError = false;
    public isLoginOptional: boolean = false;
    public separateDialCode = true;
	public SearchCountryField = SearchCountryField;
	public CountryISO = CountryISO;
    public PhoneNumberFormat = PhoneNumberFormat;
	public preferredCountries: CountryISO[] = [];
    public selectedCountry: string = null;
    public customPhonePlaceholder = '';

    public isGuestAuthEnabled = null;

    /**
     * Basic component settings
     */
    public componentSettings = {
        // eslint-disable-next-line @typescript-eslint/quotes
        msg: this._localizationProvider.getLocalizedText('registerFormMessage'),
        errorMessages: [],
        details: {
            title: this._localizationProvider.getLocalizedText('registerFormTitle'),
            fields: {
                firstName: {
                    label: this._localizationProvider.getLocalizedText('registerFormFirstNameLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormFirstNamePlaceholder'),
                    errMsg: this._localizationProvider.getLocalizedText('registerFormFirstNameErrMsg'),
                    // icon: 'user-o'
                },
                lastName: {
                    label: this._localizationProvider.getLocalizedText('registerFormLastNameLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormLastNamePlaceholder'),
                    errMsg: this._localizationProvider.getLocalizedText('registerFormLastNameErrMsg'),
                },
                birthDate: {
                    label: this._localizationProvider.getLocalizedText('registerFormBirthDateLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormBirthDatePlaceholder'),
                    errMsg: this._localizationProvider.getLocalizedText('registerFormBirthDateErrMsg'),
                },
                phone: {
                    label: this._localizationProvider.getLocalizedText('registerFormPhoneLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormPhonePlaceholder'),
                    customPlaceholder: this._localizationProvider.getLocalizedText('customPhoneInputPlaceholder'),
                    errMsg: this._localizationProvider.getLocalizedText('registerFormPhoneErrMsg'),
                    icon: '',
                    // icon: 'phone'
                },
                email: {
                    label: this._localizationProvider.getLocalizedText('wordEmail'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormEmailPlaceholder'),
                    errMsgs: {
                        required: this._localizationProvider.getLocalizedText('registerFormEmailRequiredErrMsg'),
                        format: this._localizationProvider.getLocalizedText('registerFormEmailInvalidErrMsg'),
                    },
                    icon: '',
                    // icon: 'envelope-o'
                },
                password: {
                    label: this._localizationProvider.getLocalizedText('registerFormPasswordLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormPasswordPlaceholder'),
                    errMsgs: {
                        required: this._localizationProvider.getLocalizedText('registerFormPasswordRequiredErrMsg'),
                        format: this._localizationProvider.getLocalizedText('registerFormPasswordInvalidErrMsg'),
                    },
                    errMsg: this._localizationProvider.getLocalizedText('registerFormPasswordRequiredErrMsg'),
                    icon: '',
                    showPassword: false,
                },
                confirmPassword: {
                    label: this._localizationProvider.getLocalizedText('registerFormConfirmPwLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormConfirmPwPlaceholder'),
                    errMsg: this._localizationProvider.getLocalizedText('registerFormConfirmPwErrMsg'),
                    icon: '',
                    showPassword: false,
                },
                address: {
                    label: this._localizationProvider.getLocalizedText('registerFormAddressLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormAddressPlaceholder'),
                    icon: '',
                },
                postcode: {
                    label: this._localizationProvider.getLocalizedText('registerFormPostcodeLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormPostcodePlaceholder'),
                    icon: '',
                    note: this._localizationProvider.getLocalizedText('registerFormPostcodeNote'),
                },
                reg_terms: {
                    label: '', // in template
                    errMsg: this._localizationProvider.getLocalizedText('registerFormTermsErrMsg'),
                },
                smsOptIn: {
                    label: this._localizationProvider.getLocalizedText('smsPreferenceLabel'),
                },
                emailOptIn: {
                    label: this._localizationProvider.getLocalizedText('wordEmail'),
                },
                referralCodeField: {
                    label: this._localizationProvider.getLocalizedText('registerFormReferralCodeLabel'),
                    placeholder: this._localizationProvider.getLocalizedText('registerFormReferralCodePlaceholder'),
                    icon: '',
                    errMsg: this._localizationProvider.getLocalizedText('registerFormReferralCodeErrMsg'),
                },
                signUp: {
                    label: this._localizationProvider.getLocalizedText('registerFormButtonLabel'),
                },
            },
            labels: {
                signUp: {
                    label: this._localizationProvider.getLocalizedText('wordOr'),
                },
            },
        },
        address: {
            title: 'Property',
        },
        payment: {
            title: 'Payment',
        },
    };

    public isBoxExpanded = false;
    public isBoxExpandeByDefault = false;
    public formSubmitted = false;
    public disableEmailAuthorization: boolean = false;

    /**
     * Social login register providers
     */
    @Input()
    public socialLogins;

    public showReferral = false;

    @ViewChild('dynamicLoaderContainer', { read: ViewContainerRef })
    public dynamicLoaderContainer: ViewContainerRef;

    /**
     * Imports
     * @param {FormBuilder} fb
     * @param {AuthenticationService} _authService
     * @param {Router} router
     * @param {LocalStorageService} _localStorageService
     * @param {UserDataService} _userDataService
     * @param {LoaderService} _loaderService
     */
    constructor(
        private fb: FormBuilder,
        private _authService: AuthenticationService,
        private _userDataService: UserDataService,
        private _validationService: ValidationService,
        public _mainDataProvider: MainDataProvider,
        private _eventTrackingService: EventTrackingService,
        private _configurationService: ConfigurationService,
        private _localizationProvider: LocalizationProvider,
    ) { }

    /**
     * caling initialize function
     */
    public ngOnInit() {
        this.initialize();
    }

    /**
     * inialize the registerForm and fetch social providers
     */
    private async initialize() {

        const bookConfiguration = this._configurationService.getConfigurationSettings()?.book;

        if (bookConfiguration?.settings.hasOwnProperty('enable_guest_auth')) {
            this.isGuestAuthEnabled = bookConfiguration.settings.enable_guest_auth;
        }

        // Check for manual disable socials
        if (this.socialLogins) {
            const disabledSocials: string[] = this._mainDataProvider.obfEnv.additionalConfig.functionality.disabledSocials;

            this.socialLogins = this.socialLogins.filter( item => disabledSocials.indexOf(item.type) === -1)
        }

        scrollToTop('.obf-lr-modal');

        const configurationSettings: any = this._configurationService.getConfigurationSettings();

        if (configurationSettings?.auth?.settings?.disable_email_authorization) {
            this.disableEmailAuthorization = configurationSettings.auth.settings.disable_email_authorization;
        }

        // Expand form by default whem socials missing
        if (this.socialLogins && !this.socialLogins.length) {
            this.isBoxExpanded = true;
            this.isBoxExpandeByDefault = true;
            this.componentSettings.details.fields.signUp.label = this._localizationProvider.getLocalizedText('signUpLabel');
        }

        this.birthdateFormFieldEnabled = this._mainDataProvider.obfEnv.additionalConfig.functionality.registerForm.birthdateFieldEnabled;
        this.referralFormFieldEnabled = this._mainDataProvider.obfEnv.additionalConfig.functionality.registerForm.referralFieldEnabled;

        this._configurationService.getConfiguration()
            .then(
                (configuration: any) => { // Success
                    const obfOptions = this._mainDataProvider.getResourceObfOptions();

                    // eslint-disable-next-line max-len
                    if (configuration && configuration.privacy_policy_url && configuration.terms_and_conditions_url) {
                        this.termsAndConditionsUrl = configuration.terms_and_conditions_url;
                        this.privacyPolicyUrl = configuration.privacy_policy_url;

                        if (!this.termsAndConditionsUrl.includes('http') && obfOptions.main_url) {
                            this.termsAndConditionsUrl = obfOptions.main_url + this.termsAndConditionsUrl;
                        }

                        if (!this.privacyPolicyUrl.includes('http') && obfOptions.main_url) {
                            this.privacyPolicyUrl = obfOptions.main_url + this.privacyPolicyUrl;
                        }
                    }

                    this.registerForm = this.fb.group({
                        firstName: ['', [Validators.required, Validators.minLength(1), Validators.pattern(this._validationService.getPattern('register', 'first_name'))]],
                        lastName: ['', [Validators.required, Validators.minLength(1), Validators.pattern(this._validationService.getPattern('register', 'last_name'))]],
                        email: ['', [Validators.required, Validators.pattern(this._validationService.getPattern('register', 'email'))]],
                        phone: new FormControl(undefined, [Validators.required]),
                        // phone: ['', [Validators.required, phoneValidator(this._validationService.getPattern('phones', 'value'))]], // old
                        passwordGroup: this.fb.group(
                            {
                                password: ['', [Validators.required, Validators.minLength(5), Validators.pattern(this._validationService.getPattern('register', 'password'))]],
                                // confirmPassword: ['', Validators.required] // * comment because changes with task #38345
                            },
                            // { validator: passwordMatcher }// * comment because changes with task #38345
                        ),
                        referralCodeField: ['', [Validators.pattern(this._validationService.getPattern('register', 'referrer_code'))]],
                        reg_terms: ['', Validators.requiredTrue],
                        smsOptIn: [''],
                        emailOptIn: [''],
                    });

                    if (configuration?.customer?.settings?.country_code) {
                        this.selectedCountry = CountryISO[getPreferredCountriesKeys([configuration.customer.settings.country_code])[0]];
                    } else if (configuration?.modules?.customer?.settings?.country_code) {
                        this.selectedCountry = CountryISO[getPreferredCountriesKeys([configuration.modules.customer.settings.country_code])[0]];
                    } else if (obfOptions?.country) {
                        this.selectedCountry = CountryISO[getPreferredCountriesKeys([obfOptions.country])[0]];
                    }           

                    const preferredCountries: string[] = this._mainDataProvider.obfEnv.additionalConfig.functionality.phonePreferredCountries;

                    getPreferredCountriesKeys(preferredCountries).map(countryKey => {
                        this.preferredCountries.push(CountryISO[countryKey]);
                    });
                },
                () => { // Error

                },
            );
            
        this.brandName = this._mainDataProvider.obfEnv.additionalConfig.logos.brandName;

        const authRestrictions: any = await this._userDataService.authRestrictions();

        this.isLoginOptional = authRestrictions && authRestrictions === "not_required_auth" ? true : false;
    }

    /**
     * Geter for field in registation form
     */
    get firstName() {
        return this.registerForm.get('firstName');
    }

    /**
     * Geter for field in registation form
     */
    get lastName() {
        return this.registerForm.get('lastName');
    }

    /**
     * Geter for field in registation form
     */
    get birthDate() {
        return this.registerForm.get('birthDate');
    }

    /**
     * Geter for field in registation form
     */
    get email() {
        return this.registerForm.get('email');
    }

    /**
     * Geter for field in registation form
     */
    get phone() {
        return this.registerForm.get('phone');
    }

    /**
     * Geter for field in registation form
     */
    get passwordGroup() {
        return this.registerForm.get('passwordGroup');
    }

    /**
     * Geter for field in registation form
     */
    get referralCode() {
        return this.registerForm.get('referralCode');
    }

    /**
     * Geter for field in registation form
     */
    get referralCodeField() {
        return this.registerForm.get('referralCodeField');
    }

    /**
     * Geter for field in registation form
     */
    get password() {
        return this.registerForm.get('passwordGroup').get('password');
    }

    /**
     * Geter for field in registation form
     */
    get confirmPassword() {
        return this.registerForm.get('passwordGroup').get('confirmPassword');
    }

    get reg_terms() {
        return this.registerForm.get('reg_terms');
    }

    get emailOptIn() {
        return this.registerForm.get('emailOptIn');
    }

    get smsOptIn() {
        return this.registerForm.get('smsOptIn');
    }

    /**
     * Check form validation and registrate user
     */
    public processForm() {
        if (!this.isBoxExpanded) {
            this.isBoxExpanded = true;
            this.componentSettings.details.fields.signUp.label = this._localizationProvider.getLocalizedText('signUpLabel');
            return;
        }

        this.formSubmitted = true;

        if (this.registerForm.valid) {
            const currentLanguage = this._localizationProvider.getCurrentLanguage();
            const formValue = this.registerForm.value;

            let formData: FormData = {
                type_id: 2, // 1 - Anonymous | 2 - Generic (register form) | 3 - Social | 4 - Offline
                email: formValue.email,
                password: formValue.passwordGroup.password,
                first_name: formValue.firstName,
                last_name: formValue.lastName,
                birth_date_formatted: this.birthDateField && this.birthDateField.nativeElement.value ? this.birthDateField.nativeElement.value : null,
                phone: formValue.phone.e164Number,
                referrer_code: formValue.referralCodeField,
                external: true,
                api_version: 'v2',
                language: currentLanguage ? currentLanguage : ''
            };

            if (formValue.emailOptIn || formValue.smsOptIn) {
                formData.preferences = {
                    allow_mk_email: formValue.emailOptIn ? true : false,
                    allow_mk_sms: formValue.smsOptIn ? true : false,
                }
            }

            this._authService.register(formData, {}, (response) => {
                if (response.error) {
                    this.componentSettings.errorMessages = response.error;

                    this.DataLayerPush('error');

                    scrollToTop('.obf-lr-modal');

                } else if (response.data) {
                    this.componentSettings.errorMessages = new Array();

                    this.getAuthorizeUserData();
                } else { // Server error
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('modalErrMsg4') }];
                    scrollToTop('.obf-lr-modal');
                }
            });
        } else {
            this.DataLayerPush('error');
            scrollToTop('.obf-lr-modal');

            this.registerForm.controls['phone'].markAsTouched();
            this.registerForm.controls['phone'].markAsDirty();
        }
    }

    /**
     * Social login handler
     * @param socialProvider
     */
    public socialLogin(socialProvider: any): void {
        switch (socialProvider.type) {
            case 'Facebook':
                this.loginWithFacebook();
                break;
            case 'Google':
                this.loginWithGoogle();
                break;
            case 'Apple':
                this.loginWithApple();
                break;
            case 'Microsoft': 
                this.loginWithAzure();
                break;
            case 'OAuth2':
                this.loginWithOauth2();
                break;
            default:
                break;
        }
    }

    /**
     * Prefiel user data
     * @param data
     */
    private patchForm(data) {
        this.registerForm.patchValue({
            firstName: data.firstName ? data.firstName : '',
            lastName: data.lastName ? data.lastName : '',
            email: data.email ? data.email : '',
        });

        scrollToTop('.obf-lr-modal');
    }

    /**
     * Data layer push
     * @param callbackStatus
     */
    private DataLayerPush(callbackStatus: string): void {
        const dataLayerObject: any = {};
        const event = callbackStatus === 'error' ? 'user-signup-error' : 'user-signup-success';

        // Error Data Push
        Object.assign(dataLayerObject, {
            event,
            signupType: this.signupType,
        });

        if (callbackStatus === 'success') {

            const userData = this._userDataService.getUserData();
            dataLayerObject.userId = userData.id;
            dataLayerObject.membership = userData.membership;

            if (this.emailOptIn.value && this.smsOptIn.value) {
                dataLayerObject.consent = 'E-mail & SMS';
            } else if (this.emailOptIn.value) {
                dataLayerObject.consent = 'E-mail';
            } else if (this.smsOptIn.value) {
                dataLayerObject.consent = 'SMS';
            } else {
                dataLayerObject.consent = 'None';
            }

            const dataLayerObj = {
                event: 'sign_up',
                version: 'GA4',
                membership: userData.membership,
                userId: userData.id
            };
            this._eventTrackingService.push(dataLayerObj);


            const dataLayerObjectUserInfo = {
                event: 'user_info',
                version: 'GA4',
                user_id: userData?.id,
                membership: userData ? userData?.membership ? 'member' : 'non-member' : ''
            };
            this._eventTrackingService.push(dataLayerObjectUserInfo);

        }

        this._eventTrackingService.push(dataLayerObject);
    }

    /**
     * Login with Azure B2C
     */
     private loginWithAzure(): void {
        this._authService.loginAzure()
            .then(
                (response) => { // Success
                    this.componentSettings.errorMessages = new Array();

                    this.getAuthorizeUserData();
                },
                (error: any) => {

                    if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                        this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                    } else {
                        this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('azureSignupErrMsg') }];
                    }

                    this.DataLayerPush('azure');

                    scrollToTop('.obf-lr-modal');
                },
            );
    }

    /**
     * Login with Facebook
     */
    private loginWithFacebook(): void {
        this._authService.loginFacebook()
            .then(
                (response) => { // Success
                    this.componentSettings.errorMessages = new Array();

                    this.getAuthorizeUserData();
                },
                (error: any) => {

                    if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                        this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                    } else {
                        this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('facebookSignupErrMsg') }];
                    }

                    this.DataLayerPush('error');

                    scrollToTop('.obf-lr-modal');
                },
            );
    }

    /**
    * Login with Google
    */
    private loginWithGoogle() {
        this._authService.loginGoogle().then(
            (res: any) => {
                this.componentSettings.errorMessages = new Array();

                this.getAuthorizeUserData();
            },
            (error: any) => {

                if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                    this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                } else {
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('googleSignupErrMsg') }];
                }

                this.DataLayerPush('error');

                scrollToTop('.obf-lr-modal');

            },
        );
    }

    /**
     * Login with OAuth2
     */
    private loginWithOauth2() {
        this._authService.loginOauth2().then(
            (res: any) => {
                this.componentSettings.errorMessages = new Array();

                this.getAuthorizeUserData();
            },
            (error: any) => {

                if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                    this.componentSettings.errorMessages = [{ message: error.error[0].message }];
                } else {
                    this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('defaultSigninErrMsg') }];
                }

                this.DataLayerPush('error');

                scrollToTop('.obf-lr-modal');

            },
        );
    }

    /**
     * Login with Apple
     * @param scLogin
     */
    private loginWithApple(): void {
        this._authService.loginApple().then(async (res) => {
            this.componentSettings.errorMessages = new Array();

            this.getAuthorizeUserData();
        }).catch((error) => {

            if (error && error.hasOwnProperty('error') && error.error.length && error.error[0].message) {
                this.componentSettings.errorMessages = [{ message: error.error[0].message }];
            } else {
                this.componentSettings.errorMessages = [{ message: this._localizationProvider.getLocalizedText('appleSignupErrMsg') }];
            }

            this.DataLayerPush('error');

            scrollToTop('.obf-lr-modal');
        });
    }

    private getAuthorizeUserData() {
        this._userDataService.fetchUserData({ expand: ['all.all.all.all'], 'paging[limit]': '9999' }, (data) => {
            if (!data.error) {
                this.DataLayerPush('success');

                this.modalClose.emit('success');
            } else {
                this.componentSettings.errorMessages = data.error;

                this.DataLayerPush('error');

                scrollToTop('.obf-lr-modal');
            }
        });
    }

    /**
     * Optional login handler
     */
     public continueAsGuest(): void {
        if (this._userDataService.getUserData() && this._userDataService.getUserData().type_id === 1) {
            this.modalClose.emit('success');
        } else {
            this._authService.anonymousRegister({ type_id: 1 }, null, async (response) => {
                this.modalClose.emit('success');
            });
        }
    }
}
